import axios from "axios/axiosAuth";
import axiosWithToken from "axios/axios";
import * as authActionTypes from "./authActionTypes";
import {http_post} from "../httpClientAction";
import helpers from "customs/helpers/helpers";

export const doLogin = (username, password, props) => (dispatch, getState) => {
    callLogin(
        username,
        password,
        (response) => {
            dispatch({"type": response.type,
                response,
                props});
        }
    );
};

function callLogin (username, password, callback) {
    http_post(
        axios,
        "/login",
        {username,
            password},
        authActionTypes.LOGIN_SUCCESS,
        authActionTypes.LOGIN_ERROR,
        callback,
        {
            withCredentials: true
        }
    );
}

export const doForceLogout = (props) => {
    helpers.showLoading();
    return (dispatch, getState) => {
        dispatch({"type": authActionTypes.DO_LOGOUT, props});
    };
};

export const doLogout = (props) => {
    helpers.showLoading();
    return (dispatch, getState) => {
        callLogout(
            props,
            (response) => {
                dispatch({"type": authActionTypes.DO_LOGOUT, props});
            }
        );
    };
};

function callLogout (props, callback) {
    helpers.showLoading();
    http_post(
        axiosWithToken(props),
        "/logout",
        {},
        authActionTypes.DO_LOGOUT,
        authActionTypes.DO_LOGOUT,
        callback,
        {
            withCredentials: true
        }
    );
}

export const doUpdateProfile = (params, props) => (dispatch, getState) => {
    callUpdateProfile(
        params,
        props,
        (response) => {
            dispatch({"type": response.type, response, props});
        }
    );
};

function callUpdateProfile (params, props, callback) {
    http_post(
        axiosWithToken(props),
        "/update_profile",
        params,
        authActionTypes.UPDATE_PROFILE_SUCCESS,
        authActionTypes.UPDATE_PROFILE_ERROR,
        callback
    );
}

export const doChangePassword = (params, props) => (dispatch, getState) => {
    callChangePassword(
        params,
        props,
        (response) => {
            dispatch({"type": response.type, response, props});
        }
    );
};

function callChangePassword (params, props, callback) {
    http_post(
        axiosWithToken(props),
        "/change_password",
        params,
        authActionTypes.CHANGE_PASSWORD_SUCCESS,
        authActionTypes.CHANGE_PASSWORD_ERROR,
        callback
    );
}

export const doSendResetEmail = (username, props) => (dispatch, getState) => {
    callSendResetEmail(
        username,
        (response) => {
            dispatch({"type": response.type, response, props});
        }
    );
};

function callSendResetEmail (username, callback) {
    http_post(
        axios,
        "/reset/password",
        {username},
        authActionTypes.SEND_RESET_EMAIL_SUCCESS,
        authActionTypes.SEND_RESET_EMAIL_ERROR,
        callback
    );

}

export const doResetPassword = (token, params, props) => (dispatch, getState) => {
    callDoResetPassword(
        token,
        params,
        (response) => {
            dispatch({"type": response.type, response, props});
        }
    );
};

function callDoResetPassword (token, params, callback) {
    http_post(
        axios,
        `/reset/password/${token}`,
        params,
        authActionTypes.RESET_PASSWORD_SUCCESS,
        authActionTypes.RESET_PASSWORD_ERROR,
        callback
    );
}

export const doUpdateProfileData = (params, props) => {
    const {auth} = props;
    auth.user = params.user;
    return (dispatch) => dispatch({
        "type": authActionTypes.UPDATE_PROFILE_DATA,
        "data": {"response": {"data": auth}}
    });
};

export const doRefreshToken = (response, props) => (dispatch, getState) => {
    dispatch({"type": authActionTypes.DO_REFRESH_TOKEN, response, props});
};

export const doTokenLogin = (props) => (dispatch, getState) => {
    callTokenLogin(
        (response) => {
            dispatch({"type": response.type,
                response,
                props});
        }
    );
};

function callTokenLogin (callback) {
    http_post(
        axios,
        "/token_login",
        {},
        authActionTypes.TOKEN_LOGIN_SUCCESS,
        authActionTypes.TOKEN_LOGIN_ERROR,
        callback,
        {
            withCredentials: true
        }
    );
}

export const doLoginClient = (username, password, props) => (dispatch, getState) => {
    http_post(
        axios,
        "/client-login",
        {username, password},
        authActionTypes.LOGIN_SUCCESS,
        authActionTypes.LOGIN_ERROR,
        (response) => {
            dispatch({"type": response.type, response, props});
        },
        {
            withCredentials: true
        }
    );
};

export const doSendResetClientEmail = (username, props) => (dispatch, getState) => {
    http_post(
        axios,
        "/client/reset/password",
        {username},
        authActionTypes.SEND_RESET_EMAIL_SUCCESS,
        authActionTypes.SEND_RESET_EMAIL_ERROR,
        (response) => {
            dispatch({"type": response.type, response, props});
        }
    );
};

export const doResetClientPassword = (token, params, props) => (dispatch, getState) => {
    http_post(
        axios,
        `/client/reset/password/${token}`,
        params,
        authActionTypes.RESET_PASSWORD_SUCCESS,
        authActionTypes.RESET_PASSWORD_ERROR,
        (response) => {
            dispatch({"type": response.type, response, props});
        }
    );
};

export const doClientLogout = (props) => {
    helpers.showLoading();
    return (dispatch, getState) => {
        http_post(
            axiosWithToken(props),
            "/client/logout",
            {},
            authActionTypes.DO_LOGOUT,
            authActionTypes.DO_LOGOUT,
            (response) => {
                dispatch({"type": authActionTypes.DO_LOGOUT, props});
            }
        );
    };
};

export const doUpdateAuth = (props) => (dispatch, getState) => {
    dispatch({"type": authActionTypes.FORCE_LOGIN_SUCCESS, props});
};