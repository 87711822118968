import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";
import {Notifications as NotificationsIcon} from "@material-ui/icons";
// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardHeader from "components/Card/CardHeader.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";
import {doLogout} from "../../store/actions/authActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import Pagination from "../../customs/components/Pagination";
import Refresh from "@material-ui/icons/Refresh";
import moment from "moment";
import ReactTable from "react-table";

import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";
import helpers from "customs/helpers/helpers";
import Button from "components/CustomButtons/Button.jsx";
import Icon from '@material-ui/core/Icon';

class Notifications extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
          "notifications": [],
          "notifications_pages": 1
        };

        this.getNotificationsData = this.getNotificationsData.bind(this);
        this.processNotificationData = this.processNotificationData.bind(this);
        this.gotoNotification = this.gotoNotification.bind(this);
        this.markNotificationAsSeen = this.markNotificationAsSeen.bind(this);
        this.markNotificationAs = this.markNotificationAs.bind(this);
        this.gotoToPage = this.gotoToPage.bind(this);
    }

    processErrorAxios(error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                // this.showUsersNotification();
            }
        );
    }

    getNotificationsData (page, pageSize, handleRetrievedData) {

      const url = "/notifications",
          postObject = {
              "page": page + 1,
              "per_page": pageSize
          };

      return axios(this.props)
          .post(
              url,
              postObject
          )
          .then((response) => {

              handleRetrievedData(response);
              helpers.hideLoading();

          })
          .catch((error) => {

              console.log(error);

          });

    }

    setNotificationAction(notification, index) {
      return <div key={index}>
        {
          notification.seen === 0
          ? <Button
              title={'Mark As Seen'}
              className="edit"
              color="success"
              justIcon
              onClick={(event) => {
                this.markNotificationAs(notification.id, 1);
              }}
              round
              simple
          >
              <Icon className={"fas fa-eye"} />
          </Button>
          : <Button
            title={'Mark As Unseen'}
            className="edit"
            color="warning"
            justIcon
            onClick={(event) => {
              this.markNotificationAs(notification.id, 0);
            }}
            round
            simple
        >
            <Icon className={"fas fa-eye-slash"} />
        </Button>
        }
        <Button
            title={'Remove Notification'}
            className="edit"
            color="danger"
            justIcon
            onClick={(event) => {
                this.deleteNotification(notification.id);
            }}
            round
            simple
        >
            <Icon className="fas fa-trash" />
        </Button>
      </div>;
    }

    processNotificationData (res) {
      const notifications = res.data.data.map((notification, index) => {
        notification.notification = <span style={{cursor: "pointer"}} onClick={() => {
          this.gotoNotification(
            notification.id,
            notification.payload.client_id,
            notification.payload.broadcastAs,
            notification.payload.hasOwnProperty('script') ? notification.payload.script : (notification.payload.hasOwnProperty('application') ? notification.payload.application : null),
            notification.seen
          );
        }}>{notification.payload.message}</span>;
        notification.created_at = moment.utc(notification.created_at).format(helpers.date_format() + ' HH:mm:ss')
        notification.actions = this.setNotificationAction(notification, index);
        return notification;

      });
      this.setState({
        notifications,
        notifications_pages: res.data.last_page,
        loading: false,
        "items_data": res.data.from && res.data.to && res.data.total
          ? `${res.data.from} - ${res.data.to} of ${res.data.total}`
          : "",
      });
    }

    gotoToPage(params) {
      this.props.history.push(params);
    }

    gotoNotification(id, client_id, type, script, seen = 0) {
      if (client_id === null || type === null || type === 'manager_login') return;
      if (type === 'application_event') {
        this.markNotificationAsSeen(id, seen,
          {
            pathname: '/admin/applications',
            state: script === null ? {} : {"from": "dashboard", "application": script}
          }
        );
      } else {
        if (this.props.auth.user.role === 'vc') return;
        this.markNotificationAsSeen(id, seen,
          {
              pathname: '/admin/clients',
              state: script === null ? {} : {"from": "dashboard", "script": script}
          }
        );
      }
    }

    markNotificationAsSeen(id, seen = 0, navigate_params = null) {
      if (seen === 1) {
        if (navigate_params !== null) {
          this.gotoToPage(navigate_params);
        }
      } else {
        this.markNotificationAs(id, 1, navigate_params);
      }
    }

    markNotificationAs(id, seen = 0, navigate_params = null) {
      helpers.showLoading();
      axios(this.props)
        .post( "/notifications/mark/" + id + "/" + (seen === 1 ? 'seen' : 'unseen'), {} )
            .then((response) => {
                let notifications = this.state.notifications.map((notification, index) => {
                  if (notification.id === id || id === 'all') {
                    notification.seen = seen;
                    notification.actions = this.setNotificationAction(notification, index)
                  }
                  return notification;
                });
                this.setState({notifications});

                // TODO Update notification bell
                // let bell_notifications = this.getCurrentNotifications();
                // if (id !== 0) {
                //   bell_notifications.map((prop, key) => {
                //       if (prop.id === id) {
                //           prop.seen = 1;
                //       }
                //       return prop;
                //   });
                // }
                // // this.setState({
                // //     "openNotification": false,
                // //     "bell_notifications": bell_notifications
                // // });
                // document.title = "MAPS" + ((bell_notifications.filter((item) => item.seen === 0).length) > 0 ? "*" : "");
                // this.setCurrentNotifications(bell_notifications);
                // TODO Update notification bell

                helpers.hideLoading();
                if (navigate_params !== null) {
                  this.gotoToPage(navigate_params);
                }
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    deleteNotification(id) {
      helpers.showLoading();
      axios(this.props)
        .post( "/notifications/" + id + "/delete", {} )
            .then((response) => {
                this.refreshNotificationsTable();

                // TODO Update notification bell
                // let bell_notifications = this.getCurrentNotifications();
                // if (id !== 0) {
                //   bell_notifications.map((prop, key) => {
                //       if (prop.id === id) {
                //           prop.seen = 1;
                //       }
                //       return prop;
                //   });
                // }
                // // this.setState({
                // //     "openNotification": false,
                // //     "bell_notifications": bell_notifications
                // // });
                // document.title = "MAPS" + ((bell_notifications.filter((item) => item.seen === 0).length) > 0 ? "*" : "");
                // this.setCurrentNotifications(bell_notifications);
                // TODO Update notification bell
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    getCurrentNotifications() {
      let authUser = new AuthService(
          this.state,
          this.props
      );
      
      let notifications = localStorage.getItem('admin_' + authUser.getUserId() + '_notifications');
      return notifications ? JSON.parse(notifications) : [];
    }
  
    setCurrentNotifications(notifications) {
      let authUser = new AuthService(
          this.state,
          this.props
      );
  
      localStorage.setItem('admin_' + authUser.getUserId() + '_notifications', JSON.stringify(notifications));
    }

    refreshNotificationsTable() {
      this.refReactNotificationsTable.fireFetchData();
    }

    render () {
        const authService = new AuthService(
            this.state,
            this.props
        );
        if (!authService.getToken()) {
            return <Redirect to="/auth/login" />;
        }
        const {classes} = this.props,
          {notifications} = this.state;

        return (
          <GridContainer>
              <GridItem xs={12}>
                  <Card>
                      <CardHeader
                          color="success"
                          icon
                      >
                          <CardIcon color="success">
                              <NotificationsIcon />
                          </CardIcon>
                          <h4 className={classes.cardIconTitle}>
                              Notifications
                          </h4>
                          <CardIcon
                              title={'Refresh notification table'}
                              className="float-button"
                              color="warning"
                              onClick={() => this.refreshNotificationsTable()}
                          >
                              <Refresh />
                          </CardIcon>
                          <CardIcon
                              title={'Delete All'}
                              className="float-button"
                              color="danger"
                              onClick={() => {
                                this.deleteNotification('all');
                              }}
                          >
                              <Icon style={{fontSize: "20px", height: "29px"}} className="fas fa-trash" />
                          </CardIcon>
                          <CardIcon
                              title={'Mark All Seen'}
                              className="float-button"
                              color="success"
                              onClick={() => {
                                this.markNotificationAs('all', 1);
                              }}
                          >
                              <Icon style={{fontSize: "20px", height: "29px"}} className={"fas fa-eye"} />
                          </CardIcon>
                      </CardHeader>
                      <CardBody>
                          <ReactTable
                            defaultSorted={[{ id: 'created_at', desc: false }]}
                              className="-striped -highlight"
                              columns={
                                [
                                    {
                                        "Header": "Notification",
                                        "accessor": "notification",
                                        "sortable": false,
                                        "filterable": false
                                    },
                                    {
                                        "Header": "Date",
                                        "accessor": "created_at",
                                        "sortable": false,
                                        "filterable": false,
                                        "width": 250
                                    },
                                    {
                                        "Header": "Actions",
                                        "accessor": "actions",
                                        "sortable": false,
                                        "filterable": false,
                                        "width": 100
                                    }
                                ]
                              }
                              data={notifications}
                              defaultPageSize={10}
                              filterable
                              manual
                              loading={this.state.loading}
                              onFetchData={(state, instance) => {

                                this.setState({"loading": true});
                                  this.getNotificationsData(
                                      state.page,
                                      state.pageSize,
                                      (res) => this.processNotificationData(res)
                                  );

                              }}
                              pages={this.state.notifications_pages}
                              PaginationComponent={Pagination}
                              ref={(refReactNotificationsTable) => {

                                  this.refReactNotificationsTable = refReactNotificationsTable;

                              }}
                              itemsCountText={this.state.items_data}
                              showPageSizeOptions
                              showPaginationBottom
                              showPaginationTop={false}
                          />
                      </CardBody>
                  </Card>
              </GridItem>
          </GridContainer>
      );
    }
}

Notifications.propTypes = {
    "classes": PropTypes.object
};

const mapStateToProps = (state, props) => ({
    "auth": state.auth.data
}),

mapDispatchToProps = (dispatch) => ({
    "doLogout": (props) => dispatch(doLogout(props))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(extendedFormsStyle)(Notifications));
