import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import validations from "customs/helpers/validations";
import ConfirmDialog from "customs/components/ConfirmDialog";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class CustomSignatoriesDialog extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            "signatories": this.parseSignatories(props.signatories),
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
        };
    }

    componentDidMount() {
        this.setupSignatories(this.parseSignatories(this.props.signatories));
    }

    componentWillReceiveProps(props) {
        if (/*props.signatories &&*/ this.state.signatories === null) {
            this.setState({'signatories': this.parseSignatories(props.signatories)}, function() {
                this.setupSignatories(this.parseSignatories(props.signatories));
            });
        }
    }

    parseSignatories(signatories) {
        if (signatories === null || signatories === undefined || signatories.length === 0) return {};
        if (Array.isArray(signatories)) return signatories;
        return JSON.parse(signatories);
    }

    setupSignatories(signatories) {
        if (!signatories) signatories = {};
        let number_of_signatories = signatories.hasOwnProperty('number_of_signatories') ? signatories.number_of_signatories : 1;
        let number_of_beneficials = this.props.number_of_beneficials;
        this.setState({
            'number_of_signatories': number_of_signatories,
            'signatory1_type': signatories.hasOwnProperty('signatory1') && signatories.signatory1.hasOwnProperty('type') ? signatories.signatory1.type : number_of_beneficials > 0 ? 'bo1' : 'custom',
            'signatory1_email': signatories.hasOwnProperty('signatory1') && signatories.signatory1.hasOwnProperty('email') ? signatories.signatory1.email : '',
            'signatory1_first_name': signatories.hasOwnProperty('signatory1') && signatories.signatory1.hasOwnProperty('first_name') ? signatories.signatory1.first_name : '',
            'signatory1_last_name': signatories.hasOwnProperty('signatory1') && signatories.signatory1.hasOwnProperty('last_name') ? signatories.signatory1.last_name : '',
            'signatory1_position': signatories.hasOwnProperty('signatory1') && signatories.signatory1.hasOwnProperty('position') ? signatories.signatory1.position : '',
            
            'signatory2_type': signatories.hasOwnProperty('signatory2') && signatories.signatory2.hasOwnProperty('type') ? signatories.signatory2.type : '', //number_of_beneficials > 1 ? 'bo2' : 'custom',
            'signatory2_email': signatories.hasOwnProperty('signatory2') && signatories.signatory2.hasOwnProperty('email') ? signatories.signatory2.email : '',
            'signatory2_first_name': signatories.hasOwnProperty('signatory2') && signatories.signatory2.hasOwnProperty('first_name') ? signatories.signatory2.first_name : '',
            'signatory2_last_name': signatories.hasOwnProperty('signatory2') && signatories.signatory2.hasOwnProperty('last_name') ? signatories.signatory2.last_name : '',
            'signatory2_position': signatories.hasOwnProperty('signatory2') && signatories.signatory2.hasOwnProperty('position') ? signatories.signatory2.position : '',
            
            'signatory3_type': signatories.hasOwnProperty('signatory3') && signatories.signatory3.hasOwnProperty('type') ? signatories.signatory3.type : '', //number_of_beneficials > 2 ? 'bo3' : 'custom',
            'signatory3_email': signatories.hasOwnProperty('signatory3') && signatories.signatory3.hasOwnProperty('email') ? signatories.signatory3.email : '',
            'signatory3_first_name': signatories.hasOwnProperty('signatory3') && signatories.signatory3.hasOwnProperty('first_name') ? signatories.signatory3.first_name : '',
            'signatory3_last_name': signatories.hasOwnProperty('signatory3') && signatories.signatory3.hasOwnProperty('last_name') ? signatories.signatory3.last_name : '',
            'signatory3_position': signatories.hasOwnProperty('signatory3') && signatories.signatory3.hasOwnProperty('position') ? signatories.signatory3.position : '',
            
            'signatory4_type': signatories.hasOwnProperty('signatory4') && signatories.signatory4.hasOwnProperty('type') ? signatories.signatory4.type : '', //number_of_beneficials > 3 ? 'bo4' : 'custom',
            'signatory4_email': signatories.hasOwnProperty('signatory4') && signatories.signatory4.hasOwnProperty('email') ? signatories.signatory4.email : '',
            'signatory4_first_name': signatories.hasOwnProperty('signatory4') && signatories.signatory4.hasOwnProperty('first_name') ? signatories.signatory4.first_name : '',
            'signatory4_last_name': signatories.hasOwnProperty('signatory4') && signatories.signatory4.hasOwnProperty('last_name') ? signatories.signatory4.last_name : '',
            'signatory4_position': signatories.hasOwnProperty('signatory4') && signatories.signatory4.hasOwnProperty('position') ? signatories.signatory4.position : '',
        });
    }

    isBeneficialOwnerSelected(index, key) {
        if (index !== 1 && this.state.signatory1_type === key) return true;
        if (index !== 2 && this.state.signatory2_type === key) return true;
        if (index !== 3 && this.state.signatory3_type === key) return true;
        if (index !== 4 && this.state.signatory4_type === key) return true;
        return false;
    }

    renderProcessingSignatoryForm(classes, number_of_beneficials, index) {
        let type = 'signatory' + index + '_type',
            first_name = 'signatory' + index + '_first_name',
            last_name = 'signatory' + index + '_last_name',
            email = 'signatory' + index + '_email',
            position = 'signatory' + index + '_position';
        return <div>
            <div style={{width: "100%", margin: "15px 15px 0 0px"}}>
                <table>
                    <tbody>
                        <tr>
                            <td style={{width: "10%", whiteSpace: "nowrap"}}>
                                <InputLabel
                                    className={classes.selectLabel}
                                    htmlFor={type}
                                >
                                    Select Signatory {index.toString()}
                                </InputLabel>
                            </td>
                            <td>
                                <select
                                    onChange={(e) => this.setState({[type]: e.target.value})}
                                    style={{
                                        "padding": "5px 10px 5px 10px",
                                        "marginLeft": "2px",
                                        "borderColor": "#ccc",
                                        "borderRadius": "3px"
                                    }}
                                    value={this.state[type]}
                                >
                                    <option key={0} value={""}>Please a Signatory</option>
                                    <option key={1} value={"custom"}>Custom</option>
                                    <option style={{display: number_of_beneficials > 0 ? "block" : "none"}} disabled={number_of_beneficials < 1 || this.isBeneficialOwnerSelected(index, 'bo1')} key={2} value={"bo1"}>Beneficial Owner 1</option>
                                    <option style={{display: number_of_beneficials > 1 ? "block" : "none"}} disabled={number_of_beneficials < 2 || this.isBeneficialOwnerSelected(index, 'bo2')} key={3} value={"bo2"}>Beneficial Owner 2</option>
                                    <option style={{display: number_of_beneficials > 2 ? "block" : "none"}} disabled={number_of_beneficials < 3 || this.isBeneficialOwnerSelected(index, 'bo3')} key={4} value={"bo3"}>Beneficial Owner 3</option>
                                    <option style={{display: number_of_beneficials > 3 ? "block" : "none"}} disabled={number_of_beneficials < 4 || this.isBeneficialOwnerSelected(index, 'bo4')} key={5} value={"bo4"}>Beneficial Owner 4</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {
                    this.state[type] === 'custom'
                    ? <GridContainer
                            key={"signatory" + index + "_name_grid"}
                            style={{padding: '0 15px 15px 15px'}}
                        >
                            <GridItem md={6}>
                                <div style={{ paddingTop : "10px"}}>
                                    <TextField
                                        inputProps={{
                                            name: {first_name},
                                            id: {first_name}
                                        }}
                                        type="text"
                                        className="w-100"
                                        value={this.state[first_name] ? this.state[first_name] : ""}
                                        onChange={(event) => {
                                            this.setState({[first_name]: event.target.value});
                                        }}
                                        label={"First Name"}
                                    />
                                </div>
                            </GridItem>
                            <GridItem md={6}>
                                <div style={{ paddingTop : "10px"}}>
                                    <TextField
                                        inputProps={{
                                            name: {last_name},
                                            id: {last_name}
                                        }}
                                        type="text"
                                        className="w-100"
                                        value={this.state[last_name] ? this.state[last_name] : ""}
                                        onChange={(event) => {
                                            this.setState({[last_name]: event.target.value});
                                        }}
                                        label={"Last Name"}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    : ""
                }
                {
                    this.state[type] === 'custom'
                    ? <GridContainer
                            key={"signatory" + index + "_email_position_grid"}
                            style={{padding: '0 15px 15px 15px'}}
                        >
                            <GridItem md={6}>
                                <div style={{ paddingTop : "10px"}}>
                                    <TextField
                                        inputProps={{
                                            name: {email},
                                            id: {email}
                                        }}
                                        type="text"
                                        className="w-100"
                                        value={this.state[email] ? this.state[email] : ""}
                                        onChange={(event) => {
                                            this.setState({[email]: event.target.value});
                                        }}
                                        label={"Email Address"}
                                    />
                                </div>
                            </GridItem>
                            <GridItem md={6}>
                                <div style={{ paddingTop : "10px"}}>
                                    <TextField
                                        inputProps={{
                                            name: {position},
                                            id: {position}
                                        }}
                                        type="text"
                                        className="w-100"
                                        value={this.state[position] ? this.state[position] : ""}
                                        onChange={(event) => {
                                            this.setState({[position]: event.target.value});
                                        }}
                                        label={"Position"}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                    : ""
                }
            </div>
        </div>;
    }

    isValid() {
        let number_of_signatories = this.state.number_of_signatories;
        let signatory_emails = [];
        if (number_of_signatories > 0) {
            if (!this.state.signatory1_type || this.state.signatory1_type.length === 0) {
                this.props.setUsersNotification("Please select Signatory 1.", "danger");
                return false;
            } else if (this.state.signatory1_type === 'custom') {
                if (!this.state.signatory1_first_name || this.state.signatory1_first_name.length === 0) {
                    this.props.setUsersNotification("Signatory 1 first name is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory1_last_name || this.state.signatory1_last_name.length === 0) {
                    this.props.setUsersNotification("Signatory 1 last name is invalid.", "danger");
                    return false;
                } else if (!validations.validateEmail(this.state.signatory1_email)) {
                    this.props.setUsersNotification("Signatory 1 email is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory1_position || this.state.signatory1_position.length === 0) {
                    this.props.setUsersNotification("Signatory 1 position is invalid.", "danger");
                    return false;
                }
            }

            signatory_emails.push(this.state.signatory1_type === 'custom' ? this.state.signatory1_email : this.props.renderValue(this.state.signatory1_type + "_email"));
        }
        if (number_of_signatories > 1) {
            if (!this.state.signatory2_type || this.state.signatory2_type.length === 0) {
                this.props.setUsersNotification("Please select Signatory 2.", "danger");
                return false;
            } else if (this.state.signatory2_type === 'custom') {
                if (!this.state.signatory2_first_name || this.state.signatory2_first_name.length === 0) {
                    this.props.setUsersNotification("Signatory 2 first name is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory2_last_name || this.state.signatory2_last_name.length === 0) {
                    this.props.setUsersNotification("Signatory 2 last name is invalid.", "danger");
                    return false;
                } else if (!validations.validateEmail(this.state.signatory2_email)) {
                    this.props.setUsersNotification("Signatory 2 email is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory2_position || this.state.signatory2_position.length === 0) {
                    this.props.setUsersNotification("Signatory 2 position is invalid.", "danger");
                    return false;
                }
            }

            let signatory_email = this.state.signatory2_type === 'custom' ? this.state.signatory2_email : this.props.renderValue(this.state.signatory2_type + "_email");
            if (signatory_emails.includes(signatory_email)) {
                this.props.setUsersNotification("Signatory 2 email (" + signatory_email + ") is already in use.", "danger");
                return false;
            }
            signatory_emails.push(signatory_email);
        }
        if (number_of_signatories > 2) {
            if (!this.state.signatory3_type || this.state.signatory3_type.length === 0) {
                this.props.setUsersNotification("Please select Signatory 3.", "danger");
                return false;
            } else if (this.state.signatory3_type === 'custom') {
                if (!this.state.signatory3_first_name || this.state.signatory3_first_name.length === 0) {
                    this.props.setUsersNotification("Signatory 3 first name is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory3_last_name || this.state.signatory3_last_name.length === 0) {
                    this.props.setUsersNotification("Signatory 3 last name is invalid.", "danger");
                    return false;
                } else if (!validations.validateEmail(this.state.signatory3_email)) {
                    this.props.setUsersNotification("Signatory 3 email is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory3_position || this.state.signatory3_position.length === 0) {
                    this.props.setUsersNotification("Signatory 3 position is invalid.", "danger");
                    return false;
                }
            }

            let signatory_email = this.state.signatory3_type === 'custom' ? this.state.signatory3_email : this.props.renderValue(this.state.signatory3_type + "_email");
            if (signatory_emails.includes(signatory_email)) {
                this.props.setUsersNotification("Signatory 3 email (" + signatory_email + ") is already in use.", "danger");
                return false;
            }
            signatory_emails.push(signatory_email);
        }
        if (number_of_signatories > 3) {
            if (!this.state.signatory4_type || this.state.signatory4_type.length === 0) {
                this.props.setUsersNotification("Please select Signatory 4.", "danger");
                return false;
            } else if (this.state.signatory4_type === 'custom') {
                if (!this.state.signatory4_first_name || this.state.signatory4_first_name.length === 0) {
                    this.props.setUsersNotification("Signatory 4 first name is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory4_last_name || this.state.signatory4_last_name.length === 0) {
                    this.props.setUsersNotification("Signatory 4 last name is invalid.", "danger");
                    return false;
                } else if (!validations.validateEmail(this.state.signatory4_email)) {
                    this.props.setUsersNotification("Signatory 4 email is invalid.", "danger");
                    return false;
                } else if (!this.state.signatory4_position || this.state.signatory4_position.length === 0) {
                    this.props.setUsersNotification("Signatory 4 position is invalid.", "danger");
                    return false;
                }
            }

            let signatory_email = this.state.signatory4_type === 'custom' ? this.state.signatory4_email : this.props.renderValue(this.state.signatory4_type + "_email");
            if (signatory_emails.includes(signatory_email)) {
                this.props.setUsersNotification("Signatory 4 email (" + signatory_email + ") is already in use.", "danger");
                return false;
            }
            signatory_emails.push(signatory_email);
        }
        return true;
    }

    setSignatoriesPayload() {
        let signatory1 = this.state.number_of_signatories > 0
            ? {
                "signatory1": {
                    'type': this.state.signatory1_type,
                    'email': this.state.signatory1_email,
                    'first_name': this.state.signatory1_first_name,
                    'last_name': this.state.signatory1_last_name,
                    'position': this.state.signatory1_position,
                }
            }
            : {};
        let signatory2 = this.state.number_of_signatories > 1
            ? {
                "signatory2": {
                    'type': this.state.signatory2_type,
                    'email': this.state.signatory2_email,
                    'first_name': this.state.signatory2_first_name,
                    'last_name': this.state.signatory2_last_name,
                    'position': this.state.signatory2_position,
                }
            }
            : {};
        let signatory3 = this.state.number_of_signatories > 2
            ? {
                "signatory3": {
                    'type': this.state.signatory3_type,
                    'email': this.state.signatory3_email,
                    'first_name': this.state.signatory3_first_name,
                    'last_name': this.state.signatory3_last_name,
                    'position': this.state.signatory3_position,
                }
            }
            : {};
        let signatory4 = this.state.number_of_signatories > 3
            ? {
                "signatory4": {
                    'type': this.state.signatory4_type,
                    'email': this.state.signatory4_email,
                    'first_name': this.state.signatory4_first_name,
                    'last_name': this.state.signatory4_last_name,
                    'position': this.state.signatory4_position,
                }
            }
            : {};

        return {
            ...{"number_of_signatories": this.state.number_of_signatories},
            ...{"signatories": {
                ...signatory1,
                ...signatory2,
                ...signatory3,
                ...signatory4
            }}
        }
    }

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                maxWidth={this.props.minWidth ? "lg" : "sm"}
                onClose={() => this.setState({'signatories': null}, function() {this.props.onClose("customSignatoriesModal");})}
                open={this.props.customSignatoriesModal}
                style={{"zIndex": 9999}}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.customSignatoriesModalTitle
                        ? this.props.customSignatoriesModalTitle
                        : "Confirm"}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                >

                    {
                        this.props.customSignatoriesModalMsg === null
                        ? ""
                        : <h5 style={{"minWidth": this.props.minWidth ? this.props.minWidth : "200px",
                                "marginBottom": "1rem"}}
                            >
                                {this.props.customSignatoriesModalMsg
                                    ? this.props.customSignatoriesModalMsg
                                    : "Are you sure?"}
                            </h5>
                    }

                    <h5 style={{"minWidth": this.props.minWidth ? this.props.minWidth : "200px"}}
                    >
                        Setup Signatories
                    </h5>
                    
                    <div style={{overflowX: "auto"}}>
                        <table style={{width: "250px"}}>
                            <tbody>
                                <tr>
                                    <td style={{whiteSpace: "nowrap"}}>
                                        <InputLabel
                                            className={classes.selectLabel}
                                            htmlFor="number_of_signatories"
                                        >
                                            Number of Signatories
                                        </InputLabel>
                                    </td>
                                    <td>
                                        <select
                                            onChange={(e) => this.setState({'number_of_signatories': e.target.value})}
                                            style={{
                                                "padding": "5px 10px 5px 10px",
                                                "marginLeft": "2px",
                                                "borderColor": "#ccc",
                                                "borderRadius": "3px"
                                            }}
                                            value={this.state.number_of_signatories}
                                        >
                                            <option key={1} value={"1"}>1</option>
                                            <option key={2} value={"2"}>2</option>
                                            <option key={3} value={"3"}>3</option>
                                            <option key={4} value={"4"}>4</option>
                                        </select>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    { this.state.number_of_signatories > 0 ? this.renderProcessingSignatoryForm(classes, this.props.number_of_beneficials, 1) : "" }
                    { this.state.number_of_signatories > 1 ? this.renderProcessingSignatoryForm(classes, this.props.number_of_beneficials, 2) : "" }
                    { this.state.number_of_signatories > 2 ? this.renderProcessingSignatoryForm(classes, this.props.number_of_beneficials, 3) : "" }
                    { this.state.number_of_signatories > 3 ? this.renderProcessingSignatoryForm(classes, this.props.number_of_beneficials, 4) : "" }
                    
                    <ConfirmDialog
                        confirmModal={this.state.confirmModal}
                        confirmModalMsg={this.state.confirmModalMsg}
                        confirmModalTitle={this.state.confirmModalTitle}
                        onClose={() => this.setState({"confirmModal": false})}
                        onYesClick={() => this.setState({"confirmModal": false}, function() {
                            this.props.onYesClick(this.setSignatoriesPayload());
                        })}
                    />

                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.setState({'signatories': null}, function() {this.props.onClose("customSignatoriesModal");})}
                    >
                        {this.props.noLbl ? this.props.noLbl : "No"}
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        onClick={
                            () => {
                                if (this.isValid()) {
                                    this.setState({
                                        "confirmModal": true,
                                        "confirmModalMsg":  'Are you sure?',
                                        "confirmModalTitle": "Request Signature(s)",
                                    });
                                }
                            }
                        }
                        simple
                    >
                        {this.props.yesLbl ? this.props.yesLbl : "Continue"}
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(userProfileStyles)(CustomSignatoriesDialog);
