import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";
import {Redirect, Route, Switch} from "react-router-dom";
// Creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// Core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";

import routes from "routes.js";
import AuthService from "customs/auth/AuthService";
import {connect} from "react-redux";

import helpers from "customs/helpers/helpers";

import appStyle from "assets/jss/material-dashboard-pro-react/layouts/adminStyle.jsx";

let ps;

class Dashboard extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "mobileOpen": false,
            "miniActive": false,
            "image": require("assets/img/sidebar-2.jpg"),
            "color": "blue",
            "bgColor": "black",
            "hasImage": true,
            "fixedClasses": "dropdown",
            "logo": `${process.env.PUBLIC_URL}/images/mini-logo.png`
        };

        this.scrollToOffset = this.scrollToOffset.bind(this);

    }

  mainPanel = React.createRef();
  componentDidMount () {
      if (navigator.platform.indexOf("Win") > -1) {
          ps = new PerfectScrollbar(
              this.mainPanel.current,
              {
                  "suppressScrollX": true,
                  "suppressScrollY": false
              }
          );
          document.body.style.overflow = "hidden";
      }
      window.addEventListener(
          "resize",
          this.resizeFunction
      );
  }

  componentWillUnmount () {
      if (navigator.platform.indexOf("Win") > -1) {
        try {ps.destroy();} catch(err){}
      }
      window.removeEventListener(
          "resize",
          this.resizeFunction
      );
  }

  componentDidUpdate (e) {
      if (e.history.location.pathname !== e.location.pathname) {
          this.mainPanel.current.scrollTop = 0;
          if (this.state.mobileOpen) {
              this.setState({"mobileOpen": false});
          }
      }
  }

  handleImageClick = (image) => {
      this.setState({image});
  };

  handleColorClick = (color) => {
      this.setState({color});
  };

  handleBgColorClick = (bgColor) => {
      switch (bgColor) {
        case "white":
            this.setState({
                "logo": `${process.env.PUBLIC_URL}/images/mini-logo.png`
            });
            break;
        default:
            this.setState({
                "logo": `${process.env.PUBLIC_URL}/images/mini-logo.png`
            });
            break;
      }
      this.setState({bgColor});
  };

  handleFixedClick = () => {
      if (this.state.fixedClasses === "dropdown") {
          this.setState({"fixedClasses": "dropdown show"});
      } else {
          this.setState({"fixedClasses": "dropdown"});
      }
  };

  handleDrawerToggle = () => {
      this.setState({"mobileOpen": !this.state.mobileOpen});
  };

  getRoute = () => window.location.pathname !== "/admin/full-screen-maps";

  getActiveRoute = (routes) => {
      const activeRoute = "Default Brand Text";
      for (let i = 0; i < routes.length; i++) {
          if (routes[i].collapse) {
              const collapseActiveRoute = this.getActiveRoute(routes[i].views);
              if (collapseActiveRoute !== activeRoute) {
                  return collapseActiveRoute;
              }
          } else if (
              window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
          ) {
              return routes[i].name;
          }
      }
      return activeRoute;
  };

  scrollToOffset(offset) {
    try {
        this.mainPanel.current.scrollTo({top: offset, left: 0, behavior: 'smooth' });
    } catch(e) {
        try {
            this.mainPanel.current.scrollTop = offset;
        } catch(e1) {}
    }
  }

  getRoutes = (routes) => routes.map((prop, key) => {
      if (prop.collapse) {
          return this.getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
          return (
              <Route
                //   component={prop.component}
                  render={(props) => <prop.component scrollToOffset={this.scrollToOffset} {...props} />}
                  key={key}
                  path={prop.layout + prop.path}
              />
          );
      }
      return null;
  });

  shouldComponentUpdate(nextProps, nextState) {
      if (nextProps.location.pathname === this.props.location.pathname) {
          return false;
      }
      return true;
  }

  sidebarMinimize = () => {
      this.setState({"miniActive": !this.state.miniActive});
  };

  resizeFunction = () => {
      if (window.innerWidth >= 960) {
          this.setState({"mobileOpen": false});
      }
  };

  render () {
      const {classes, ...rest} = this.props,
          mainPanel = 
            `${classes.mainPanel} ${
                cx({
                    [classes.mainPanelSidebarMini]: this.state.miniActive,
                    [classes.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
                })}`,
          authService = new AuthService(
              this.state,
              this.props
          );
      return (
          <div className={classes.wrapper}>
              <style>
                  {".rt-resizable-header-content{white-space: nowrap !important;}"}
              </style>
              <Sidebar
                  bgColor={this.state.bgColor}
                  color={this.state.color}
                  handleDrawerToggle={this.handleDrawerToggle}
                  image={this.state.image}
                  logo={this.state.logo}
                  logoText={`${process.env.PUBLIC_URL}/images/logo-main.png`}
                  miniActive={this.state.miniActive}
                  open={this.state.mobileOpen}
                  routes={routes}
                  {...rest}
              />
              <div
                  className={mainPanel}
                  ref={this.mainPanel}
              >
                  <AdminNavbar
                      brandText={this.getActiveRoute(routes)}
                      handleDrawerToggle={this.handleDrawerToggle}
                      miniActive={this.state.miniActive}
                      sidebarMinimize={this.sidebarMinimize.bind(this)}
                      {...rest}
                  />
                  {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
                  {!authService.getToken()
                      ? <Redirect
                          from="/admin"
                          to="/auth/login"
                        />
                      : this.getRoute()
                          ? <div className={classes.content}>
                              <div className={classes.container}>
                                  <Switch>
                                      {this.getRoutes(routes)}
                                      <Redirect
                                          from="/admin"
                                          to="/auth/login"
                                      />
                                  </Switch>
                              </div>
                          </div>
                          : <div className={classes.map}>
                              <Switch>
                                  {this.getRoutes(routes)}
                                  <Redirect
                                      from="/admin"
                                      to="/auth/login"
                                  />
                              </Switch>
                          </div>}
                  {this.getRoute() ? <Footer fluid /> : null}
              </div>
          </div>
      );
  }
}

Dashboard.propTypes = {
    "classes": PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => {
    helpers.hideLoading();
    return {
        "auth": state.auth.data
    };
};

export default connect(mapStateToProps)(withStyles(appStyle)(Dashboard));
