import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Add from "@material-ui/icons/Add";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class AddManagerToClientDialog extends React.Component {

  handleAddManagerFromAddDialog = (event, id) => {
      this.props.handleAddManagerFromAddDialog(
          event,
          id
      );
  };

  handleAddNewManager = (event) => {
    if (this.props.handleAddNewManager !== null)
        this.props.handleAddNewManager(event);
  };

  render () {
      const spanStyle = {
              "display": "block",
              "padding": "15px 60px 15px 10px",
              "borderBottom": "1px solid #DDD"
          },
          btnStyle = {"float": "right",
              "top": "-52px"},
          {classes} = this.props;
      return (
          <Dialog
              aria-describedby="update-profile-confirm-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{
                  "root": `${classes.center} ${classes.modalRoot}`,
                  "paper": `${classes.modal} ${classes.modalSmall}`
              }}
              keepMounted
              onClose={() => this.props.onClose("selectManagerModal")}
              open={this.props.selectManagerModal}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Managers
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="update-profile-confirm-modal-slide-description"
              >
                  {this.props.handleAddNewManager !== null ? <div
                      key="9999"
                      style={{"width": "100%"}}
                  >
                      <Button
                          className="info"
                          color="info"
                          onClick={(event) => this.handleAddNewManager(event)}
                          round
                      >
                          <Add />
                          New Team Leader
                      </Button>
                  </div> : ''}
                  {this.props.available_managers && this.props.available_managers.length > 0
                      ? this.props.available_managers.map((prop, key) => <div
                              style={{width: "100%"}}
                              key={key}
                          >
                              <span style={spanStyle}>
                                  {`${prop.first_name 
                                  } ${
                                      prop.last_name 
                                  } (${
                                      prop.company 
                                  })`}
                              </span>
                              <Button
                                  className="info"
                                  color="info"
                                  justIcon
                                  onClick={(event) => this.handleAddManagerFromAddDialog(event,
                                    prop.id)}
                                  round
                                  simple
                                  style={btnStyle}
                              >
                                  <Add />
                              </Button>
                          </div>)
                      : <div style={{width: "100%", paddingTop: "1rem", textAlign: "center"}}>{"No officers available"}</div>}
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose("selectManagerModal")}
                  >
                      Close
                  </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(AddManagerToClientDialog);
