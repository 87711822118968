import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import ListSubheader from "@material-ui/core/ListSubheader";
import MenuItem from "@material-ui/core/MenuItem";
import validations from "customs/helpers/validations";
import Danger from "components/Typography/Danger.jsx";
import Warning from "components/Typography/Warning.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import helpers from "customs/helpers/helpers";
import contactDefaults from "customs/components/Contacts/ContactDefaults";
import TagsInput from "react-tagsinput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Check from "@material-ui/icons/Check";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";
// import IconButton from "@material-ui/core/IconButton";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";
import AddressAutocomplete from "customs/components/ScriptStep/AddressAutocomplete";

class ClientDetailsDialog extends React.Component {

    constructor(props) {
        super(props);
        let primary_contact = this.getPrimaryContact(this.props.primary_contact_id);
        this.state = {
            "filter": "",
            "primary_contact": primary_contact,
            "contact_id": null,
            "details": this.setupDetails(primary_contact),
            "emails": this.setupEmails(primary_contact),
            "phones": this.setupPhones(primary_contact),
            "addresses": [],
            // "notes": this.setupNotes(primary_contact),
            "websites": this.setupWebsites(primary_contact),
            "documents": this.setupDocuments(primary_contact),
            "is_view": false, //TODO 
            "show_more": true, //TODO 
            "contact_details_title": this.props.contact_details_title,
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "saveDetailsErrors": [],
            "calling_codes":
                this.props.countries !== null
                    ? this.setCallingCodes(this.props.countries)
                    : null,
            "transaction_methods_open": false,
            "managers_open": false,
            "mccs_open": false,
            "show_password": false,
            "current_tab": 0
        };
        this.setupAddresses = this.setupAddresses.bind(this);
        this.processCountryStates = this.processCountryStates.bind(this);
        this.setAddress = this.setAddress.bind(this);
        this.getStates = this.getStates.bind(this);
        this.setupStatesList = this.setupStatesList.bind(this);
        this.getPrimaryContact = this.getPrimaryContact.bind(this);
        this.updateContact = this.updateContact.bind(this);
    }

    getPrimaryContact(primary_contact_id) {
        let primary_contact = [];
        if (primary_contact_id) {
            primary_contact = this.props.contacts ? this.props.contacts.find((contact) => contact.id === primary_contact_id) : [];
        }
        return primary_contact !== undefined ? primary_contact : [];
    }

  handleChange = (event) => {
      this.props.handleChange(event);
  };

  handleGeneratePassword = (event) => {
    this.setState({show_password: true});
    const RandExp = require('randexp');
    let generated_password = '';
    do {
        generated_password = new RandExp(
            /^(?=.*[abcdefghijkmnpqrstuvwxz])(?=.*[ABCDEFGHJKLMNPQRSTUVWXZ])(?=.*[23456789])(?=.*[@$!#])[ABCDEFGHJKLMNPQRSTUVWXZabcdefghijkmnpqrstuvwxz123456789@$!#]{10,10}$/
        ).gen();
    }
    while (!validations.validatePassword(generated_password));
    this.props.handleManualChange("password", generated_password);
  }

  handleMultiple = (event) => {
    let selected = event.target.value;
    if (Object.keys(selected).length > 0) {
        selected = selected.filter((item) => item !== 'filter');
    }
    event.target.value = selected;
      this.props.handleMultiple(event);
  };

  handleSelectManagerTeam = (event) => {
      this.props.handleSelectManagerTeam(event);
  };

  handleSelectLead = (event) => {
      this.props.handleSelectLead(event);
  };

  handleSelectPrimaryContact = (event) => {
      this.updateContact(event.target.value);
      this.props.handleSelectPrimaryContact(event);
  };

  async updateContact(primary_contact_id) {
    let primary_contact = this.getPrimaryContact(primary_contact_id);
    this.setState({
        "primary_contact": primary_contact,
        "details": this.setupDetails(primary_contact),
        "emails": this.setupEmails(primary_contact),
        "phones": this.setupPhones(primary_contact),
        "addresses": await this.setupAddresses(primary_contact).then((res) => res),
        // "notes": this.setupNotes(primary_contact),
        "websites": this.setupWebsites(primary_contact),
        "documents": this.setupDocuments(primary_contact),
    });
  }

  handleSelectMerchantType = (event) => {
      this.props.handleSelectMerchantType(event);
  };

  handleSelectMCCCode = (event) => {
    let selected = event.target.value;
    let filterSelected = false;
    if (Object.keys(selected).length > 0) {
        filterSelected = selected.includes('filter') || selected.includes(undefined);
        selected = selected.filter((item) => item && item.length > 0).filter((item) => item !== 'filter');
        if (this.props.mcc_code) {
            selected = selected.filter((item) => item !== this.props.mcc_code);
        }
    }
    try {
        if (selected[0] === this.props.mcc_code) {
            return;
        }
    } catch(e) {}
    if (!filterSelected) {
        this.setState({mccs_open: false});
    }
    this.props.handleSelectMCCCode(Object.keys(selected).length > 0 ? selected[0] : this.props.mcc_code);
  };

  setMccTooltip(mcc) {
    let tooltip = '', addNL = '';
    if (mcc.description !== undefined && mcc.description !== null && mcc.description.length > 0) {
        tooltip = 'Description: ' + mcc.description;
        addNL = '\n\n';
    }
    if (mcc.inclusions !== undefined && mcc.inclusions !== null && mcc.inclusions.length > 0) {
        tooltip += addNL + 'Inclusions: ' + mcc.inclusions;
    }
    if (mcc.similar !== undefined && mcc.similar !== null && mcc.similar.length > 0) {
        tooltip += addNL + 'Similar: ' + mcc.similar;
    }
    return tooltip;
  }

  filterMCCs() {
    return this.state.filter.length > 0
        ? this.props.mccs.filter((mcc) => {
            let filter = this.state.filter.toLowerCase();
            return mcc.code.toLowerCase().includes(filter) ||
                mcc.title.toLowerCase().includes(filter) ||
                mcc.description.toLowerCase().includes(filter) ||
                mcc.inclusions.toLowerCase().includes(filter) ||
                mcc.similar.toLowerCase().includes(filter)
        })
        : this.props.mccs;
  }

    setCallingCodes (countries) {
        return countries
            .filter((prop) => prop.country_code !== null)
            .map((prop) => prop.country_code)
            .sort((a, b) => a.geo_country_id.localeCompare(b.geo_country_id));
    }

    componentWillReceiveProps (nextProps) {
        try {
            if (this.props.countries === null && nextProps.countries !== null) {
                this.setState({
                    "calling_codes": this.setCallingCodes(nextProps.countries)
                });
                helpers.hideLoading();
            }
        } catch(e) {}
    }

    componentDidUpdate () {
        try {
            if (this.props.countries === null) {
                helpers.showLoading();
            }
        } catch(e) {}
    }

    async componentDidMount () {
        try {
            this.setState({
                "addresses": await this.setupAddresses(this.state.primary_contact).then((res) => res)
            });
            if (this.props.countries === null) {
                helpers.showLoading();
            }
        } catch(e) {}
    }

    addSubContactFields (elements, element, state_name) {
        if (element.hasOwnProperty("default")) {
            element.default = false;
        }
        elements.push(element);
        this.setState({[state_name]: elements});
    }

    getLength (str) {
        if (str === null || str === undefined) {
            return 0;
        }
        return `${str}`.length;
      }
  
    validateFields () {
        let all_correct = true,
            // DETAILS
            {details} = this.state;
        this.setState({details});
        // DETAILS
  
        // EMAILS
        const emails = this.state.emails.map((prop, key) => {
            prop.valid =
              this.getLength(prop.value) === 0 ||
              validations.validateEmail(prop.value)
              ? ""
              : "error";
            if (prop.valid === "error") {
                all_correct = false;
            }
            return prop;
        });
        this.setState({emails});
        // EMAILS
  
        // PHONES
        const phones = this.state.phones.map((prop, key) => {
            if (this.getLength(prop.value) > 0) {
                prop.valid = "";
                prop.service_valid = "";
                prop.geo_valid = "";
                if (!validations.validateIfNumber(prop.value)) {
                    prop.valid = "error";
                    all_correct = false;
                } else {
                    if (this.getLength(prop.service) === 0) {
                        prop.service_valid = "error";
                        all_correct = false;
                    }
                    if (this.getLength(prop.geo_country_id) === 0) {
                        prop.geo_valid = "error";
                        all_correct = false;
                    }
                }
            }
            return prop;
        });
        this.setState({phones});
        // PHONES
  
        // ADDRESSES
        const addresses = this.state.addresses.map((prop, key) => {
            prop.address_valid = "";
            prop.city_valid = "";
            prop.state_valid = "";
            prop.geo_valid = "";
            prop.postcode_valid = "";
            if (
              this.getLength(prop.address) > 0 ||
              this.getLength(prop.city) > 0 ||
              this.getLength(prop.state) > 0 ||
              this.getLength(prop.postcode) > 0
            ) {
                if (this.getLength(prop.address) === 0) {
                    prop.address_valid = "error";
                    all_correct = false;
                }
                if (this.getLength(prop.city) === 0) {
                    prop.city_valid = "error";
                    all_correct = false;
                }
                if (this.getLength(prop.state) === 0) {
                    prop.state_valid = "error";
                    all_correct = false;
                }
                if (this.getLength(prop.geo_country_id) === 0) {
                    prop.geo_valid = "error";
                    all_correct = false;
                }
                if (this.getLength(prop.postcode) === 0) {
                    prop.postcode_valid = "error";
                    all_correct = false;
                }
            }
            return prop;
        });
        this.setState({addresses});
        // ADDRESSES
  
        // NOTES
        // const notes = this.state.notes.map((prop, key) => {
        //     prop.author_valid = "";
        //     prop.subject_valid = "";
        //     prop.note_valid = "";
        //     if (this.getLength(prop.author) > 0 || this.getLength(prop.subject) > 0) {
        //         if (this.getLength(prop.note) === 0) {
        //             prop.note_valid = "error";
        //             all_correct = false;
        //         }
        //     }
        //     return prop;
        // });
        // this.setState({notes});
        // NOTES
  
        // WEBSITES
        const websites = this.state.websites.map((prop, key) => {
            prop.url_valid = this.getLength(prop.url) === 0 || validations.validateURL(prop.url)
              ? ""
              : "error";
            if (prop.url_valid === "error") {
                all_correct = false;
            }
            return prop;
        });
        this.setState({websites});
        // WEBSITES
  
        return all_correct;
    }
    
    setupDetails (contact) {
      try {
          if (Object.keys(contact).length > 0) {
              return {
                  "id": contact.id,
                  "first_name": {"value": contact.first_name, "valid": ""},
                  "last_name": {"value": contact.last_name, "valid": ""},
                  "company": {"value": contact.company ? contact.company : "", "valid": ""},
                  "position": {"value": contact.position ? contact.position : "", "valid": ""},
                  "tags": {
                      "value": contact.tags && this.getLength(contact.tags) > 0 ? JSON.parse(contact.tags) : [],
                      "valid": ""
                  }
              };
          }
      } catch (e) {}
      return contactDefaults.details();
    }

    createDetailsUI () {
      return (
          <GridContainer
              key="details_grid"
              style={{"borderTop": "#9c27b0 solid 1px"}}
          >
              <GridItem md={6}>
                  <CustomInput
                      error={this.props.primary_contact_first_name_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          "onChange": (event) => this.handleContactChange(
                              event,
                              "details",
                              "first_name",
                              "text"
                          ),
                          "value": this.state.details.first_name.value,
                          "name": "first_name",
                          "disabled": this.state.is_view
                      }}
                      labelText="First Name"
                  />
              </GridItem>
              <GridItem md={6}>
                  <CustomInput
                      error={this.props.primary_contact_last_name_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          "onChange": (event) => this.handleContactChange(
                              event,
                              "details",
                              "last_name",
                              "text"
                          ),
                          "value": this.state.details.last_name.value,
                          "name": "last_name",
                          "disabled": this.state.is_view
                      }}
                      labelText="Last Name"
                  />
              </GridItem>
              {this.getLength(this.state.details.company.value) > 0 || !this.state.is_view
                  ? <GridItem md={6}>
                      <CustomInput
                          error={this.state.details.company.valid === "error"}
                          formControlProps={{"fullWidth": true}}
                          inputProps={{
                              "onChange": (event) => this.handleContactChange(
                                  event,
                                  "details",
                                  "company",
                                  "text"
                              ),
                              "value": this.state.details.company.value,
                              "name": "company",
                              "disabled": this.state.is_view
                          }}
                          labelText="Company"
                      />
                  </GridItem>
                  : ""}
              {this.getLength(this.state.details.position.value) > 0 || !this.state.is_view
                  ? <GridItem md={6}>
                      <CustomInput
                          error={this.state.details.position.valid === "error"}
                          formControlProps={{"fullWidth": true}}
                          inputProps={{
                              "onChange": (event) => this.handleContactChange(
                                  event,
                                  "details",
                                  "position",
                                  "text"
                              ),
                              "value": this.state.details.position.value,
                              "name": "position",
                              "disabled": this.state.is_view
                          }}
                          labelText="Position"
                      />
                  </GridItem>
                  : ""}
              {this.state.details.tags.value.length > 0 || !this.state.is_view
                  ? <GridItem
                      md={12}
                      sm={12}
                      xs={12}
                    >
                      <label className="customRadioTopLabel">
                          Tags
                      </label>
                      <TagsInput
                          disabled={this.state.is_view}
                          onChange={(tags) => this.handleContactChange(
                              tags,
                              "details",
                              "tags",
                              "text"
                          )}
                          onlyUnique
                          tagProps={{"className": "react-tagsinput-tag primary"}}
                          value={this.state.details.tags.value}
                      />
                  </GridItem>
                  : ""}
          </GridContainer>
      );
    }

    setupEmails (contact) {
      try {

          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.emails).length > 0
          ) {
              return Object.keys(contact.emails).map((prop, key) => ({
                  "id": contact.emails[prop].id,
                  "default": contact.emails[prop].default === 1,
                  "value": contact.emails[prop].email_address,
                  "valid": ""
              }));
          }
      } catch (e) {}
      return contactDefaults.email();
    }

    createEmailsUI () {
      const container = this.state.emails
        .map((prop, key) => (!this.state.is_view || this.getLength(prop.value) > 0
            ? this.generateEmailGrid(prop, key)
            : ""))
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_emails_grid"
            //   style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add email'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {

                              this.addSubContactFields(
                                  this.state.emails,
                                  contactDefaults.email()[0],
                                  "emails"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_email_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateEmailGrid (prop, key) {
      const {classes} = this.props,
          grid =
              (<GridContainer key={`emails_grid_${key}`}>
              <GridItem md={1}>
                  <label
                      htmlFor={`emails_radio_${key}`}
                      className="customRadioTopLabel"
                      style={{"width": "50px"}}
                  >
                        Default
                  </label>
                  <FormControlLabel
                      disabled={this.state.is_view}
                      control={
                          <Checkbox
                              checked={prop.default}
                              onChange={(event) => this.handleContactChange(
                                  event,
                                  "emails",
                                  "default",
                                  "checkbox",
                                  key
                              )}
                              value={key}
                              name={`emails_radio_${key}`}
                              aria-label={key}
                              icon={<FiberManualRecord className={classes.radioUnchecked} />}
                              checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                  disabled: classes.disabledCheckboxAndRadio
                              }}
                          />
                      }
                      classes={{"label": classes.label,
                          root: classes.labelRoot}}
                  />
              </GridItem>
              <GridItem md={11}>
                  <CustomInput
                      success={prop.valid === "success"}
                      error={prop.valid === "error"}
                      labelText="Email"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleContactChange(
                              event,
                              "emails",
                              "value",
                              "email",
                              key
                          ),
                          value: prop.value,
                          name: `email_${key}`,
                          disabled: this.state.is_view,
                          type: "email"
                      }}
                  />
              </GridItem>
          </GridContainer>);
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_emails_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove email'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {

                          let {emails} = this.state;
                          delete emails[key];
                          emails = emails.filter((el) => el != null);
                          if (emails.length === 0 || Object.keys(emails).length === 0) {

                              emails = contactDefaults.email();

                          }
                          this.setState({emails});

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_email_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    setupPhones (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.phones).length > 0
          ) {
              return Object.keys(contact.phones).map((prop, key) => ({
                  "id": contact.phones[prop].id,
                  "default": contact.phones[prop].default === 1,
                  "service": contact.phones[prop].service,
                  "geo_country_id": contact.phones[prop].geo_country_id,
                  "value": contact.phones[prop].phone_number,
                  "valid": "",
                  "service_valid": "",
                  "geo_valid": ""
              }));
          }
      } catch (e) {}
      return contactDefaults.phone();
    }

    createPhonesUI () {
      const container = this.state.phones
        .map((prop, key) => (!this.state.is_view || this.getLength(prop.value) > 0
            ? this.generatePhoneGrid(prop, key)
            : ""))
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_phones_grid"
            //   style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add phone'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {

                              this.addSubContactFields(
                                  this.state.phones,
                                  contactDefaults.phone()[0],
                                  "phones"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_phone_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generatePhoneGrid (prop, key) {
      const {classes} = this.props,
          grid =
              (<GridContainer key={`phones_grid_${key}`}>
              <GridItem
                xs={3}
                md={1}
              >
                  <label
                      htmlFor={`phones_radio_${key}`}
                      className="customRadioTopLabel"
                      style={{"width": "50px"}}
                  >
                    Default
                  </label>
                  <FormControlLabel
                      disabled={this.state.is_view}
                      control={
                          <Checkbox
                              checked={prop.default}
                              onChange={(event) => this.handleContactChange(
                                  event,
                                  "phones",
                                  "default",
                                  "checkbox",
                                  key
                              )}
                              value={key}
                              name={`phones_radio_${key}`}
                              aria-label={key}
                              icon={<FiberManualRecord className={classes.radioUnchecked} />}
                              checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                  disabled: classes.disabledCheckboxAndRadio
                              }}
                          />
                      }
                      classes={{"label": classes.label,
                          root: classes.labelRoot}}
                  />
              </GridItem>
              <GridItem
                xs={3}
                md={2}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.service_valid === "success" ? "success" : ""}
                          error={prop.service_valid === "error"}
                          htmlFor="contact_services"
                          className={classes.selectLabel}
                      >
                        Service
                      </InputLabel>
                      <Select
                          value={prop.service}
                          onChange={(event) => this.handleContactChange(
                              event,
                              "phones",
                              "service",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `services_${key}`,
                              id: `services_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          <MenuItem
                              key={`service_phone${key}`}
                              classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value="CELLULAR"
                          >
                              <i
                                key={`i_service_phone${  key}`}
                                className="fa fa-mobile-alt"
                              />
                          </MenuItem>
                          <MenuItem
                              key={`service_landline${key}`}
                              classes={{
                                  root: classes.selectMenuItem,
                                  selected: classes.selectMenuItemSelectedMultiple
                              }}
                              value="LANDLINE"
                          >
                              <i
                                  key={`i_service_landline${key}`}
                                  className="fa fa-phone-alt"
                              />
                          </MenuItem>
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={6}
                md={3}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.geo_valid === "success" ? "success" : ""}
                          error={prop.geo_valid === "error"}
                          htmlFor="contact_geo"
                          className={classes.selectLabel}
                      >
                        Code
                      </InputLabel>
                      <Select
                          value={prop.geo_country_id}
                          onChange={(event) => this.handleContactChange(
                              event,
                              "phones",
                              "geo_country_id",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `services_${key}`,
                              id: `services_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          {this.state.calling_codes !== null
                              ? this.state.calling_codes.map((prop, key) => 
                      <MenuItem
                        key={"country_" + key}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelectedMultiple
                        }}
                        value={prop.geo_country_id}
                      >
                        {prop.geo_country_id + " (" + prop.calling_code + ")"}
                      </MenuItem>
                    )
                              : ""}
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                md={6}
              >
                  <CustomInput
                      success={prop.valid === "success"}
                      error={prop.valid === "error"}
                      labelText="Phone Number"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleContactChange(
                              event,
                              "phones",
                              "value",
                              "tel",
                              key
                          ),
                          value: prop.value,
                          name: `phone_${key}`,
                          disabled: this.state.is_view,
                          type: "tel"
                      }}
                  />
              </GridItem>
          </GridContainer>);
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_phones_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove phone'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {

                          const {phones} = this.state;
                          delete phones[key];
                          this.setState({
                              "phones":
                  Object.keys(phones).length > 0
                      ? phones
                      : contactDefaults.phone()
                          });

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_phone_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    async setupAddresses (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.addresses).length > 0
          ) {
              return Object.keys(contact.addresses).map((prop, key) => {
                  this.processCountryStates(
                      contact.addresses[prop].states,
                      contact.addresses[prop].geo_country_id
                  );
                  return {
                      "id": contact.addresses[prop].id,
                      "default": contact.addresses[prop].default === 1,
                      "address": contact.addresses[prop].address,
                      "city": contact.addresses[prop].city,
                      "state": contact.addresses[prop].state,
                      "states": contact.addresses[prop].states,
                      "geo_country_id": contact.addresses[prop].geo_country_id,
                      "postcode": contact.addresses[prop].postcode,
                      "address_valid": "",
                      "city_valid": "",
                      "state_valid": "",
                      "geo_valid": "",
                      "postcode_valid": ""
                  };
              });
          }
      } catch (e) {}
      return [await this.setAddress(contactDefaults.address()[0]).then((res) => res)];
    }

    async setAddress (address) {
      address.states = await this.getStates(address.geo_country_id).then((res) => res);
      return address;
    }

    async getStates (geo_country_id) {
      if (this.props.countries === null) {
          return null;
      }
      const country = this.props.countries.filter((country) => country.geo_country_id === geo_country_id);
      if (country.length > 0 && country[0].states) {
          return this.setupStatesList(country[0].states);
      }

      helpers.showLoading();
      await axios(this.props)
        .get(`/countries/${geo_country_id}`)
        .then((response) => this.processCountryStates(
              response.data.country_states,
              geo_country_id
          ))
          .catch((error) => {
              this.processErrorAxios(
                  error,
                  null
              );
              return null;
          });
      return await this.getStates(geo_country_id).then((res) => res);
    }

    setupStatesList (states) {
      return states.map((prop, key) => {
          const state_id = prop.geo_region_code.split("-");
          prop.geo_region_code = state_id.length > 1 ? state_id[1] : state_id[0];
          return prop;
      });
    }

    processCountryStates (states, geo_country_id) {
      if (this.props.countries === null) return null;
      this.props.countries.map((country, key) => {
          if (country.geo_country_id === geo_country_id) {
              country.states = this.setupStatesList(states);
          }
          return country;
      });
      helpers.hideLoading();
    }

    createAddressesUI () {
      const container = this.state.addresses
        .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
          (this.getLength(prop.address) > 0 ||
            this.getLength(prop.city) > 0 ||
            this.getLength(prop.postcode) > 0 ||
            this.getLength(prop.state) > 0 ||
            (!this.state.is_view && this.state.addresses.length > 1))
              ? this.generateAddressesGrid(prop, key)
              : "")
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_addresses_grid"
            //   style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add address'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={async () => {

                              this.addSubContactFields(
                                  this.state.addresses,
                                  await this.setAddress(contactDefaults.address()[0]).then((res) => res),
                                  "addresses"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_address_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateAddressesGrid (prop, key) {
      const {classes} = this.props,
          grid =
              (<GridContainer key={`addresses_grid_${key}`}>
              <GridItem
                xs={3}
                md={1}
              >
                  <label
                      htmlFor={`addresses_radio_${key}`}
                      className="customRadioTopLabel"
                      style={{"width": "50px"}}
                  >
                    Default
                  </label>
                  <FormControlLabel
                      disabled={this.state.is_view}
                      control={
                          <Checkbox
                              checked={prop.default}
                              onChange={(event) => this.handleContactChange(
                                  event,
                                  "addresses",
                                  "default",
                                  "checkbox",
                                  key
                              )}
                              value={key}
                              name={`addresses_radio_${key}`}
                              aria-label={key}
                              icon={<FiberManualRecord className={classes.radioUnchecked} />}
                              checkedIcon={
                                  <FiberManualRecord className={classes.radioChecked} />
                              }
                              classes={{
                                  checked: classes.radio,
                                  root: classes.radioRoot,
                                  disabled: classes.disabledCheckboxAndRadio
                              }}
                          />
                      }
                      classes={{"label": classes.label,
                          root: classes.labelRoot}}
                  />
              </GridItem>
              <GridItem
                xs={9}
                md={7}
              >
                <AddressAutocomplete
                    style={{"paddingTop": "11px"}}
                    {...this.props}
                    title={"Address"}
                    initValue={prop.address === null || prop.address === undefined ? "" : prop.address}
                    auth={this.props.auth}
                    key={`address_${key}`}
                    disabled={this.state.is_view}
                    onBlur={(key, value) => {}}
                    onChange={(value) => {
                        this.handleContactChange(
                            value,
                            "addresses",
                            "address",
                            "text",
                            key
                        )
                    }}
                    onChangeSelect={(selected) => {
                        if (selected !== null && selected.place_id.length > 0) {
                            try {
                                this.fillFromAddress(selected, key);
                            } catch(e) {}
                        }
                    }}
                />
              </GridItem>
              <GridItem
                xs={12}
                md={4}
              >
                  <CustomInput
                      success={prop.city_valid === "success"}
                      error={prop.city_valid === "error"}
                      labelText="City"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleContactChange(
                              event,
                              "addresses",
                              "city",
                              "text",
                              key
                          ),
                          value: prop.city,
                          name: `city_${key}`,
                          disabled: this.state.is_view
                      }}
                  />
              </GridItem>
              <GridItem
                xs={12}
                md={4}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.geo_valid === "success" ? "success" : ""}
                          error={prop.geo_valid === "error"}
                          htmlFor="contact_countries"
                          className={classes.selectLabel}
                      >
                        Country
                      </InputLabel>
                      <Select
                          value={prop.geo_country_id}
                          onChange={(event) => this.handleContactChange(
                              event,
                              "addresses",
                              "geo_country_id",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `states_${key}`,
                              id: `states_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          {this.props.countries !== null
                              ? this.props.countries.map((prop, key) => 
                                <MenuItem
                                    key={"country_" + key}
                                    classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={prop.geo_country_id}
                                >
                                    {prop.name}
                                </MenuItem>
                                )
                              : ""}
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                md={5}
              >
                  <FormControl
                      disabled={this.state.is_view}
                      fullWidth
                      className={classes.selectFormControl}
                  >
                      <InputLabel
                          success={prop.state_valid === "success" ? "success" : ""}
                          error={prop.state_valid === "error"}
                          htmlFor="contact_states"
                          className={classes.selectLabel}
                      >
                        State
                      </InputLabel>
                      <Select
                          value={
                              prop.states == null
                                  ? ""
                                  : prop.states.filter((state) => state.geo_region_code === prop.state).length > 0
                                      ? prop.state
                                      : ""
                          }
                          onChange={(event) => this.handleContactChange(
                              event,
                              "addresses",
                              "state",
                              "select",
                              key
                          )}
                          MenuProps={{"className": classes.selectMenu}}
                          classes={{"select": classes.select}}
                          inputProps={{
                              name: `states_${key}`,
                              id: `states_${key}`,
                              disabled: this.state.is_view
                          }}
                      >
                          {prop.states !== null && prop.states !== undefined
                              ? prop.states.map((prop, key) => 
                                <MenuItem
                                    key={"country_" + key}
                                    classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value={prop.geo_region_code}
                                >
                                    {prop.name
                                    .toLowerCase()
                                    .replace(/(?:^|\s)\S/g, function(a) {
                                        return a.toUpperCase();
                                    })}
                                </MenuItem>
                                )
                              : ""}
                      </Select>
                  </FormControl>
              </GridItem>
              <GridItem
                xs={12}
                md={3}
              >
                  <CustomInput
                      success={prop.postcode_valid === "success"}
                      error={prop.postcode_valid === "error"}
                      labelText="Postcode"
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          onChange: (event) => this.handleContactChange(
                              event,
                              "addresses",
                              "postcode",
                              "text",
                              key
                          ),
                          value: prop.postcode,
                          name: `postcode_${key}`,
                          disabled: this.state.is_view
                      }}
                  />
              </GridItem>
          </GridContainer>);
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_address_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove address'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={async () => {

                          const {addresses} = this.state;
                          delete addresses[key];
                          this.setState({
                              "addresses":
                  Object.keys(addresses).length > 0
                      ? addresses
                      : [await this.setAddress(contactDefaults.address()[0]).then((res) => res)]
                          });

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_address_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    fillFromAddress(address, key) {
        try {
            helpers.showLoading();
            axios(this.props)
                .post('/address/details', {'id': address.place_id})
                .then((response) => {
                    try {
                        this.handleContactChange(response.data.result.address, "addresses", "address", "text", key);
                    } catch(e) {}
                    try {
                        this.handleContactChange(response.data.result.city,"addresses", "city", "text", key);
                    } catch(e) {}
                    try {
                        this.handleContactChange(response.data.result.country_code,"addresses", "geo_country_id", "text", key);
                    } catch(e) {}
                    try {
                        this.handleContactChange(response.data.result.state,"addresses", "state", "select", key);
                    } catch(e) {}
                    try {
                        this.handleContactChange(response.data.result.post_code,"addresses", "postcode", "text", key);
                    } catch(e) {}

                    helpers.hideLoading();
                })
                .catch((error) => helpers.hideLoading());
        } catch(e) {}
    }

    // setupNotes (contact) {
    //   try {
    //       if (
    //         Object.keys(contact).length > 0 &&
    //         Object.keys(contact.notes).length > 0
    //       ) {
    //           return Object.keys(contact.notes).map((prop, key) => ({
    //               "id": contact.notes[prop].id,
    //               "author": contact.notes[prop].author,
    //               "subject": contact.notes[prop].subject,
    //               "note": contact.notes[prop].note,
    //               "author_valid": "",
    //               "subject_valid": "",
    //               "note_valid": ""
    //           }));
    //       }
    //   } catch (e) {}
    //   return contactDefaults.note();
    // }

    // createNotesUI () {
    //   const container = this.state.notes
    //     .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
    //       (this.getLength(prop.author) > 0 ||
    //         this.getLength(prop.subject) > 0 ||
    //         this.getLength(prop.note) > 0) ||
    //       (!this.state.is_view && this.state.notes.length > 1)
    //           ? this.generateNotesGrid(prop, key)
    //           : "")
    //       .filter((item) => Object.keys(item).length > 0);

    //   return Object.keys(container).length > 0
    //       ? <GridContainer
    //           key="main_notes_grid"
    //           style={{"borderTop": "#9c27b0 solid 1px"}}
    //         >
    //           <GridItem md={12}>
    //               {container}
    //           </GridItem>
    //           {this.state.is_view
    //               ? ""
    //               : <GridItem
    //                   className="div-pad-r-7"
    //                   md={12}
    //               >
    //                   <Button
    //                       title={'Note address'}
    //                       className="small-button div-marg-tb-0 div-float-right"
    //                       color="success"
    //                       justIcon
    //                       onClick={() => {

    //                           this.addSubContactFields(
    //                               this.state.notes,
    //                               contactDefaults.note()[0],
    //                               "notes"
    //                           );

    //                       }}
    //                       simple
    //                   >
    //                       <i
    //                           className="fa fa-plus fa-sm"
    //                           key="i_notes_add"
    //                       />
    //                   </Button>
    //                 </GridItem>}
    //       </GridContainer>
    //       : container;
    // }

    // generateNotesGrid (prop, key) {
    //   const grid =
    //       <GridContainer key={`notes_grid_${  key}`}>
    //       <GridItem xs={8}
    //           md={6}>
    //           <CustomInput
    //               success={prop.author_valid === "success"}
    //               error={prop.author_valid === "error"}
    //               labelText="Author"
    //               formControlProps={{fullWidth: true}}
    //               inputProps={{
    //                   onChange: (event) => this.handleContactChange(event,
    //                     "notes",
    //                     "author",
    //                     "text",
    //                     key),
    //                   value: prop.author,
    //                   name: `author_${  key}`,
    //                   disabled: this.state.is_view
    //               }}
    //           />
    //       </GridItem>
    //       <GridItem xs={8}
    //           md={6}>
    //           <CustomInput
    //               success={prop.subject_valid === "success"}
    //               error={prop.subject_valid === "error"}
    //               labelText="Subject"
    //               formControlProps={{fullWidth: true}}
    //               inputProps={{
    //                   onChange: (event) => this.handleContactChange(event,
    //                     "notes",
    //                     "subject",
    //                     "text",
    //                     key),
    //                   value: prop.subject,
    //                   name: `subject_${  key}`,
    //                   disabled: this.state.is_view
    //               }}
    //           />
    //       </GridItem>
    //       <GridItem xs={8}
    //           md={12}>
    //           <CustomInput
    //               success={prop.note_valid === "success"}
    //               error={prop.note_valid === "error"}
    //               labelText="Note"
    //               formControlProps={{fullWidth: true}}
    //               inputProps={{
    //                   onChange: (event) => this.handleContactChange(event,
    //                     "notes",
    //                     "note",
    //                     "text",
    //                     key),
    //                   value: prop.note,
    //                   name: `note_${  key}`,
    //                   disabled: this.state.is_view
    //               }}
    //           />
    //       </GridItem>
    //   </GridContainer>;
    //   return this.state.is_view
    //       ? grid
    //       : <GridContainer key={`container_notes_grid_${key}`}>
    //           <GridItem md={11}>
    //               {grid}
    //           </GridItem>
    //           <GridItem
    //               className="div-pad-lr-0"
    //               md={1}
    //           >
    //               <Button
    //                   title={'Remove note'}
    //                   className="small-button"
    //                   color={prop.id === null ? "warning" : "danger"}
    //                   justIcon
    //                   onClick={() => {

    //                       const {notes} = this.state;
    //                       delete notes[key];
    //                       this.setState({
    //                           "notes":
    //               Object.keys(notes).length > 0 ? notes : contactDefaults.note()
    //                       });

    //                   }}
    //                   simple
    //               >
    //                   <i
    //                       className={
    //                           prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
    //                       }
    //                       key={`i_note_delete${key}`}
    //                   />
    //               </Button>
    //           </GridItem>
    //       </GridContainer>;
    // }

    setupWebsites (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.websites).length > 0
          ) {
              return Object.keys(contact.websites).map((prop, key) => ({
                  "id": contact.websites[prop].id,
                  "url": contact.websites[prop].url,
                  "url_valid": ""
              }));
          }
      } catch (e) {}
      return contactDefaults.website();
    }

    createWebsitesUI () {
      const container = this.state.websites
        .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
          this.getLength(prop.url) > 0 ||
          (!this.state.is_view && this.state.websites.length > 1)
              ? this.generateWebsitesGrid(prop, key)
              : "")
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_websites_grid"
            //   style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add url'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {

                              this.addSubContactFields(
                                  this.state.websites,
                                  contactDefaults.website()[0],
                                  "websites"
                              );

                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_websites_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateWebsitesGrid (prop, key) {
      const grid =
          <GridContainer key={`websites_grid_${  key}`}>
          <GridItem xs={8}
              md={12}>
              <CustomInput
                  success={prop.url_valid === "success"}
                  error={prop.url_valid === "error"}
                  labelText="URL"
                  formControlProps={{fullWidth: true}}
                  inputProps={{
                      onChange: (event) => this.handleContactChange(event,
                        "websites",
                        "url",
                        "url",
                        key),
                      value: prop.url,
                      name: `url_${  key}`,
                      disabled: this.state.is_view,
                      onFocus: ((event) => {
                        if (!prop.url) {
                            let state_value = this.state['websites'];
                            state_value[key]['url'] = 'https://';
                            this.setState({"websites": state_value});
                        }
                      }),
                      onBlur: ((event) => {
                        if (prop.url.toLowerCase() === 'https://' || prop.url.toLowerCase() === 'http://') {
                            let state_value = this.state['websites'];
                            state_value[key]['url'] = '';
                            this.setState({"websites": state_value});
                        }
                      })
                  }}
              />
          </GridItem>
      </GridContainer>;
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_websites_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove url'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {

                          const {websites} = this.state;
                          delete websites[key];
                          this.setState({
                              "websites":
                  Object.keys(websites).length > 0
                      ? websites
                      : contactDefaults.website()
                          });

                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_website_delete${key}`}
                      />
                  </Button>
              </GridItem>
          </GridContainer>;
    }

    setupDocuments (contact) {
      try {
          if (
            Object.keys(contact).length > 0 &&
            Object.keys(contact.documents).length > 0
          ) {
              return Object.keys(contact.documents).map((prop, key) => ({
                  "id": contact.documents[prop].id,
                  "filename": contact.documents[prop].filename,
                  "storage_filename": contact.documents[prop].storage_filename,
                  "filename_valid": "",
                  "filename_tmp": "",
                  "file": null,
                  "tags": contact.documents[prop].tags,
                  "tags_tmp": contact.documents[prop].tags
              }));
          }
      } catch (e) {}
      return contactDefaults.document();
    }

    createDocumentsUI () {
      const container = this.state.documents
        .map((prop, key) => (!this.state.is_view && this.state.show_more) ||
          this.getLength(prop.filename) > 0 ||
          (!this.state.is_view && this.state.documents.length > 1)
              ? this.generateDocumentsGrid(prop, key)
              : "")
          .filter((item) => Object.keys(item).length > 0);

      return Object.keys(container).length > 0
          ? <GridContainer
              key="main_documents_grid"
            //   style={{"borderTop": "#9c27b0 solid 1px"}}
            >
              <GridItem md={12}>
                  {container}
              </GridItem>
              {this.state.is_view
                  ? ""
                  : <GridItem
                      className="div-pad-r-7"
                      md={12}
                  >
                      <Button
                          title={'Add file'}
                          className="small-button div-marg-tb-0 div-float-right"
                          color="success"
                          justIcon
                          onClick={() => {
                              this.addSubContactFields(
                                  this.state.documents,
                                  contactDefaults.document()[0],
                                  "documents"
                              );
                          }}
                          simple
                      >
                          <i
                              className="fa fa-plus fa-sm"
                              key="i_document_add"
                          />
                      </Button>
                    </GridItem>}
          </GridContainer>
          : container;
    }

    generateDocumentsGrid (prop, key) {
      const grid =
          <GridContainer
          key={`documents_grid_${  key}`}
          style={{borderBottom: "1px solid #ded9d9"}}
      >
          <GridItem>
              {prop.id !== null
                ? <div style={{"marginTop": "10px"}}>
                    {prop.filename}
                    </div>
                : ""
              }
              {this.state.is_view
                ? ""
                : <div
                    style={
                        prop.id === null
                        ? { marginTop: "20px" }
                        : { paddingBottom: "0px" }
                    }
                    >
                    <div className={"upload-btn-wrapper"} style={{ width: "100%" }}>
                        <button className={"btn"} style={{ marginRight: "10px" }}>
                        Choose a{prop.id === null ? "" : " replacement"} file
                        </button>
                        <input
                        type="file"
                        name="newAvatarImage"
                        onChange={event =>
                            this.handleContactChange(
                            event,
                            "documents",
                            "filename",
                            "file",
                            key
                            )
                        }
                        accept="*"
                        value=""
                        />
                        <label htmlFor={"document_" + key} style={{ color: "#495057" }}>
                        <b style={{ color: prop.id === null ? "#4caf50" : "red" }}>
                            {this.getLength(prop.filename_tmp) > 0
                            ? prop.id === null
                                ? "New "
                                : "Update "
                            : ""}
                        </b>
                        {prop.filename_tmp}
                        </label>
                    </div>
                  </div>
              }
              <div>
                  <span
                      className="customRadioTopLabel"
                      style={{paddingTop: "0px",
                        display: "inline"}}
                  >
                    Tags:{" "}
                  </span>
                  <TagsInput
                      value={prop.tags_tmp ? prop.tags_tmp : []}
                      onChange={(tags) => this.handleContactChange(tags,
                        "documents",
                        "tags",
                        "file",
                        key)}
                      tagProps={{className: "react-tagsinput-tag primary"}}
                      onlyUnique
                      disabled={this.state.is_view}
                  />
              </div>
          </GridItem>
      </GridContainer>;
      return this.state.is_view
          ? grid
          : <GridContainer key={`container_documents_grid_${key}`}>
              <GridItem md={11}>
                  {grid}
              </GridItem>
              <GridItem
                  className="div-pad-lr-0"
                  md={1}
              >
                  <Button
                      title={'Remove file'}
                      className="small-button"
                      color={prop.id === null ? "warning" : "danger"}
                      justIcon
                      onClick={() => {
                          const documents = this.state.documents.filter((document) => document.id !== prop.id);
                          this.setState({
                              "documents": documents.length > 0 ? documents : contactDefaults.document()
                          });
                      }}
                      simple
                  >
                      <i
                          className={
                              prop.id === null ? "fa fa-minus fa-sm" : "fa fa-times fa-sm"
                          }
                          key={`i_document_delete${key}`}
                      />
                  </Button>
                  {prop.id !== null && this.getLength(prop.filename_tmp) > 0
                      ? <Button
                          title={'Restore previous file'}
                          className="small-button div-marg-tb-0"
                          color="warning"
                          justIcon
                          onClick={() => {

                              const {documents} = this.state;
                              documents[key].filename_tmp = "";
                              documents[key].file = null;
                              this.setState({documents});

                          }}
                          simple
                      >
                          <i
                              className="fa fa-undo fa-sm"
                              key={`i_document_undo${key}`}
                          />
                        </Button>
                      : ""}
              </GridItem>
          </GridContainer>;
    }

    async handleContactChange (event, stateName, field, type, key) {
      let state_value = this.state[stateName];
      if (stateName === "details") {
          if (field === "tags") {
              state_value[field].value = event;
          } else {
              state_value[field].value = event.target.value;
              state_value[field].valid = "";
              if (
                  (field === "first_name" || field === "last_name") &&
                  this.getLength(event.target.value) === 0
              ) {
                  state_value[field].valid = "error";
              }
          }
      } else if (type === "file" && field === "tags") {
          state_value[key].tags_tmp = event;
      } else {
          if (field === "default") {
              if (event.target.checked) {
                  state_value.map((prop, key) => prop.default = false);
              }
              state_value[key][field] = event.target.checked;
          } else if (type === "file" && event.target.files[0]) {
              let attach = true;
              try {
                  if (Math.round(event.target.files[0].size / 1024) >= 4096) {
                      this.setState({
                          "btnDisabled": false,
                          "doneModal": false,
                          "doneModalMsg":
                            `${event.target.files[0].name
                            } is too big, please select a file that is less than 4mb`,
                          "doneModalBtn": "OK",
                          "tr_color": "danger"
                      });
                      this.showUsersNotification();
                      attach = false;
                  }
              } catch (e) {}
              if (attach) {
                  state_value[key][`${field}_tmp`] = event.target.files[0].name;
                  state_value[key].file = event.target.files[0];
              }
          } else if (stateName === "phones" && field === "value") {
              try {
                  state_value[key][field] = event.target.value.replace(/[^\d]/g, "");
                //   state_value[key][field] =
                //     `${parseInt(
                //         event.target.value.replace(
                //             /^0+/,
                //             ""
                //         ).replace(
                //             /[^\d]/g,
                //             ""
                //         ),
                //         10
                //     )}`;
              } catch (e) {
                  state_value[key][field] = "";
              }
          } else {
              state_value[key][field] = event.hasOwnProperty('target') && event.target.hasOwnProperty('value') ? event.target.value : event;
          }

          if (stateName === "addresses" && field === "geo_country_id") {
              state_value[key].states = await this.getStates(
                event.hasOwnProperty('target') && event.target.hasOwnProperty('value') ? event.target.value : event
              ).then((res) => res);
          }

          state_value = this.doValidate(
              event,
              field,
              type,
              key,
              state_value
          );
      }
      this.setState({"stateName": state_value});
    }

    doValidate (event, field, type, key, state_value) {
      const valid_name =
      field === "value"
          ? "valid"
          : (field === "geo_country_id"
              ? "geo_valid"
              : `${field}_valid`);
      switch (type) {
        case "email":
            state_value[key][valid_name] = validations.validateEmail(event.target.value)
                ? ""
                : "error";
            break;
        case "number":
            if (this.getLength(event.target.value) > 0) {
                state_value[key][valid_name] = validations.validateIfNumber(event.target.value)
                    ? ""
                    : "error";
            }
            break;
        case "url":
            if (this.getLength(event.target.value) > 0) {
                state_value[key][valid_name] = validations.validateURL(event.target.value)
                    ? ""
                    : "error";
            }
            break;
        default:
            break;
      }
      return state_value;
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                // this.showUsersNotification();
            }
        );
    }

  render () {
      const {classes} = this.props;
      let mcc_details = this.props.mccs.filter((mcc) => mcc.code === this.props.mcc_code)[0];
      return (
          <Dialog
              aria-describedby="avatar-update-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{"root": `${classes.center} ${classes.modalRoot}`}}
              keepMounted
              onClose={() => this.props.onClose("clientDetailsModal")}
              open={this.props.clientDetailsModal}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  {this.props.action === "update_client"
                      ? "Update Client Details"
                      : "Create Client"}
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="avatar-update-modal-slide-description"
                  style={{"maxWidth": "600px"}}
              >
                  <input
                      name="id"
                      type="hidden"
                      value={this.props.id}
                  />
                  {this.props.action === "update_client" && this.props.username
                  ? <GridContainer
                    key="main_username_grid"
                    >
                        <GridItem md={6}>
                            <form>
                                <CustomInput
                                    error={this.props.username_valid === "error"}
                                    formControlProps={{"fullWidth": true}}
                                    helperText={
                                        this.props.username_valid === "error" && this.props.username
                                            ? validations.usernameErrorMsg()
                                            : undefined
                                    }
                                    inputProps={{
                                        "disabled": this.props.action === "update_client",
                                        "onChange": this.handleChange,
                                        "value": this.props.username,
                                        "name": "username"
                                    }}
                                    labelText="Username"
                                />
                            </form>
                        </GridItem>
                        <GridItem md={6}>
                            <Button
                                color="primary"
                                onClick={() => this.props.handleResetPassword()}
                                style={{"marginTop": "0.7rem"}}
                            >
                                Reset Password
                            </Button>
                        </GridItem>
                        {/* <GridItem md={6}>
                            <div style={{"display": "flex", "width": "100%"}}>
                            {
                                this.props.action === "update_client"
                                ? ""
                                : <div style={{"flexGrow": "100"}}><CustomInput
                                    error={this.props.password_valid === "error"}
                                    formControlProps={{"fullWidth": true}}
                                    helperText={
                                        this.props.password_valid === "error" && this.props.password
                                            ? validations.passwordErrorMsg()
                                            : undefined
                                    }
                                    inputProps={{
                                        "disabled": this.props.action === "update_client",
                                        "onChange": this.handleChange,
                                        "value": this.props.password,
                                        "type": this.state.show_password ? "text" : "password",
                                        "autoComplete": "off",
                                        "name": "password"
                                    }}
                                    labelText="Password"
                                    /></div>
                            }
                            {
                                this.props.action === "update_client"
                                ? ""
                                : <div><IconButton
                                        title="Generate random password"
                                        aria-label="Close"
                                        className={classes.iconButton}
                                        color="inherit"
                                        key="close"
                                        onClick={this.handleGeneratePassword}
                                    >
                                        <span className="fa-stack" style={{"width": "1rem"}}>
                                            <i className="fas fa-unlock fa-stack-1x fa-flip-horizontal"></i>
                                            <i className="fas fa-undo fa-stack fa-inverse fa-flip-horizontal"
                                                style={{"fontSize": "0.6rem", "marginTop": "10px", "marginLeft": "-6px"}}></i>
                                        </span>
                                    </IconButton></div>
                            }
                            </div>
                        </GridItem> */}
                  </GridContainer> : ""
                  }
                  {/* <CustomInput
                      error={this.props.abn_valid === "error"}
                      formControlProps={{"fullWidth": true}}
                      inputProps={{
                          "onChange": this.handleChange,
                          "value": this.props.abn,
                          "name": "abn"
                      }}
                      labelText="ABN/ACN"
                  /> */}
                  <GridContainer
                    key="main_email_grid"
                    >
                        <GridItem md={6}>
                            <GridContainer key="main_manager_grid">
                                <GridItem md={12}>
                                    <CustomInput
                                        error={this.props.company_valid === "error"}
                                        formControlProps={{"fullWidth": true}}
                                        inputProps={{
                                            "onChange": this.handleChange,
                                            "value": this.props.company,
                                            "name": "company"
                                        }}
                                        labelText="Company"
                                        helperText={
                                            this.props.company_valid === "error"
                                                ? validations.businessNameErrorMsg()
                                                : undefined
                                        }
                                    />
                                </GridItem>
                                <GridItem md={12}>
                                    <FormControl
                                        className={classes.selectFormControl}
                                        fullWidth
                                        style={{
                                            "display": this.props.accessControlManager ? "null" : "none"
                                        }}
                                    >
                                        <InputLabel
                                            className={classes.selectLabel}
                                            error={this.props.managers_valid === "error"}
                                            htmlFor="client_managers"
                                        >
                                            Choose Managers
                                        </InputLabel>
                                        <Select
                                            classes={{"select": classes.select}}
                                            inputProps={{
                                                "name": "client_managers",
                                                "id": "client_managers"
                                            }}
                                            MenuProps={{"className": classes.selectMenu}}
                                            multiple
                                            onChange={this.handleMultiple}
                                            value={this.props.client_managers}
                                            open={this.state.managers_open}
                                            onOpen={() => this.setState({managers_open: true})}
                                            onClose={() => this.setState({managers_open: false})}
                                        >
                                            <MenuItem
                                                classes={{"root": classes.selectMenuItem}}
                                                disabled
                                            >
                                                Choose Managers
                                            </MenuItem>
                                            <div
                                                classes={{"root": classes.selectMenuItem}}
                                                value="filter"
                                                style={{"padding": "0px 10px", "position": "absolute"}}
                                            >
                                                <Button
                                                    className="edit"
                                                    color="info"
                                                    onClick={() => {
                                                        this.setState({managers_open: false});
                                                    }}
                                                    round
                                                    style={{"position": "fixed", "zIndex": "10"}}
                                                >
                                                    Close
                                                </Button>
                                            </div>
                                            <MenuItem
                                                classes={{"root": classes.selectMenuItem}}
                                                disabled
                                                style={{"height": "3rem"}}
                                            >
                                                {""}
                                            </MenuItem>
                                            {this.props.managers
                                                ? this.props.managers.map((prop, key) => <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                        key={key}
                                                        value={prop.id}
                                                    >
                                                        {`${prop.first_name  } ${prop.last_name}`}
                                                    </MenuItem>)
                                                : ""}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem md={12}>
                                    <FormControl
                                        className={classes.selectFormControl}
                                        fullWidth
                                        style={{
                                            "display": this.props.accessControlManager ? "null" : "none"
                                        }}
                                    >
                                        <InputLabel
                                            className={classes.selectLabel}
                                            htmlFor="manager_team"
                                            error={this.props.client_manager_team_valid === "error"}
                                        >
                                            Choose a Manager Team
                                        </InputLabel>
                                        <Select
                                            classes={{"select": classes.select}}
                                            inputProps={{
                                                "name": "client_manager_team",
                                                "id": "client_manager_team"
                                            }}
                                            MenuProps={{"className": classes.selectMenu}}
                                            onChange={this.handleSelectManagerTeam}
                                            value={this.props.client_manager_team}
                                        >
                                            <MenuItem
                                                classes={{"root": classes.selectMenuItem}}
                                                disabled
                                            >
                                                Choose a team
                                            </MenuItem>
                                            <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                                value=""
                                            >
                                                {"No Assignment"}
                                            </MenuItem>
                                            {this.props.manager_teams
                                                ? this.props.manager_teams.map((prop, key) => <MenuItem
                                                        classes={{
                                                            root: classes.selectMenuItem,
                                                            selected: classes.selectMenuItemSelectedMultiple
                                                        }}
                                                        key={key}
                                                        value={prop.id}
                                                    >
                                                        {prop.name}
                                                    </MenuItem>)
                                                : ""}
                                        </Select>
                                    </FormControl>
                                </GridItem>
                            </GridContainer>
                            {
                            this.props.client_manager_team_valid === "error"
                                ? <Danger>
                                    <div style={{
                                            "paddingBottom": "1rem",
                                            // "textAlign": "center",
                                            color: "#f44336",
                                            fontSize: "0.75rem",
                                            marginRop: "3px",
                                            textAlign: "left",
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        }}>
                                        Please select at least one Manager OR a Manager Team
                                    </div>
                                </Danger>
                                : ""
                            }
                        </GridItem>
                        <GridItem md={6}>
                            <FormControl
                                className={classes.selectFormControl}
                                fullWidth
                            >
                                <span style={{"paddingTop": "5px", color: this.props.transaction_methods_valid === "error" ? "red" : "inherit"}}>
                                    Transaction Methods
                                    <Button
                                        className={classes.modalSmallFooterFirstButton}
                                        color="transparent"
                                        onClick={() => {
                                            let selections = [];
                                            this.props.transaction_methods.forEach((element, key) => {
                                                selections.push(element.id);
                                            });
                                            this.props.handleTransactionMethods(selections);
                                        }}
                                        style={{padding: "0px", margin: "0 15px"}}
                                    >
                                        &lt;Select All&gt;
                                    </Button>
                                </span>
                                {
                                    this.props.transaction_methods
                                    ? this.props.transaction_methods.map((prop, key) => {
                                        return <FormControlLabel
                                            key={key}
                                            label={<span style={{ fontSize: '14px' }}>{prop.method}</span>}
                                            style={{"padding": "0px 10px"}}
                                            control={
                                                <Checkbox
                                                    style={{"padding": "5px 10px"}}
                                                    checked={this.props.client_transaction_methods.includes(prop.id)}
                                                    checkedIcon={<Check className={classes.checkedIcon} />}
                                                    classes={{
                                                        "checked": classes.checked,
                                                        "root": classes.checkRoot
                                                    }}
                                                    icon={<Check className={classes.uncheckedIcon} />}
                                                    onClick={(event) => {
                                                        let selections = this.props.client_transaction_methods;
                                                        const index = selections.indexOf(prop.id);
                                                        if (event.target.checked) {
                                                            if (index === -1) {
                                                                selections.push(prop.id);
                                                            }
                                                        } else {
                                                            if (index > -1) {
                                                                selections.splice(index, 1);
                                                            }
                                                        }
                                                        this.props.handleTransactionMethods(selections);
                                                    }}
                                                    tabIndex={-1}
                                                />
                                            }
                                        />
                                    })
                                    : null
                                }
                                {
                                    this.props.transaction_methods_valid === "error"
                                        ? React.createElement("p", {style: {
                                            color: "#f44336",
                                            fontSize: "0.75rem",
                                            marginRop: "3px",
                                            textAlign: "left",
                                            fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                        }}, 'Please select at least one transaction method')
                                        : null
                                }
                            </FormControl>
                        </GridItem>
                  </GridContainer>
                  <GridContainer
                    key="main_lead_grid"
                    >
                        <GridItem md={6}>
                        <FormControl
                            className={classes.selectFormControl}
                            fullWidth
                        >
                            <InputLabel
                                className={classes.selectLabel}
                                htmlFor="lead"
                            >
                                Choose a Lead
                            </InputLabel>
                            <Select
                                classes={{"select": classes.select}}
                                inputProps={{
                                    "name": "lead_operator_id",
                                    "id": "lead_operator_id"
                                }}
                                MenuProps={{"className": classes.selectMenu}}
                                onChange={this.handleSelectLead}
                                value={this.props.lead_operator_id}
                            >
                                <MenuItem
                                    classes={{"root": classes.selectMenuItem}}
                                    disabled
                                >
                                    Choose a lead
                                </MenuItem>
                                <MenuItem
                                    classes={{
                                        root: classes.selectMenuItem,
                                        selected: classes.selectMenuItemSelectedMultiple
                                    }}
                                    value=""
                                >
                                    {"No Assignment"}
                                </MenuItem>
                                {this.props.leads
                                    ? this.props.leads.map((prop, key) => <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelectedMultiple
                                            }}
                                            key={key}
                                            value={prop.id}
                                        >
                                            {prop.name + ' (' + prop.source + ')'}
                                        </MenuItem>)
                                    : ""}
                            </Select>
                        </FormControl>
                        </GridItem>
                        <GridItem md={6}>
                        <FormControl
                            className={classes.selectFormControl}
                            fullWidth
                        >
                            <InputLabel
                                className={classes.selectLabel}
                                htmlFor="lead"
                            >
                                Choose a Merchant Type
                            </InputLabel>
                            <Select
                                classes={{"select": classes.select}}
                                inputProps={{
                                    "name": "merchant_type_id",
                                    "id": "merchant_type_id"
                                }}
                                MenuProps={{"className": classes.selectMenu}}
                                onChange={this.handleSelectMerchantType}
                                value={this.props.merchant_type_id}
                            >
                                <MenuItem
                                    classes={{"root": classes.selectMenuItem}}
                                    disabled
                                >
                                    Choose a merchant type
                                </MenuItem>
                                {this.props.merchant_types
                                    ? this.props.merchant_types.map((prop, key) => <MenuItem
                                            classes={{
                                                root: classes.selectMenuItem,
                                                selected: classes.selectMenuItemSelectedMultiple
                                            }}
                                            key={key}
                                            value={prop.id}
                                        >
                                            {prop.name}
                                        </MenuItem>)
                                    : ""}
                            </Select>
                        </FormControl>
                        </GridItem>
                  </GridContainer>
                  <GridContainer
                    key="main_referrer_grid"
                    >
                        <GridItem md={12}>
                            <CustomInput
                                error={this.props.referrer_valid === "error"}
                                formControlProps={{"fullWidth": true}}
                                inputProps={{
                                    "onChange": this.handleChange,
                                    "value": this.props.referrer,
                                    "name": "referrer"
                                }}
                                labelText="Referrer"
                            />
                        </GridItem>
                  </GridContainer>
                  <GridContainer
                    key="main_mcc_grid"
                    >
                        <GridItem md={12}>
                            <FormControl
                                className={classes.selectFormControl}
                                fullWidth
                            >
                                <InputLabel
                                    className={classes.selectLabel}
                                    htmlFor="lead"
                                >
                                    Choose an MCC
                                </InputLabel>
                                <Select
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "mcc_code",
                                        "id": "mcc_code"
                                    }}
                                    MenuProps={{
                                        autoFocus: false,
                                        "className": classes.selectMenu,
                                        anchorOrigin: {
                                            vertical: "bottom",
                                            horizontal: "left"
                                        },
                                        getContentAnchorEl: null
                                    }}
                                    onChange={this.handleSelectMCCCode}
                                    onClose={() => {this.setState({"filter": "", mccs_open: false})}}
                                    onOpen={() => {this.setState({"filter": "", mccs_open: true})}}
                                    multiple
                                    value={[this.props.mcc_code]}
                                    title={mcc_details === undefined ? '' : this.setMccTooltip(mcc_details)}
                                    open={this.state.mccs_open}
                                >
                                    <ListSubheader>
                                        <input type="text"
                                            // value={this.state.filter}
                                            style={{"width": "100%", "padding": "10px 5px"}}
                                            placeholder={"Search MCC"}
                                            onKeyDown={(e) => {
                                                if (e.key !== "Escape") {e.stopPropagation();}
                                            }}
                                            onChange={(event) => {this.setState({"filter": event.target.value})}} />
                                    </ListSubheader>
                                    {/* <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        disabled
                                    >
                                        Choose an MCC
                                    </MenuItem> */}
                                    {/* <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        value="filter"
                                    >
                                        <input type="text"
                                            // value={this.state.filter}
                                            style={{"width": "100%", "padding": "10px 5px"}}
                                            placeholder={"Search MCC"}
                                            onChange={(event) => {this.setState({"filter": event.target.value})}} />
                                    </MenuItem> */}
                                    {this.props.mccs
                                        ? this.filterMCCs().map((prop, key) => <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                                key={key}
                                                value={prop.code}
                                                title={this.setMccTooltip(prop)}
                                            >
                                                {'(' + prop.code + ') ' + prop.title.slice(0, 80)}
                                            </MenuItem>)
                                        : ""}
                                </Select>
                            </FormControl>
                            <Warning>
                                <div style={{"paddingBottom": "1rem"}}>
                                    Notice: These details are not used for application generation
                                </div>
                            </Warning>
                        </GridItem>
                  </GridContainer>
                  
                    <GridContainer
                        key="main_contact_label_grid"
                        style={{"borderTop": "#9c27b0 solid 1px"}}
                        >
                        <GridItem md={12} style={{"marginTop": "10px", "marginBottom": "10px"}}>
                            Primary Contact
                        </GridItem>
                    </GridContainer>
                    {this.props.action === "update_client"
                        ? <FormControl
                                disabled={this.state.is_view}
                                fullWidth
                                className={classes.selectFormControl}
                            >
                                <Select
                                    classes={{"select": classes.select}}
                                    inputProps={{
                                        "name": "primary_contact_id",
                                        "id": "primary_contact_id"
                                    }}
                                    MenuProps={{"className": classes.selectMenu}}
                                    onChange={this.handleSelectPrimaryContact}
                                    value={this.props.primary_contact_id ? this.props.primary_contact_id : ""}
                                >
                                    <MenuItem
                                        classes={{"root": classes.selectMenuItem}}
                                        disabled
                                    >
                                        Choose a contact
                                    </MenuItem>
                                    {this.props.contacts
                                        ? this.props.contacts.map((prop, key) => <MenuItem
                                                classes={{
                                                    root: classes.selectMenuItem,
                                                    selected: classes.selectMenuItemSelectedMultiple
                                                }}
                                                key={key}
                                                value={prop.id}
                                            >
                                                {prop.first_name + ' ' + prop.last_name + (prop.company ? ' (' + prop.company + ')' : '')}
                                            </MenuItem>)
                                        : ""}
                                </Select>
                            </FormControl>
                        : ""
                    }
                    {/* {this.props.action === "update_client"
                        ? ""
                        :  */}
                        <div
                            key="main_contact_grid"
                            >
                                {this.createDetailsUI()}
                                {/* {this.createEmailsUI()}
                                {this.createPhonesUI()}
                                {this.createAddressesUI()} */}
                                {/* {this.createNotesUI()} */}
                                {/* {this.createWebsitesUI()}
                                {this.createDocumentsUI()} */}
                        </div>

                        <CustomTabs
                            key="main_contact_details_grid"
                            changeTabCallBack={(tab) => {this.setState({"current_tab": tab})}}
                            headerColor="warning"
                            tabs={
                                [
                                    {
                                        "tabName": "Emails",
                                        "tabContent": this.createEmailsUI(),
                                    },
                                    {
                                        "tabName": "Phones",
                                        "tabContent": this.createPhonesUI(),
                                    },
                                    {
                                        "tabName": "Addresses",
                                        "tabContent": this.createAddressesUI(),
                                    },
                                    {
                                        "tabName": "Websites",
                                        "tabContent": this.createWebsitesUI(),
                                    },
                                    {
                                        "tabName": "Documents",
                                        "tabContent": this.createDocumentsUI()
                                    }
                                ]
                            }
                            // title={"Contact Details"}
                            value={this.state.current_tab}
                        />
                    {/* } */}
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                  <Button
                      className={classes.modalSmallFooterFirstButton}
                      color="transparent"
                      onClick={() => this.props.onClose("clientDetailsModal")}
                  >
                      Cancel
                  </Button>
                  <Button
                      className={
                          `${classes.modalSmallFooterFirstButton
                          } ${
                              classes.modalSmallFooterSecondButton}`
                      }
                      color="success"
                      disabled={this.props.btnDisabled}
                      onClick={() => this.props.onYesClick(
                        {
                            "id": this.props.primary_contact_id ? this.props.primary_contact_id : "",
                            "first_name": this.state.details.first_name.value,
                            "last_name": this.state.details.last_name.value,
                            "company": this.state.details.company.value,
                            "position": this.state.details.position.value,
                            "tags": JSON.stringify(this.state.details.tags.value),
                            "emails": this.state.emails
                              .map((prop, key) => ({
                                    "id": prop.id,
                                    "default": prop.default ? 1 : 0,
                                    "email_address": prop.value
                                }))
                                .filter((email) => this.getLength(email.email_address) > 0),
                            "phones": this.state.phones
                              .map((prop, key) => ({
                                    "id": prop.id,
                                    "default": prop.default ? 1 : 0,
                                    "service": prop.service,
                                    "geo_country_id": prop.geo_country_id,
                                    "phone_number": prop.value
                                }))
                                .filter((phone) => this.getLength(phone.service) > 0 &&
                                  this.getLength(phone.geo_country_id) > 0 &&
                                  this.getLength(phone.phone_number) > 0),
                            "addresses": this.state.addresses
                              .map((prop, key) => ({
                                    "id": prop.id,
                                    "default": prop.default ? 1 : 0,
                                    "address": prop.address,
                                    "city": prop.city,
                                    "state": prop.state,
                                    "geo_country_id": prop.geo_country_id,
                                    "postcode": prop.postcode
                                }))
                                .filter((address) => this.getLength(address.address) > 0 &&
                                  this.getLength(address.city) > 0 &&
                                  this.getLength(address.state) > 0 &&
                                  this.getLength(address.geo_country_id) > 0 &&
                                  this.getLength(address.postcode) > 0),
                            "websites": this.state.websites
                              .map((prop, key) => ({"id": prop.id,
                                    "website": prop.url}))
                                  .filter((website) => this.getLength(website.website) > 0),
                            // "notes": this.state.notes
                            //   .map((prop, key) => ({
                            //         "id": prop.id,
                            //         "author": prop.author,
                            //         "subject": prop.subject,
                            //         "note": prop.note
                            //     }))
                            //     .filter((note) => this.getLength(note.note) > 0),
                            "documents": this.state.documents
                              .map((prop, key) => ({"id": prop.id,
                                    "filename": prop.filename,
                                    "tags": prop.tags}))
                                  .filter((document) => document.id !== null)
                        },
                        this.state.documents.map((prop, key) => {
                                const old_tag = prop.tags ? JSON.parse(JSON.stringify(prop.tags)) : [];
                                old_tag.sort();
                                const new_tag = prop.tags_tmp
                                    ? JSON.parse(JSON.stringify(prop.tags_tmp))
                                    : [];
                                new_tag.sort();
                                return {
                                    "id": prop.id,
                                    "file": prop.file === null ? "" : prop.file,
                                    "tags": old_tag === new_tag ? "" : prop.tags_tmp
                                };
                            })
                            .filter((document) => document.file !== "" ||
                            (document.tags !== "" && document.tags !== undefined))
                      )}
                      simple
                  >
                      Save
                  </Button>
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(ClientDetailsDialog);
