import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class ConfirmWithInputDialog extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            "message": props.message
        };
    }

    componentWillReceiveProps(props) {
        if (props.message) {
            this.setState({'message': props.message});
        }
    }

    render () {

        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="small-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                maxWidth={this.props.minWidth ? "lg" : "sm"}
                onClose={() => this.props.onClose("confirmWithInputModal")}
                open={this.props.confirmWithInputModal}
                style={{"zIndex": 9999}}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.confirmModalTitle
                        ? this.props.confirmModalTitle
                        : "Confirm"}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="small-modal-slide-description"
                    style={{overflowX: "hidden"}}
                >
                    <h5 style={{"minWidth": this.props.minWidth ? this.props.minWidth : "200px",
                        "marginBottom": "1rem"}}
                    >
                        {this.props.confirmModalMsg
                            ? this.props.confirmModalMsg
                            : "Are you sure?"}
                    </h5>
                    <InputLabel
                        style={{ paddingTop : "1.5rem" }}
                        className={classes.selectLabel}
                        htmlFor="message"
                    >
                        {this.props.hasOwnProperty('messageLabel') ? this.props.messageLabel : 'Message'}
                    </InputLabel>
                    <TextField
                        style={{overflowX: "hidden"}}
                        inputProps={{
                            name: `message`,
                            id: `message`
                        }}
                        type="text"
                        className="w-100"
                        value={this.state.message ? this.state.message : ""}
                        multiline={true}
                        rows={this.props.rows ? this.props.rows : 3}
                        onChange={(event) => {
                            this.setState({"message": event.target.value});
                        }}
                    />
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("confirmWithInputModal")}
                    >
                        {this.props.noLbl ? this.props.noLbl : "No"}
                    </Button>
                    <Button
                        className={
                            `${classes.modalSmallFooterFirstButton
                            } ${
                                classes.modalSmallFooterSecondButton}`
                        }
                        color="success"
                        onClick={
                            () => {
                                if ((!this.state.message.length || this.state.message.length === 0) && this.props.hasOwnProperty('messageOptional') && this.props.messageOptional !== true) {
                                    this.props.setUsersNotification((this.props.hasOwnProperty('messageLabel') ? this.props.messageLabel : 'Message') + " is required.", "danger");
                                } else {
                                    this.props.onYesClick(this.state.message);
                                }
                            }
                        }
                        simple
                    >
                        {this.props.yesLbl ? this.props.yesLbl : "Yes"}
                    </Button>
                </DialogActions>
            </Dialog>
        );

    }

}

export default withStyles(userProfileStyles)(ConfirmWithInputDialog);
