import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

import Button from "components/CustomButtons/Button.jsx";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

class FlagApplicationPageDialog extends React.Component {

    constructor (props) {

        super(props);

        this.state = {
            "message": props.flag_message,
            "is_flagged": props.is_flagged,
            "flag_page": props.flag_page
        };
    }

    handleFlagPage(event, action) {
        this.props.handleFlagPage(this.props.selected_application_id, this.state.message, this.props.flag_page, action);
    }

    componentWillReceiveProps(props) {
        if (props.flagPage === false) {
            this.setState({"message" : "", "is_flagged": false, "flag_page": ""});
        } else if (this.state.flag_page !== props.flag_page) {
            this.setState({"message" : props.flag_message, "is_flagged": props.is_flagged, "flag_page": props.flag_page});
        }
    }

  render () {
      const {classes} = this.props;
      return (
          <Dialog
              aria-describedby="update-profile-confirm-modal-slide-description"
              aria-labelledby="small-modal-slide-title"
              classes={{
                  "root": `${classes.center} ${classes.modalRoot}`,
                  "paper": `${classes.modal} ${classes.modalSmall}`
              }}
              fullWidth
              keepMounted
              onClose={() => {this.setState({"additional_fields_values": [], "message": ""}); this.props.onClose("flagPage");}}
              open={this.props.flagPage}
          >
              <DialogTitle
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  style={{"paddingBottom": "0px"}}
              >
                  Flag Page
              </DialogTitle>
              <DialogContent
                  className={`${classes.modalBody} ${classes.modalSmallBody}`}
                  id="update-profile-confirm-modal-slide-description"
              >
                <InputLabel
                    style={{ paddingTop : "1.5rem" }}
                    className={classes.selectLabel}
                    htmlFor="message"
                >
                    Reason for Flagging
                </InputLabel>
                <TextField
                    inputProps={{
                        name: `message`,
                        id: `message`
                    }}
                    type="text"
                    className="w-100"
                    value={this.state.message ? this.state.message : ""}
                    multiline={true}
                    rows={3}
                    onChange={(event) => this.setState({"message": event.target.value})}
                />
              </DialogContent>
              <DialogActions
                  className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
              >
                <Button
                    className={classes.modalSmallFooterFirstButton}
                    color="transparent"
                    onClick={() => {this.setState({"additional_fields_values": [], "message": ""}); this.props.onClose("flagPage");}}
                >
                    Close
                </Button>
                <Button
                    className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                    color="success"
                    disabled={this.state.btnDisabled}
                    onClick={(event) => this.handleFlagPage(event, 1)}
                    simple
                    >
                    {this.state.is_flagged ? "Update Flag" : "Flag"}
                </Button>
                {
                    this.state.is_flagged
                    ? <Button
                        className={`${classes.modalSmallFooterFirstButton} ${classes.modalSmallFooterSecondButton}`}
                        color="warning"
                        disabled={this.state.btnDisabled}
                        onClick={(event) => this.handleFlagPage(event, 0)}
                        simple
                        >
                        Remove Flag
                    </Button>
                    : ""
                }
              </DialogActions>
          </Dialog>
      );
  }
}

export default withStyles(extendedFormsStyle)(FlagApplicationPageDialog);
