import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import helpers from "customs/helpers/helpers";
import axios from "../../../axios/client-axios";

import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx";

// Core components
import Button from "components/CustomButtons/Button.jsx";

class PreviewDocument extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            content: "",
            clientArea: this.props.clientArea !== undefined && this.props.clientArea === true
        };
    }

    fetchContent() {
        helpers.showLoading();
        let url = this.state.clientArea
            ? 'client/information_registry/file/' + this.props.value + '/download'
            : 'client/' + this.props.client_id + '/file/' + this.props.value + '/download';
        axios(this.props)
          .get(
                url,
                {
                    "responseType": "blob",
                    "timeout": 30000
                }
            )
            .then((response) => {
                var urlCreator = window.URL || window.webkitURL;
                var blob;
                var file;
                if (["png", "bmp", "jpg", "jpeg"].includes(this.props.name.split('.').pop().toLowerCase())) {
                    var image = urlCreator.createObjectURL(response.data);
                    blob = new Blob(['<img width="100%" src="' + image + '" style="margin: 0;">'], {type: "text/html"});
                    file = urlCreator.createObjectURL(blob);
                } else {
                    file = urlCreator.createObjectURL(response.data);
                }
                // document.querySelector("iframe").src = file;
                document.getElementById('preview_msg_attach').src = file;
                helpers.hideLoading();
            })
            .catch((error) => {
                helpers.hideLoading();
            });
    }

    componentDidMount () {
        this.fetchContent();
    }

    render () {
        const {classes} = this.props;
        return (
            <Dialog
                aria-describedby="update-profile-confirm-modal-slide-description"
                aria-labelledby="small-modal-slide-title"
                classes={{
                    "root": `${classes.center} ${classes.modalRoot}`,
                    "paper": `${classes.modal} ${classes.modalSmall}`
                }}
                keepMounted
                onClose={() => this.props.onClose("previewDocumentModal")}
                open={this.props.previewDocumentModal}
                fullWidth
                maxWidth="lg"
                style={{"minHeight": "80vh"}}
            >
                <DialogTitle
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    style={{"paddingBottom": "0px"}}
                >
                    {this.props.name}
                </DialogTitle>
                <DialogContent
                    className={`${classes.modalBody} ${classes.modalSmallBody}`}
                    id="update-profile-confirm-modal-slide-description"
                >
                    <div style={{"border": "lightgrey 1px solid"}}>
                        <iframe title="preview_msg_attach" id="preview_msg_attach" name="preview_msg_attach" src="" style={{"border": "none", "width": "100%", "height": "78vh"}}>
                        </iframe>
                    </div>
                </DialogContent>
                <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                >
                    <Button
                        className={classes.modalSmallFooterFirstButton}
                        color="transparent"
                        onClick={() => this.props.onClose("previewDocumentModal")}
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withStyles(extendedFormsStyle)(PreviewDocument);
