const AccessControl = {
    "checkRoutePermissions" (path, auth, action = "view") {
        const userPermissions = auth.user && auth.user.permissions ? auth.user.permissions : [];
        if (userPermissions.length === 0) {
            return true;
        }
        try {
            if (userPermissions[path]) {
                return userPermissions[path][action]
                    ? userPermissions[path][action]
                    : false;
            }
        } catch (error) {}
        return true;
    },

    "getFirstActiveRoute" (auth) {
        const userPermissions = auth.user && auth.user.permissions ? auth.user.permissions : [];
        let path = '';
        try {
            let permission_keys = Object.keys(userPermissions);
            for (let i=0; i<permission_keys.length; i++) {
                let permission = permission_keys[i];
                if (userPermissions[permission].hasOwnProperty('view') && userPermissions[permission].view === true) {
                    path = permission;
                    break;
                }
            }
        } catch (error) {}
        return path;
    }
};

export default AccessControl;
