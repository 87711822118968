import React from "react";
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";
import validations from "customs/helpers/validations";
import helpers from "customs/helpers/helpers";
import AuthService from "customs/auth/AuthService";
import AccessControl from "customs/components/AccessControl";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Person from "@material-ui/icons/PersonOutline";
// Import LockOutline from "@material-ui/icons/LockOutline";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import {connect} from "react-redux";
import {doLogin} from "../../store/actions/authActions";
import routes from "routes.js";
import { withRouter } from "react-router-dom";

class LoginPage extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "cardAnimation": "cardHidden",
            "username_valid": "",
            "password_valid": ""
        };
        this.handleChange = this.handleChange.bind(this);

    }

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }

    componentDidMount () {
        this.timeOutFunction = setTimeout(
            () => {
                this.setState({"cardAnimation": ""});
            },
            700
        );

        document.title = "MAPS";
        
        try {
            clearTimeout(helpers.poll_timer);
            helpers.poll_timer = null;
        } catch(e) {}
    }

    componentWillUnmount () {
        clearTimeout(this.timeOutFunction);
        this.timeOutFunction = null;
    }

    handleLoginClick = (event) => {
        event.preventDefault();
        this.handleLogin();
    };

    handleLogin () {
      const is_valid_username = validations.validateUsername(this.state.username),
          is_valid_password = validations.validatePassword(this.state.password);
      if (is_valid_username && is_valid_password) {
          helpers.showLoading();
          this.props.doLogin(
              this.state.username,
              this.state.password,
              {
                  ...this.props
              }
          );
          this.setState({"username_valid": "",
              "password_valid": ""});
      } else {
          this.setState({
              "username_valid": is_valid_username ? "success" : "error",
              "password_valid": is_valid_password ? "success" : "error"
          });
      }
    }

    handleKeyDown = (event) => {
        if (event.key === "Enter") {
            this.handleLogin();
        }
    };

    handleSendResetEmailClick = () => {
        this.props.history.push("/auth/password/reset");
  };

  render () {
      if (new AuthService(
          this.state,
          this.props
      ).loggedIn()) {
          let route = routes.filter((route) => route.layout === "/admin" && this.props.prevPath === route.layout + route.path);
          let path = "/admin/dashboard";
          try {
            if (route.length > 0 && AccessControl.checkRoutePermissions(route.shift().path, this.props.auth, "view")) {
                path = this.props.prevPath;
            }
          } catch(e) {}
          return (<div><Redirect to={path + (this.props.prevParams.length > 0 ? '?d=' + this.props.prevParams : '')} /></div>);
      }

      const {classes} = this.props;
      return (
          <div className={classes.container}>
              <GridContainer justify="center">
                  <GridItem
                      md={4}
                      sm={6}
                      xs={12}
                  >
                      <form>
                          <Card
                              className={classes[this.state.cardAnimation]}
                              login
                          >
                              <CardHeader
                                  className={`${classes.cardHeader} ${classes.textCenter}`}
                                  color="success"
                              >
                                  <h4 className={classes.cardTitle}>
                                      Log in
                                  </h4>
                              </CardHeader>
                              {this.props.error_msg
                                  ? <CardBody style={{"paddingBottom": "0px"}}>
                                      <h4
                                          className={classes.textCenter}
                                          id="reset_password_status"
                                          style={{
                                              "color": "red",
                                              "marginBottom": "0px",
                                              "paddingBottom": "0px"
                                          }}
                                      >
                                          {this.props.error_msg}
                                      </h4>
                                    </CardBody>
                                  : ""}
                              <CardBody>
                                  <CustomInput
                                      error={this.state.username_valid === "error"}
                                      formControlProps={{
                                          "fullWidth": true
                                      }}
                                      helperText={
                                          this.state.username_valid === "error" && this.state.username
                                              ? "Invalid username format"
                                              : undefined
                                      }
                                      id="username"
                                      inputProps={{
                                          "endAdornment":
                                            <InputAdornment position="end">
                                                <Person className={classes.inputAdornmentIcon} />
                                            </InputAdornment>,
                                          "type": "username",
                                          "onChange": this.handleChange,
                                          "onKeyDown": this.handleKeyDown
                                      }}
                                      labelText="Username..."
                                      success={this.state.username_valid === "success"}
                                  />
                                  <CustomInput
                                      error={this.state.password_valid === "error"}
                                      formControlProps={{
                                          "fullWidth": true
                                      }}
                                      helperText={
                                          this.state.password_valid === "error" && this.state.password
                                              ? "Invalid password format"
                                              : undefined
                                      }
                                      id="password"
                                      inputProps={{
                                          "endAdornment":
                                            <InputAdornment position="end">
                                                <Icon className={classes.inputAdornmentIcon}>
                                                    lock_outline
                                                </Icon>
                                            </InputAdornment>,
                                          "type": "password",
                                          "autoComplete": "off",
                                          "onChange": this.handleChange,
                                          "onKeyDown": this.handleKeyDown
                                      }}
                                      labelText="Password"
                                      success={this.state.password_valid === "success"}
                                  />
                              </CardBody>
                              <CardFooter
                                  className={classes.justifyContentCenter}
                                  style={{"paddingTop": "0px"}}
                              >
                                  <Button
                                      block
                                      color="success"
                                      onClick={(event) => this.handleLoginClick(event)}
                                      simple
                                      size="lg"
                                  >
                                      Submit
                                  </Button>
                                  <Button
                                      block
                                      color="info"
                                      onClick={this.handleSendResetEmailClick}
                                      simple
                                      size="lg"
                                  >
                                      Forgot Password?
                                  </Button>
                              </CardFooter>
                          </Card>
                      </form>
                  </GridItem>
              </GridContainer>
          </div>
      );
  }
}

LoginPage.propTypes = {
    "classes": PropTypes.object.isRequired,
    "error_msg": PropTypes.string
};

const mapStateToProps = (state, props) => {

        helpers.hideLoading();
        return {
            "auth": state.auth.data,
            "error_msg":
      state.auth && state.auth.data && state.auth.data.authMessage
          ? state.auth.data.authMessage
          : ""
        };

    },

    mapDispatchToProps = (dispatch) => ({
        "doLogin": (username, password, props) => dispatch(doLogin(
            username,
            password,
            props
        ))
    });

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withStyles(loginPageStyle)(LoginPage)));
