import React from "react";

import withStyles from "@material-ui/core/styles/withStyles";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx";
import Add from "@material-ui/icons/Add";
import Edit from "@material-ui/icons/Edit";
import Assignment from "@material-ui/icons/Assignment";
import History from "@material-ui/icons/History";
import Close from "@material-ui/icons/Close";
import Table from "components/Table/Table.jsx";
import ContactDetailsWizard from "customs/components/Contacts/ContactDetailsWizard";
import ClientNote from "customs/components/ClientNote";
import ClientFile from "customs/components/ClientFile";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Refresh from "@material-ui/icons/Refresh";
import CardIcon from "components/Card/CardIcon";
import TagsInput from "react-tagsinput";
import FormSelectDialog from "customs/components/FormSelectDialog";
import TabbedChecklist from "customs/components/TabbedChecklist";

import helpers from "customs/helpers/helpers";
import axios from "axios/axios";
import axiosHelper from "axios/axiosHelper";

import extendedTablesStyle from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.jsx";
import FormOpenDialog from "customs/components/FormOpenDialog";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import SubmitClientApplication from "customs/components/SubmitClientApplication";
import SubmittedClientApplicationDownload from "customs/components/SubmittedClientApplicationDownload";
import Star from "@material-ui/icons/Star";
import AccessControl from "customs/components/AccessControl";
import SelectLibraryDocuments from "customs/components/SelectLibraryDocuments";
import Icon from '@material-ui/core/Icon';

class ClientFullDetailsDialog extends React.Component {

    constructor (props) {

        super(props);
        this.state = {
            "contact_details_wizard": false,
            "selected_contact": [],
            "is_view": false,
            "contact_details_title": "",
            // "contact_checklist_new_contact": false,
            // "contact_checklist_sales": false,
            // "contact_checklist_application": false,
            // "contact_checklist_data": [],
            "client_note_details": false,
            "note_dialog_title": "",
            "selected_note": [],
            "client_file_details": false,
            "file_dialog_title": "",
            "selected_file": [],
            "submitClientApplicationModal": false,
            "submitClientApplicationModalMsg": "",
            "submitClientApplicationTitle": "",
            "applicationToSubmit": "",
            "selected_form": this.props.selected_form,
            "downloadApplicationBundleModal": false,
            "downloadApplicationBundleModalMsg": false,
            "downloadApplicationBundleModalFooter": false,
            "selectLibraryDocumentsModal": false,
            "downloadApplicationBundleTitle": "",
            "downloadApplicationFileName": "",
            "downloadApplicationFileId": "",
            "isReadOnly": this.props.isReadOnly,
            "current_client_tab": this.props.current_client_tab,
            "selected_script": this.props.selected_script,
        };
        this.refreshClientContactsTable = this.refreshClientContactsTable.bind(this);
        this.handleAddContact = this.handleAddContact.bind(this);
        this.handleViewContact = this.handleViewContact.bind(this);
        this.handleEditContact = this.handleEditContact.bind(this);
        this.handleDeleteContact = this.handleDeleteContact.bind(this);
        this.handleDownloadContactDocument = this.handleDownloadContactDocument.bind(this);
        this.handleSaveContactDetails = this.handleSaveContactDetails.bind(this);
        this.handleSaveContactError = this.handleSaveContactError.bind(this);
        this.changeClientTabCallBack = this.changeClientTabCallBack.bind(this);
        // this.handleViewNewContactChecklist = this.handleViewNewContactChecklist.bind(this);
        this.handleAddNote = this.handleAddNote.bind(this);
        this.handleViewNote = this.handleViewNote.bind(this);
        this.handleEditNote = this.handleEditNote.bind(this);
        this.handleDeleteNote = this.handleDeleteNote.bind(this);
        this.handleAddFile = this.handleAddFile.bind(this);
        this.handleViewFile = this.handleViewFile.bind(this);
        this.handleDownloadFile = this.handleDownloadFile.bind(this);
        this.handleDownloadFileNoClientID = this.handleDownloadFileNoClientID.bind(this);
        this.handleDownloadVersionFile = this.handleDownloadVersionFile.bind(this);
        this.handleEditFile = this.handleEditFile.bind(this);
        this.handleDeleteFile = this.handleDeleteFile.bind(this);
        // this.postChecklist = this.postChecklist.bind(this);
        this.handleSubmitApplicationClick = this.handleSubmitApplicationClick.bind(this);
        this.handleGetClientForms = this.handleGetClientForms.bind(this);
        this.handleChangePrimaryContact = this.handleChangePrimaryContact.bind(this);
        this.handleRemoveLibraryDocument = this.handleRemoveLibraryDocument.bind(this);
        this.handleDownloadDocument = this.handleDownloadDocument.bind(this);
        this.handleAddDocuments = this.handleAddDocuments.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.computeApplicationProgress = this.computeApplicationProgress.bind(this);
    }

    // postChecklist (checklist, checklist_id) {
    //     const fd = checklist;
    //     fd.checklist_id = checklist_id;
    //     axios(this.props)
    //         .patch(
    //             `/client/${this.props.client_selected.id}/checklist/10`,
    //             fd,
    //             {
    //                 "headers": {"Content-Type": "application/x-www-form-urlencoded"}
    //             }
    //         )
    //         .then((response) => console.log(response));
    // }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

  refreshClientContactsTable = (event, client_id) => {
      this.props.refreshClientContactsTable(
          event,
          client_id
      );
  };

  handleDeleteClientManager = (event, client_id, id) => {
      this.props.handleDeleteClientManager(
          event,
          client_id,
          id
      );
  };

  handleAddClientManager = (event, id) => {
      this.props.handleAddClientManager(
          event,
          id
      );
  };

  handleAddContact = (event, client_id) => {
      this.setState({
          "contact_details_wizard": true,
          "selected_contact": [],
          "is_view": false,
          "contact_details_title": "New Contact"
      });
  };

  handleViewContact = (event, client_id, contact) => {
      this.setState({
          "contact_details_wizard": true,
          "selected_contact": contact,
          "is_view": true,
          "contact_details_title": "Contact Details"
      });
  };

  handleEditContact = (event, client_id, contact) => {
      this.setState({
          "contact_details_wizard": true,
          "selected_contact": contact,
          "is_view": false,
          "contact_details_title": "Edit Contact"
      });
  };

  handleDeleteContact = (event, client_id, contact_id, name) => {
      this.props.handleDeleteContact(
          event,
          client_id,
          contact_id,
          name
      );
  };

  handleChangePrimaryContact = (event, client_id, contact_id, name) => {
      this.props.handleChangePrimaryContact(
          event,
          client_id,
          contact_id,
          name
      );
  };

  handleDownloadContactDocument = (
      event,
      client_id,
      contact_id,
      document_id,
      file_name
  ) => {
      this.props.handleDownloadContactDocument(
          event,
          client_id,
          contact_id,
          document_id,
          file_name
      );
  };

  handleSaveContactDetails = (results, is_add, saveDetailsErrors) => {
      this.setState({"contact_details_wizard": false});
      this.props.handleSaveContactDetails(
          results,
          is_add,
          saveDetailsErrors
      );
  };

  handleAddNote = (event, client_id) => {
      this.setState({
          "client_note_details": true,
          "selected_note": [],
          "is_view": false,
          "note_dialog_title": "New Note"
      });
  };

  handleViewNote = (event, client_id, note) => {
      this.setState({
          "client_note_details": true,
          "selected_note": note,
          "is_view": true,
          "note_dialog_title": "Note Details"
      });
  };

  handleEditNote = (event, client_id, note) => {
      this.setState({
          "client_note_details": true,
          "selected_note": note,
          "is_view": false,
          "note_dialog_title": "Edit Note"
      });
  };

  handleDeleteNote = (event, client_id, note_id) => {
      this.props.handleDeleteNote(
          event,
          client_id,
          note_id
      );
  };

  handleAddFile = (event, client_id) => {
      this.setState({
          "client_file_details": true,
          "selected_file": [],
          "is_view": false,
          "file_dialog_title": "New File"
      });
  };

  handleViewFile = (event, client_id, file) => {
      this.setState({
          "client_file_details": true,
          "selected_file": file,
          "is_view": true,
          "file_dialog_title": "File Details"
      });
  };

  handleDownloadFileNoClientID(event, file) {
      this.handleDownloadFile(event, this.props.client_selected.id, file);
  }

  handleDownloadFile = (event, client_id, file) => {
      event.preventDefault();
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
              `client/${client_id}/file/${file.id}/download`,
              {
                  "responseType": "blob",
                  "timeout": 30000
              }
          )
          .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                file.filename
            );
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  };

  handleDownloadVersionFile = (event, client_id, file_id, version_file) => {
      event.preventDefault();
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
              `client/${
                  client_id
              }/file/${
                  file_id
              }/download/${
                  version_file.id}`,
              {"responseType": "blob",
                  "timeout": 30000}
          )
          .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                version_file.filename
            );
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  };

  handleEditFile = (event, client_id, file) => {
      this.setState({
          "client_file_details": true,
          "selected_file": file,
          "is_view": false,
          "file_dialog_title": "Edit File"
      });
  };

  handleDeleteFile = (event, client_id, file_id) => {
      this.props.handleDeleteFile(
          event,
          client_id,
          file_id
      );
  };

  handleRemoveLibraryDocument= (event, client_id, document_id) => {
      this.props.handleRemoveLibraryDocument(
          event,
          client_id,
          document_id
      );
  };

  handleDownloadDocument = (event, client_id, document) => {
      event.preventDefault();
      const FileDownload = require("js-file-download");
      helpers.showLoading();
      axios(this.props)
        .get(
              `client/${client_id}/document_library/${document.id}/download`,
              {
                  "responseType": "blob",
                  "timeout": 30000
              }
          )
          .then((response) => {
            helpers.hideLoading();
            FileDownload(
                response.data,
                document.filename
            );
          })
          .catch((error) => this.processErrorAxios(
              error,
              null
          ));
  };

  handleSaveContactError = (event) => {
      this.props.handleSaveContactError(event);
  };

  getEmail (emails) {
      let default_email = "";
      if (emails.length) {
          const email = emails.find((email) => email.default === 1);
          default_email = email === undefined ? emails[0].email_address : email.email_address;
      }
      return default_email;
  }

  getPhone (phones, key) {
      let default_phone = "";
      if (phones.length) {
          let phone = phones.find((phone) => phone.default === 1);
          if (phone === undefined) {
              phone = phones[0];
          }
          default_phone = [
              <i
                  className={
                      phone.service === "LANDLINE"
                          ? "fa fa-phone-alt"
                          : "fa fa-mobile-alt"
                  }
                  key={`service${key}`}
                  style={{"paddingRight": "0.25rem"}}
              />,
              `(${phone.country_code.calling_code}) ${phone.phone_number}`
          ];
      }
      return default_phone;
  }

  setContacts (client_id, contacts) {
      let res = [];
      res = contacts.map((prop, key) => [
          prop.id,
          prop.first_name,
          prop.last_name,
          prop.company ? prop.company : "",
          prop.position ? prop.position : "",
          this.getEmail(prop.emails),
          this.getPhone(
              prop.phones,
              key
          ),
          [
            <Button
                title={prop.id === this.props.client_selected.primary_contact_id ?
                        'Primary Contact'
                        : (this.props.client_selected.deleted_at === null ? 'Set as Primary Contact?' : '')}
                className="view"
                justIcon
                key={`btnS${key}`}
                onClick={(event) => {
                    if (this.props.client_selected.deleted_at === null) {
                        this.handleChangePrimaryContact(
                            event,
                            client_id,
                            prop.id,
                            `${prop.first_name} ${prop.last_name}`
                        );
                    }
                }}
                round
                simple
                style={{"color": prop.id === this.props.client_selected.primary_contact_id ? "#ffa500" : "#9e9e9e"}}
            >
                <Star />
            </Button>,
              <Button
                  title={'Contact details'}
                  className="view"
                  color="warning"
                  justIcon
                  key={`btnA${key}`}
                  onClick={(event) => this.handleViewContact(
                      event,
                      client_id,
                      prop
                  )}
                  round
                  simple
              >
                  <Assignment />
              </Button>,
              this.props.client_selected.deleted_at === null
                ? <Button
                    title={'Edit contact'}
                    className="edit"
                    color="info"
                    justIcon
                    key={`btnE${key}`}
                    onClick={(event) => this.handleEditContact(
                        event,
                        client_id,
                        prop
                    )}
                    round
                    simple
                >
                    <Edit />
                </Button> : "",
              this.props.client_selected.deleted_at === null
                ? <Button
                    title={'Delete contact'}
                    className="remove"
                    color="danger"
                    justIcon
                    key={`btnC${key}`}
                    onClick={(event) => this.handleDeleteContact(
                        event,
                        client_id,
                        prop.id,
                        `${prop.first_name} ${prop.last_name}`
                    )}
                    round
                    simple
                >
                    <Close />
                </Button>
                : ""
          ]
      ]);
      return res;
  }

  changeClientTabCallBack (tab) {
      this.props.changeClientTabCallBack(tab);
  }

  // New Contact
//   handleViewNewContactChecklist = (event, client_id, contact) => {
//       helpers.showLoading();
//       axios(this.props)
//         .get(`/client/${client_id}/checklist/${contact.id}`)
//         .then((response) => this.processNewContactChecklistData(
//               response,
//               contact
//           ))
//           .catch((error) => this.processErrorAxios(
//               error,
//               null
//           ));
//   };

//   processNewContactChecklistData (response, contact) {
//       if (this.isUnmounted) {
//           return;
//       }
//       helpers.hideLoading();
//       this.setState({
//           "contact_checklist_new_contact": true,
//           "selected_contact": contact,
//           "contact_checklist_data": response.data
//       });
//   }

  // Sales
//   handleViewSalesChecklist = (event, client_id, contact) => {
//       helpers.showLoading();
//       axios(this.props)
//         .get(`/client/${client_id}/checklist/${contact.id}`)
//         .then((response) => this.processSalesChecklistData(
//               response,
//               contact
//           ))
//           .catch((error) => this.processErrorAxios(
//               error,
//               null
//           ));
//   };

//   handleViewSalesChecklist = (client_id, contact) => {
//       helpers.showLoading();
//       axios(this.props)
//         .get(`/client/${client_id}/checklist/${contact.id}`)
//         .then((response) => this.processSalesChecklistData(
//               response,
//               contact
//           ))
//           .catch((error) => this.processErrorAxios(
//               error,
//               null
//           ));
//   };

//   processSalesChecklistData (response, contact) {
//       if (this.isUnmounted) {
//           return;
//       }
//       helpers.hideLoading();
//       this.setState({
//           "contact_checklist_sales": true,
//           "selected_contact": contact,
//           "contact_checklist_data": response.data
//       });
//   }

  // Sales
  componentDidUpdate (prevProps, prevState, snapshot) {
      if (this.props.client_selected && this.props.client_selected.contacts && !this.props.isReadOnly) {
          if (
            prevProps.client_selected === null ||
            prevProps.client_selected.id !== this.props.client_selected.id
          ) {
            this.refreshClientContactsTable(null, this.props.client_selected.id);
            this.handleGetClientForms();
          }
      }
  }

  handleGetClientForms() {
    this.setState({"selected_form": {
        "client_forms": [],
        "forms_schema": [],
        "acquirers": []
    }});
    this.props.getClientForms(this.props.client_selected.id, false, false);
  }

  // Application
//   handleViewApplicationChecklist = (event, client_id, contact) => {
//       helpers.showLoading();
//       axios(this.props)
//         .get(`/client/${client_id}/checklist/${contact.id}`)
//         .then((response) => this.processApplicationChecklistData(
//               response,
//               contact
//           ))
//           .catch((error) => this.processErrorAxios(
//               error,
//               null
//           ));
//   };

//   processApplicationChecklistData (response, contact) {
//       if (this.isUnmounted) {
//           return;
//       }
//       helpers.hideLoading();
//       this.setState({
//           "contact_checklist_application": true,
//           "selected_contact": contact,
//           "contact_checklist_data": response.data
//       });
//   }
  // Application

//   setChecklists (client_id, checklists) {
//       let res = [];
//       res = checklists.map((prop, key) => [
//           prop.id,
//           prop.checklist_name,
//           prop.completed_steps,
//           prop.progress,
//           [
//               <Button
//                   className="view"
//                   color="warning"
//                   justIcon
//                   key={`btnA${key}`}
//                   onClick={(event) => {
//                       switch (prop.checklist_key) {
//                         case "new_contact":
//                             this.handleViewNewContactChecklist(
//                                 event,
//                                 client_id,
//                                 prop
//                             );
//                             break;
//                         case "sales":
//                             this.handleViewSalesChecklist(
//                                 event,
//                                 client_id,
//                                 prop
//                             );
//                             break;
//                         case "application":
//                             this.handleViewApplicationChecklist(
//                                 event,
//                                 client_id,
//                                 prop
//                             );
//                             break;
//                         default:
//                       }
//                   }}
//                   round
//                   simple
//               >
//                   <Assignment />
//               </Button>
//           ]
//       ]);
//       return res;
//   }

  setNotes (client_id, notes) {
      let res = [];
      res = notes.map((prop, key) => [
          prop.id,
          prop.author ? prop.author : "",
          prop.subject ? prop.subject : "",
          prop.note ? prop.note : "",
          [
              <Button
                  title={'Note details'}
                  className="view"
                  color="warning"
                  justIcon
                  key={`btnA${key}`}
                  onClick={(event) => this.handleViewNote(
                      event,
                      client_id,
                      prop
                  )}
                  round
                  simple
              >
                  <Assignment />
              </Button>,
              (this.props.client_selected.deleted_at === null && AccessControl.checkRoutePermissions(
                    "/clients",
                    this.props.auth,
                    "update_notes"
                )
                ? <Button
                 title={'Edit note'}
                  className="edit"
                  color="info"
                  justIcon
                  key={`btnE${key}`}
                  onClick={(event) => this.handleEditNote(
                      event,
                      client_id,
                      prop
                  )}
                  round
                  simple
              >
                  <Edit />
              </Button>
              : ""),
              (this.props.client_selected.deleted_at === null && AccessControl.checkRoutePermissions(
                    "/clients",
                    this.props.auth,
                    "delete_notes"
                )
                ? <Button
                    title={'Delete note'}
                    className="remove"
                    color="danger"
                    justIcon
                    key={`btnC${key}`}
                    onClick={(event) => this.handleDeleteNote(
                        event,
                        client_id,
                        prop.id
                    )}
                    round
                    simple
                >
                    <Close />
                </Button>
                : "")
          ]
      ]);
      return res;
  }

  setFiles (client_id, files) {
      let res = [];
      res = files.map((prop, key) => [
          prop.id,
          prop.filename ? prop.filename : "",
          prop.tags != null && prop.tags.length > 0
              ? <TagsInput
                  disabled
                  onlyUnique
                  tagProps={{"className": "react-tagsinput-tag primary"}}
                  value={prop.tags}
              />
              : "",
          prop.uploader ? "(" + prop.uploader.type + ") " + prop.uploader.username : "",
          [
              this.state.isReadOnly ? "" :
                <Button
                    title={'File details'}
                    className="view"
                    color="warning"
                    justIcon
                    key={`btnA${key}`}
                    onClick={(event) => this.handleViewFile(
                        event,
                        client_id,
                        prop
                    )}
                    round
                    simple
                >
                    <History />
                </Button>,
              this.state.isReadOnly || this.props.client_selected.deleted_at !== null ? "" :
                    <Button
                    title={'Edit file'}
                    className="edit"
                    color="info"
                    justIcon
                    key={`btnE${key}`}
                    onClick={(event) => this.handleEditFile(
                        event,
                        client_id,
                        prop
                    )}
                    round
                    simple
                >
                    <Edit />
                </Button>,
              this.props.client_selected.deleted_at !== null ? "" : <Button
                  title={'Download file'}
                  className="view"
                  color="facebook"
                  justIcon
                  key={`btnF${key}`}
                  onClick={(event) => this.handleDownloadFile(
                      event,
                      client_id,
                      prop
                  )}
                  round
                  simple
              >
                  <i
                      className="fa fa-download fa-sm"
                      key={`i_document_${key}`}
                  />
              </Button>,
              this.state.isReadOnly || this.props.client_selected.deleted_at !== null ? "" :
                <Button
                    title={'Delete file'}
                    className="remove"
                    color="danger"
                    justIcon
                    key={`btnC${key}`}
                    onClick={(event) => this.handleDeleteFile(
                        event,
                        client_id,
                        prop.id
                    )}
                    round
                    simple
                >
                    <Close />
                </Button>
          ]
      ]);
      return res;
  }

  setWatched(watched, registry_key) {
    try {
        if (Object.keys(
            watched.filter((watch) => watch.event === 'entry' && watch.element.toLowerCase() === registry_key.toLowerCase() && watch.status === 1)
        ).length === 1) return true;
    } catch(e) {}
    return false;
}

  setInfos (client_id, registry_entries) {
      let res = [];
      res = registry_entries.filter((registry) => {
            return registry.field_template !== undefined && registry.field_template !== null;
        }).map((prop, key) => {
            let isWatched = this.setWatched(this.props.client_selected.watchedEvents, prop.registry_key);
            return [
                prop.field_template.configuration.properties.title ? prop.field_template.configuration.properties.title.replace(/^\*/, '') : "",
                prop.registry_key,
                this.setInfoValue(prop.field_template.configuration.properties, prop.value),
                <Icon className={"fas fa-eye" + (isWatched ? "" : "-slash eye-watcher-off") + " eye-watcher"}
                      onClick={(e) => {e.preventDefault(); this.props.handleUpdateWatch('entry', prop.registry_key, !isWatched);}} />
            ];
        });
      return res;
  }

  setLibrary (client_id, library_documents) {
    let res = [];
    res = library_documents.map((prop, key) => [
        prop.id,
        prop.filename ? prop.filename : "",
        prop.tags != null && prop.tags.length > 0
            ? <TagsInput
                disabled
                onlyUnique
                tagProps={{"className": "react-tagsinput-tag primary"}}
                value={prop.tags}
            />
            : "",
        [
            this.props.client_selected.deleted_at !== null ? "" : <Button
                title={'Download document'}
                className="view"
                color="facebook"
                justIcon
                key={`btnF${key}`}
                onClick={(event) => this.handleDownloadDocument(
                    event,
                    client_id,
                    prop
                )}
                round
                simple
            >
                <i
                    className="fa fa-download fa-sm"
                    key={`i_document_${key}`}
                />
            </Button>,
            this.state.isReadOnly || this.props.client_selected.deleted_at !== null ? "" :
              <Button
                  title={'Delete document'}
                  className="remove"
                  color="danger"
                  justIcon
                  key={`btnC${key}`}
                  onClick={(event) => this.handleRemoveLibraryDocument(
                      event,
                      client_id,
                      prop.id
                  )}
                  round
                  simple
              >
                  <Close />
              </Button>
        ]
    ]);
    return res;
  }

  getValueByType(value, type) {
    if (type === "number") {
        return Number(value);
    } else if (Object.prototype.toString.call(value) === '[object Array]') {
        return helpers.addLineBreaks(value);
    } else if (typeof value === "object") {
        let val = [];
        for(let key in value) {
            val.push(key + ': ' + value[key]);
        }
        return helpers.addLineBreaks(val);
    }
    return value;
  }

  setInfoValue(config, value) {
    if (config.hasOwnProperty('enum')) {
        if (value.hasOwnProperty(config.name)) {
            return this.getValueByType(value[config.name], config.type);
        }

        let val = config.enum.filter((val) => val === this.getValueByType(value, config.type));
        if (val.length > 0) {
            return val[0];
        }
    } else if (config.hasOwnProperty('field_type') && (config.field_type === 'file' || config.field_type === 'file_sig')) {
        try {
            return value.split(',').map((prop, key) => {
                let file = this.props.client_selected.files.filter((file) => file.id === Number(prop));
                return file.length > 0
                    ? <span key={key}>{'(id: ' + file[0].id + ') ' + file[0].filename}<br/></span>
                    : <span key={key} style={{"color": "red"}}><b>{"File id " + prop + " does not exist"}</b><br/></span>;
            });
        } catch(e) {}
    }
    return this.getValueByType(value, config.type);
  }

  processErrorAxios (error, prop) {
      axiosHelper.processError(
          this.isUnmounted,
          prop,
          error,
          (state) => {
              this.setState(state);
          },
          (msg, color) => {
              this.props.setUsersNotification(
                  msg,
                  color
              );
          }
      );
  }

  handleSubmitApplicationClick(formData, url) {
    helpers.showLoading();
    axios(this.props)
        .post(url, formData, {
            headers: {
              'content-type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data.status === "success") {
                this.setState({
                    "submitClientApplicationModal": false,
                    "submitClientApplicationModalMsg": "",
                    "submitClientApplicationTitle": "",
                    "applicationToSubmit": ""
                });
                let message = 'The application is being processed and will be submitted shortly.';
                if (response.data.hasOwnProperty('method') && response.data.method === 'manual' && response.data.hasOwnProperty('file')) {
                    message = 'Application bundle has been generated.';
                    let file_name = response.data.name ? response.data.name + ' ' : '';
                    this.setState({
                        "downloadApplicationBundleModal": true,
                        "downloadApplicationBundleModalMsg": "Application bundle has been generated.<br>" +
                            "Please click the download button to download the generated zip file.",
                        "downloadApplicationBundleModalFooter": "You can also access and download the " + file_name + "file in the files section.",
                        "downloadApplicationBundleTitle": "Download Application Bundle (Zip)",
                        "downloadApplicationFileName": file_name.trim(),
                        "downloadApplicationFileId": response.data.file,
                    });
                }

                this.props.setUsersNotification(
                    message,
                    "success"
                );
                this.refreshClientContactsTable(
                    null,
                    this.props.client_selected.id
                )
            }
            helpers.hideLoading();
        })
        .catch((error) => {
            this.processErrorAxios(error, null);
        });
  }

  componentWillReceiveProps(props) {
    if (props.selected_form) {
        this.setState({"selected_form" : props.selected_form});
    }
    if (props.current_client_tab !== this.state.current_client_tab) {
        this.setState({"current_client_tab" : props.current_client_tab});
    }
    if (props.selected_script !== this.state.selected_script) {
        this.setState({"selected_script" : props.selected_script});
    }
  }

  computeApplicationProgress(filled, total) {
    if (total === 0) return 'N/A';
    let progress = (filled / total) * 100;
    if (progress < 0) progress = 0;
    if (progress > 100) progress = 100;

    return isNaN(progress) ? 'N/A' : progress.toFixed(2).replace(".00", "") + '%'
  }

  detailsTab(client_selected, manager) {
      let primary_contact = !this.state.isReadOnly ? client_selected.contacts.find((contact) => contact.id === client_selected.primary_contact_id) : null;
      let primary_contact_email = !this.state.isReadOnly && primary_contact && primary_contact.emails ? primary_contact.emails.find((email) => email.default === 1) : null;
      return {
        "tabName": "Details",
        "tabContent":
      <GridContainer style={{"minHeight": "250px"}}>
          <GridItem
              md={8}
              xs={12}
          >
              <span>
                {/* <b>ABN/ACN: </b>{`${client_selected ? client_selected.abn : ""}`}
                <br /> */}
                <b>Company: </b>{`${client_selected ? client_selected.company : ""}`}
                <br />
                {manager ? <b>Managing Team: </b> : ""}
                {manager ? `${manager.name}` : ""}
                <br />
                {this.state.isReadOnly || primary_contact === undefined ? "" :
                    <div>
                        <br />
                        <b>Primary Contact</b>
                        <br />
                        {primary_contact.first_name + ' ' + primary_contact.last_name}
                        {primary_contact.company ? ' (' + primary_contact.company + ')' : ''}
                        {primary_contact_email ? <br /> : ''}
                        {primary_contact_email ? primary_contact_email.email_address : ''}
                    </div>
                }
                {this.state.isReadOnly || (client_selected.hasOwnProperty('scripts') && Object.keys(client_selected.scripts).length === 0) ? "" : 
                    <div style={{"paddingBottom": "1rem"}}>
                    <br />
                    <b>Scripts</b>
                    <br />
                    <TabbedChecklist
                            basic={true}
                            value={0}
                            checklist={client_selected.scripts}
                            handleUpdateStep={this.props.handleUpdateStep}
                            onClick={(index) => {
                                this.changeClientTabCallBack(2);
                                this.setState({"selected_script": index});
                            }}
                            mccs={this.props.mccs}
                            saved_data={this.props.saved_data}
                        />
                    </div>
                }
              </span>
          </GridItem>
          <GridItem
              md={4}
              style={{"display": "flex"}}
              xs={12}
          >
              <div
                  style={{
                      "border": "1px solid #D2D2D2",
                      "borderRadius": "5px",
                      "padding": "10px",
                      "width": "100%"
                  }}
              >
                  <table>
                      <thead>
                          <tr>
                              <th
                                  style={{
                                      "textAlign": "left",
                                      "paddingBottom": "10px"
                                  }}
                              >
                                  Managers
                              </th>
                              <th style={{"width": "1%"}}>
                                  {this.props.accessControlManager && client_selected.deleted_at === null
                                      ? <Button
                                          title={'Add manager to client'}
                                          className="success"
                                          color="success"
                                          justIcon
                                          onClick={(event) => this.handleAddClientManager(
                                              event,
                                              client_selected ? client_selected.id : ""
                                          )}
                                          round
                                          simple
                                          style={{"height": "26px",
                                              "paddingTop": "4px"}}
                                      >
                                          <Add />
                                          </Button>
                                      : " "}
                              </th>
                          </tr>
                      </thead>
                      <tbody>
                          {client_selected
                              ? client_selected.managers.map((prop, key) => (<tr key={key}>
                                      <td>
                                          {`${prop.first_name 
                                          } ${
                                              prop.last_name 
                                          } (${
                                              prop.company 
                                          })`}
                                      </td>
                                      <td>
                                        {this.props.accessControlManager && client_selected.deleted_at === null
                                        ? <Button
                                                title={'Remove manager from client'}
                                                style={{
                                                    height: "26px",
                                                    paddingTop: "4px"
                                                }}
                                                onClick={(event) => this.handleDeleteClientManager(
                                                        event,
                                                        client_selected.id,
                                                        prop.id
                                                    )}
                                                justIcon
                                                round
                                                simple
                                                color="danger"
                                                className="remove"
                                            >
                                                <Close />
                                            </Button>
                                        : " "}
                                      </td>
                                  </tr>))
                              : null}
                      </tbody>
                  </table>
              </div>
          </GridItem>
      </GridContainer>
    };
  }

  contactsTab(client_selected, contacts, classes) {
      return {
        "tabName": "Contacts",
        "tabContent":
            <GridContainer style={{"minHeight": "250px"}}>
                <Card style={{"boxShadow": "none"}}>
                    <CardHeader
                        color="success"
                        icon
                    >
                        {client_selected.deleted_at === null
                            ? <CardIcon
                                title={'New contact'}
                                className="float-button"
                                color="info"
                                onClick={(event) => this.handleAddContact(
                                    event,
                                    client_selected.id
                                )}
                            >
                                <Add />
                            </CardIcon>
                            : ""}
                        {client_selected.deleted_at === null
                            ? <CardIcon
                                title={'Refresh contacts table'}
                                className="float-button"
                                color="warning"
                                onClick={(event) => this.refreshClientContactsTable(
                                    event,
                                    client_selected.id
                                )}
                            >
                                <Refresh />
                            </CardIcon>
                            :""}
                    </CardHeader>
                    {contacts.length === 0
                        ? <GridItem
                            md={12}
                            style={{"textAlign": "center",
                                "lineHeight": "170px"}}
                        >
                            No contacts
                            </GridItem>
                        : <GridItem md={12}>
                            <Table
                                customCellClasses={[classes.right]}
                                customClassesForCells={[7]}
                                customHeadCellClasses={[classes.right]}
                                customHeadClassesForCells={[7]}
                                tableData={contacts}
                                tableHead={[
                                    "ID",
                                    "First Name",
                                    "Last Name",
                                    "Company",
                                    "Position",
                                    "Email",
                                    "Phone",
                                    "Actions"
                                ]}
                            />
                            </GridItem>}
                    {this.state.contact_details_wizard
                        ? <ContactDetailsWizard
                            auth={this.props.auth}
                            client_id={client_selected ? client_selected.id : ""}
                            contactDetailsWizard={this.state.contact_details_wizard}
                            countries={this.props.countries}
                            handleDownloadContactDocument={
                                this.handleDownloadContactDocument
                            }
                            handleSaveContactDetails={this.handleSaveContactDetails}
                            handleSaveContactError={this.handleSaveContactError}
                            onClose={() => this.handleClose("contact_details_wizard")}
                            {...this.state}
                            readOnly={client_selected.deleted_at !== null}
                        />
                        : ""}
                </Card>
            </GridContainer>
        };
  }

    scriptsTab(client_selected, manager) {
        return {
            "tabName": "Scripts",
            "tabContent":
                <GridContainer style={{"minHeight": "250px"}}>
                    <Card style={{"boxShadow": "none"}}>
                        <CardHeader
                            color="success"
                            icon
                        >
                            {/* {this.props.client_selected.deleted_at === null
                                ? <CardIcon
                                    title={'Generate Script'}
                                    className="float-button"
                                    color="info"
                                    onClick={(event) => this.props.generateScriptDialog(
                                        client_selected.id,
                                        client_selected.username
                                    )}
                                >
                                    <Add />
                                </CardIcon> : ""}
                            {" "} */}
                            {this.props.client_selected.deleted_at === null
                                ? <CardIcon
                                    title={'Refresh scripts'}
                                    className="float-button"
                                    color="warning"
                                    onClick={(event) => this.refreshClientContactsTable(
                                        event,
                                        client_selected.id
                                    )}
                                >
                                    <Refresh />
                                </CardIcon> : ""}
                        </CardHeader>
                        <GridItem md={12}>
                            {
                            this.state.isReadOnly || (client_selected.hasOwnProperty('scripts') && Object.keys(client_selected.scripts).length === 0)
                                ? <GridItem
                                    md={12}
                                    style={{"textAlign": "center",
                                        "lineHeight": "170px"}}
                                >
                                    No scripts
                                </GridItem>
                                :  <div style={{"paddingBottom": "1rem"}}>
                                    <TabbedChecklist
                                        basic={false}
                                        checklist={client_selected.scripts}
                                        registry_entries={client_selected.registry_entries}
                                        handleUpdateStep={this.props.handleUpdateStep}
                                        handleUpdateWatch={this.props.handleUpdateWatch}
                                        saveChecklist={this.props.saveChecklist}
                                        files={client_selected.files}
                                        value={this.state.selected_script === -1 ? 0 : this.state.selected_script}
                                        readOnly={client_selected.deleted_at !== null}
                                        acquirers={this.state.selected_form.acquirers}
                                        applications={this.props.formOptions}
                                        application_schema={this.state.selected_form.forms_schemas}
                                        client_files={this.props.client_selected.files}
                                        computeApplicationProgress={this.computeApplicationProgress}
                                        setUsersNotification={this.props.setUsersNotification}
                                        refreshClientContactsTable={this.props.refreshClientContactsTable}
                                        handleDownloadFile={this.handleDownloadFileNoClientID}
                                        handleApplicationSignature={this.props.handleApplicationSignature}
                                        auth={this.props.auth}
                                        username={client_selected.username}
                                        watched={client_selected.watchedEvents}
                                        mccs={this.props.mccs}
                                        saved_data={this.props.saved_data}
                                        client_company={client_selected.company}
                                        transaction_methods={client_selected.transaction_methods}
                                    />
                                </div>
                            }
                        </GridItem>
                    </Card>
                </GridContainer>
        };
    }

  applicationsTab(client_selected, classes) {
      return {
        "tabName": "Applications",
        "tabContent":
            <GridContainer style={{"minHeight": "250px"}}>
                <Card style={{"boxShadow": "none"}}>
                    <CardHeader
                        color="success"
                        icon
                    >
                        {this.props.client_selected.deleted_at === null
                            ? <FormSelectDialog
                                client_id={client_selected.id}
                                getFormOptions={this.props.getFormOptions}
                                handleAddForm={this.props.handleAddForm}
                                options={this.props.formOptions}
                            /> : ""}
                        {this.props.client_selected.deleted_at === null
                            ? <CardIcon
                                title={'Refresh applications table'}
                                className="float-button"
                                color="warning"
                                onClick={(event) => this.handleGetClientForms()}
                            >
                                <Refresh />
                            </CardIcon> : ""}
                    </CardHeader>
                    {this.state.selected_form.client_forms.length === 0
                        ? <GridItem
                            md={12}
                            style={{"textAlign": "center",
                                "lineHeight": "170px"}}
                        >
                            No Applications
                            </GridItem>
                        : <GridItem md={12}>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[3]}
                                customHeadCellClasses={[
                                    "w-50",
                                    "w-30",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3
                                ]}
                                tableData={this.state.selected_form.client_forms.map((row) => {

                                    const schema = this.state.selected_form.forms_schemas.find((o) => o.id === row.form_id);
                                    return [
                                        row.id,
                                        schema.name,
                                        this.computeApplicationProgress(row.fields_filled_count, row.fields_count),
                                        <>
                                            <FormOpenDialog
                                                disabled={this.props.client_selected.deleted_at !== null}
                                                client_form_id={row.id}
                                                client_id={row.client_id}
                                                form_data_init={row.form_data}
                                                onClose={() => this.handleGetClientForms()}
                                                onSubmit={this.props.handleFormSubmit}
                                                schema={schema.forms_schema}
                                                submitForApproval={(formData) => {
                                                    this.props.handleFormSubmitForApproval(
                                                        formData,
                                                        this.props.client_selected.id,
                                                        row.id
                                                    );
                                                }}
                                                uiSchema={schema.ui_schema}
                                                setUsersNotification={this.props.setUsersNotification}
                                            />
                                            {this.props.client_selected.deleted_at === null
                                            ? <Button
                                                title={'Submit application for approval'}
                                                className="edit"
                                                color="warning"
                                                justIcon
                                                onClick={(event) => this.props.handleFormSubmitForApproval(
                                                    row.form_data,
                                                    this.props.client_selected.id,
                                                    row.id
                                                )}
                                                round
                                                simple
                                            >
                                                <ThumbUpIcon style={{width:"22px", height: "22px"}} />
                                            </Button> : ""}
                                            {this.props.client_selected.deleted_at === null
                                            ? <Button
                                                title={'Submit application to acquirer'}
                                                className="edit"
                                                color="danger"
                                                justIcon
                                                onClick={() => {
                                                    this.setState({
                                                        "submitClientApplicationModal": true,
                                                        "submitClientApplicationModalMsg": "Please select an acquirer",
                                                        "submitClientApplicationTitle": "Submit Application to Acquirer",
                                                        "applicationToSubmit": row.id
                                                    });
                                                }}
                                                round
                                                simple
                                            >
                                                <PictureAsPdfIcon style={{width:"22px", height: "22px"}} />
                                            </Button> : ""}
                                        </>
                                    ];

                                })}
                                tableHead={[
                                    "ID",
                                    "Title",
                                    "Progress",
                                    "Actions"
                                ]}
                            />
                            </GridItem>}
                </Card>
            </GridContainer>
            };
  }

  notesTab(client_selected, notes, classes) {
      return {
        "tabName": "Notes",
        "tabContent":
            <GridContainer style={{"minHeight": "250px"}}>
                <Card style={{"boxShadow": "none"}}>
                    <CardHeader
                        color="success"
                        icon
                    >
                        {this.props.client_selected.deleted_at === null && AccessControl.checkRoutePermissions(
                            "/clients",
                            this.props.auth,
                            "add_notes"
                        )
                            ? <CardIcon
                                title={'New note'}
                                className="float-button"
                                color="info"
                                onClick={(event) => this.handleAddNote(
                                    event,
                                    client_selected.id
                                )}
                            >
                                <Add />
                            </CardIcon>
                            : ""}
                        {this.props.client_selected.deleted_at === null
                            ? <CardIcon
                                title={'Refresh notes table'}
                                className="float-button"
                                color="warning"
                                onClick={(event) => this.refreshClientContactsTable(
                                    event,
                                    client_selected.id
                                )}
                            >
                                <Refresh />
                            </CardIcon> : ""}
                    </CardHeader>
                    {notes.length === 0
                        ? <GridItem
                            md={12}
                            style={{"textAlign": "center",
                                "lineHeight": "170px"}}
                        >
                            No notes
                            </GridItem>
                        : <GridItem md={12}>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[4]}
                                customHeadCellClasses={[
                                    "w-20",
                                    "w-20",
                                    "w-60",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3,
                                    4
                                ]}
                                tableData={notes}
                                tableHead={[
                                    "ID",
                                    "Author",
                                    "Subject",
                                    "Note",
                                    "Actions"
                                ]}
                            />
                            </GridItem>}
                    {this.state.client_note_details
                        ? <ClientNote
                            auth={this.props.auth}
                            client_id={client_selected ? client_selected.id : ""}
                            clientNote={this.state.client_note_details}
                            handleSaveContactDetails={this.handleSaveContactDetails}
                            handleSaveContactError={this.handleSaveContactError}
                            onClose={() => this.handleClose("client_note_details")}
                            refreshClientContactsTable={
                                this.props.refreshClientContactsTable
                            }
                            setUsersNotification={this.props.setUsersNotification}
                            {...this.state}
                        />
                        : ""}
                </Card>
            </GridContainer>
            };
  }

  filesTab(client_selected, files, classes) {
      return {
        "tabName": "Files",
        "tabContent":
            <GridContainer style={{"minHeight": "250px"}}>
                <Card style={{"boxShadow": "none"}}>
                    {this.state.isReadOnly ? "" : 
                        <CardHeader
                            color="success"
                            icon
                        >
                            {this.props.client_selected.deleted_at === null
                                ? <CardIcon
                                    title={'New file'}
                                    className="float-button"
                                    color="info"
                                    onClick={(event) => this.handleAddFile(
                                        event,
                                        client_selected.id
                                    )}
                                >
                                    <Add />
                                </CardIcon> : ""}
                            {this.props.client_selected.deleted_at === null
                                ? <CardIcon
                                    title={'Refresh files table'}
                                    className="float-button"
                                    color="warning"
                                    onClick={(event) => this.refreshClientContactsTable(
                                        event,
                                        client_selected.id
                                    )}
                                >
                                    <Refresh />
                                </CardIcon> : ""}
                        </CardHeader>
                    }
                    {files.length === 0
                        ? <GridItem
                            md={12}
                            style={{"textAlign": "center",
                                "lineHeight": "170px"}}
                        >
                            No files
                            </GridItem>
                        : <GridItem md={12}>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[4]}
                                customHeadCellClasses={[
                                    "w-30",
                                    "w-30",
                                    "w-20",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3,
                                    4
                                ]}
                                tableData={files}
                                tableHead={[
                                    "ID",
                                    "File Name",
                                    "Tags",
                                    "Uploader",
                                    "Actions"
                                ]}
                            />
                            </GridItem>}
                    {this.state.client_file_details
                        ? <ClientFile
                            auth={this.props.auth}
                            client_id={client_selected ? client_selected.id : ""}
                            ClientFile={this.state.client_file_details}
                            handleDownloadFile={this.handleDownloadFile}
                            handleDownloadVersionFile={
                                this.handleDownloadVersionFile
                            }
                            handleSaveContactDetails={this.handleSaveContactDetails}
                            handleSaveContactError={this.handleSaveContactError}
                            onClose={() => this.handleClose("client_file_details")}
                            refreshClientContactsTable={
                                this.props.refreshClientContactsTable
                            }
                            setUsersNotification={this.props.setUsersNotification}
                            {...this.state}
                            readOnly={client_selected.deleted_at !== null}
                        />
                        : ""}
                </Card>
            </GridContainer>
            };
  }

  infoTab(client_selected, info, classes) {
      return {
        "tabName": "Info",
        "tabContent":
            <GridContainer style={{"minHeight": "250px"}}>
                <Card style={{"boxShadow": "none"}}>
                    {this.state.isReadOnly ? "" : 
                        <CardHeader
                            color="success"
                            icon
                        >
                        </CardHeader>
                    }
                    {info.length === 0
                        ? <GridItem
                            md={12}
                            style={{"textAlign": "center",
                                "lineHeight": "170px"}}
                        >
                            No info
                            </GridItem>
                        : <GridItem md={12}>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[4]}
                                customHeadCellClasses={[
                                    "w-40",
                                    "w-40",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3,
                                    4
                                ]}
                                tableData={info}
                                tableHead={[
                                    "Name",
                                    "Key",
                                    "Value",
                                    "Watch"
                                ]}
                            />
                            </GridItem>}
                </Card>
            </GridContainer>
            };
  }

  libraryTab(client_selected, library, classes) {
      return {
        "tabName": "Library",
        "tabContent":
            <GridContainer style={{"minHeight": "250px"}}>
                <Card style={{"boxShadow": "none"}}>
                    {this.state.isReadOnly ? "" : 
                        <CardHeader
                            color="success"
                            icon
                        >
                            {this.props.client_selected.deleted_at === null
                                ? <CardIcon
                                    title={'Add documents'}
                                    className="float-button"
                                    color="info"
                                    onClick={(event) => {
                                        this.setState({
                                            "selected_documents": [],
                                            "selectLibraryDocumentsModal": true
                                        });
                                    }}
                                >
                                    <Add />
                                </CardIcon> : ""}
                            {this.props.client_selected.deleted_at === null
                                ? <CardIcon
                                    title={'Refresh library table'}
                                    className="float-button"
                                    color="warning"
                                    onClick={(event) => this.refreshClientContactsTable(
                                        event,
                                        client_selected.id
                                    )}
                                >
                                    <Refresh />
                                </CardIcon> : ""}
                        </CardHeader>
                    }
                    {library.length === 0
                        ? <GridItem
                            md={12}
                            style={{"textAlign": "center",
                                "lineHeight": "170px"}}
                        >
                            No documents
                            </GridItem>
                        : <GridItem md={12}>
                            <Table
                                customCellClasses={[`no-wrap ${classes.right}`]}
                                customClassesForCells={[3]}
                                customHeadCellClasses={[
                                    "w-40",
                                    "w-40",
                                    classes.right
                                ]}
                                customHeadClassesForCells={[
                                    1,
                                    2,
                                    3
                                ]}
                                tableData={library}
                                tableHead={[
                                    "ID",
                                    "File Name",
                                    "Tags",
                                    "Actions"
                                ]}
                            />
                            </GridItem>}
                </Card>
            </GridContainer>
            };
  }

  setClientDetailsTabs(client_selected) {
    const {classes} = this.props,
        contacts = client_selected && client_selected.contacts
            ? this.setContacts(
                client_selected.id,
                client_selected.contacts
            )
            : [],
        notes = client_selected && client_selected.contacts
            ? this.setNotes(
                client_selected.id,
                client_selected.notes
            )
            : [],
        files = client_selected && client_selected.files
            ? this.setFiles(
                client_selected.id,
                client_selected.files
            )
            : [],
        manager = this.props.manager_teams && client_selected
            ? this.props.manager_teams.find((o) => o.id === client_selected.manager_team_id)
            : null,
        info = client_selected && client_selected.registry_entries
            ? this.setInfos(
                client_selected.id,
                client_selected.registry_entries
            )
            : [],
        library = client_selected && client_selected.library_documents
            ? this.setLibrary(
                client_selected.id,
                client_selected.library_documents
            )
            : [];
    let tabs = [];
    tabs.push(this.detailsTab(client_selected, manager));
    if (!this.state.isReadOnly) {
        tabs.push(this.contactsTab(client_selected, contacts, classes));
        tabs.push(this.scriptsTab(client_selected, manager));
        // tabs.push(this.applicationsTab(client_selected, classes));
        tabs.push(this.notesTab(client_selected, notes, classes));
    }
    tabs.push(this.filesTab(client_selected, files, classes));
    if (!this.state.isReadOnly) {
        tabs.push(this.infoTab(client_selected, info, classes));
        tabs.push(this.libraryTab(client_selected, library, classes));
    }
    return tabs;
  }

    handleAddDocuments = (documents) => {
        this.setState({'selected_documents': documents});
        this.props.handleAddDocuments(
            documents,
            () => {
                this.handleClose("selectLibraryDocumentsModal");
                this.props.refreshClientContactsTable(null, this.props.client_selected.id);
            }
        );
    };

  render () {
      const {client_selected} = this.props;
      return client_selected
          ? <GridItem xs={12}>
              <style>
                  {"@media (min-width: 768px) {.MuiTabs-flexContainer {float: right;}}" +
            ".CustomTabs-cardTitle-1018 {font-weight: bold;} .MuiTabs-scroller {overflow: auto !important;}" +
            ".MuiTableCell-head {font-size: 0.9rem !important;}" +
            ".MuiTableCell-body {padding: 0px 0px 0px 8px! important;}"}
              </style>
              <CustomTabs
                  changeTabCallBack={this.changeClientTabCallBack}
                  headerColor="warning"
                  tabs={this.setClientDetailsTabs(client_selected)}
                  title={
                      client_selected
                          ? `${client_selected.company + (client_selected.username ? " (" + client_selected.username + ")" : "")}`
                          : ""
                  }
                  value={this.state.current_client_tab}
              />
            {this.state.submitClientApplicationModal
                ? <SubmitClientApplication
                    submitClientApplicationModal={this.state.submitClientApplicationModal}
                    submitClientApplicationModalMsg={this.state.submitClientApplicationModalMsg}
                    submitClientApplicationTitle={this.state.submitClientApplicationTitle}
                    onClose={() => this.handleClose("submitClientApplicationModal")}
                    form_id={this.state.applicationToSubmit}
                    acquirers={this.state.selected_form.acquirers}
                    setUsersNotification={this.props.setUsersNotification}
                    onSubmitClick={(fd, url) => this.handleSubmitApplicationClick(fd, url)}
                    client_files={this.props.client_selected.files}
                />
                : ""
            }
            {this.state.downloadApplicationBundleModal
                ? <SubmittedClientApplicationDownload
                    downloadApplicationBundleModal={this.state.downloadApplicationBundleModal}
                    downloadApplicationBundleModalMsg={this.state.downloadApplicationBundleModalMsg}
                    downloadApplicationBundleModalFooter={this.state.downloadApplicationBundleModalFooter}
                    downloadApplicationBundleTitle={this.state.downloadApplicationBundleTitle}
                    onClose={() => this.handleClose("downloadApplicationBundleModal")}
                    fileName={this.state.downloadApplicationFileName}
                    id={this.state.downloadApplicationFileId}
                    downloadFile={this.handleDownloadFile}
                    client_id={this.props.client_selected.id}
                />
                : ""
            }
            {this.state.selectLibraryDocumentsModal
                ? <SelectLibraryDocuments
                    client_documents={this.props.client_selected.library_documents ? this.props.client_selected.library_documents : []}
                    document_library={this.props.document_library ? this.props.document_library : []}
                    selected_documents={this.props.selected_documents ? this.props.selected_documents : []}
                    handleAddDocuments={this.handleAddDocuments}
                    onClose={() => {
                        this.handleClose("selectLibraryDocumentsModal");
                    }}
                    {...this.state}
                />
                : ""
            }
          </GridItem>
          : "";
  }
}

export default withStyles(extendedTablesStyle)(ClientFullDetailsDialog);
