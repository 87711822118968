import Dashboard from "views/Dashboard/Dashboard.jsx";
import ErrorPage from "views/Pages/ErrorPage.jsx";
import LoginPage from "views/Pages/LoginPage.jsx";
import Notifications from "views/Components/Notifications.jsx";
import UserProfile from "views/Pages/UserProfile.jsx";
import ChangePassword from "views/Pages/ChangePassword.jsx";
import SendResetEmail from "views/Pages/SendResetEmail.jsx";
import ResetPassword from "views/Pages/ResetPassword.jsx";
import Users from "views/Pages/Users.jsx";
import ManagerTeams from "views/Pages/ManagerTeams.jsx";
import Clients from "views/Pages/Clients.jsx";
import Files from "views/Pages/Files.jsx";
import Calendar from "views/Pages/Calendar.jsx";
import Tools from "views/Pages/Tools.jsx";
// import Approvals from "views/Pages/Approvals.jsx";
import Applications from "views/Pages/Applications.jsx";
import ASIC from "views/Pages/ASIC.jsx";
import Leads from "views/Pages/Leads.jsx";
// import Tickets from "views/Pages/Tickets.jsx";
import Acquirers from "views/Pages/Acquirers.jsx";
import DocumentLibrary from "views/Pages/DocumentLibrary.jsx";
import Mcc from "views/Pages/Mcc.jsx";

// @material-ui/icons
import DashboardIcon from "@material-ui/icons/Dashboard";
import UsersIcon from "@material-ui/icons/People";
import TeamIcon from "@material-ui/icons/GroupWork";
import AccountBox from "@material-ui/icons/AccountBox";
import Description from "@material-ui/icons/Description";
import Event from "@material-ui/icons/Event";
import BuildIcon from "@material-ui/icons/Build";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import AccountTree from "@material-ui/icons/AccountTree";
import DeviceHub from "@material-ui/icons/DeviceHub";
// import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import SettingsApplications from "@material-ui/icons/SettingsApplications";
import CloudUpload from '@material-ui/icons/CloudUpload';
import ViewApplicationStatusDialog from "customs/components/ViewApplicationStatusDialog";

const dashRoutes = [
    {
        "path": "/dashboard",
        "name": "Dashboard",
        "icon": DashboardIcon,
        "component": Dashboard,
        "layout": "/admin"
    },
    {
        "path": "/login",
        "name": "Login Page",
        "component": LoginPage,
        "layout": "/auth",
        "invisible": true
    },
    {
        "path": "/profile",
        "name": "User Profile",
        "component": UserProfile,
        "layout": "/admin",
        "invisible": true
    },
    {
        "path": "/error-page",
        "name": "Error Page",
        "component": ErrorPage,
        "layout": "/auth",
        "invisible": true
    },
    {
        "path": "/notifications",
        "name": "Notifications",
        "component": Notifications,
        "layout": "/admin",
        "invisible": true
    },
    {
        "path": "/changepassword",
        "name": "Password",
        "component": ChangePassword,
        "layout": "/admin",
        "invisible": true
    },
    {
        "path": "/password/reset/:token",
        "name": "Password Reset",
        "component": ResetPassword,
        "layout": "/auth",
        "invisible": true
    },
    {
        "path": "/password/reset",
        "name": "Password Reset",
        "component": SendResetEmail,
        "layout": "/auth",
        "invisible": true
    },
    {
        "path": "/users",
        "name": "Users",
        "icon": UsersIcon,
        "component": Users,
        "layout": "/admin"
    },
    {
        "path": "/clients",
        "name": "Clients",
        "icon": AccountBox,
        "component": Clients,
        "layout": "/admin"
    },
    {
        "path": "/manager_teams",
        "name": "Manager Teams",
        "icon": TeamIcon,
        "component": ManagerTeams,
        "layout": "/admin"
    },
    {
        "path": "/acquirers",
        "name": "Acquirers",
        "icon": AccountTree,
        "component": Acquirers,
        "layout": "/admin"
    },
    {
        "path": "/leads",
        "name": "Leads",
        "icon": DeviceHub,
        "component": Leads,
        "layout": "/admin"
    },
    {
        "path": "/files",
        "name": "Files",
        "icon": Description,
        "component": Files,
        "layout": "/admin"
    },
    {
        "path": "/calendar",
        "name": "Calendar",
        "icon": Event,
        "component": Calendar,
        "layout": "/admin"
    },
    {
        "path": "/tools",
        "name": "Tools",
        "icon": BuildIcon,
        "component": Tools,
        "layout": "/admin"
    },
    {
        "path": "/applications",
        "name": "Applications",
        "icon": ThumbUpIcon,
        "component": Applications,
        "layout": "/admin"
    },
    {
        "path": "/asic",
        "name": "ASIC Upload",
        "icon": CloudUpload,
        "component": ASIC,
        "layout": "/admin"
    },
    {
        "path": "/document_library",
        "name": "Document Library",
        "icon": LibraryBooks,
        "component": DocumentLibrary,
        "layout": "/admin"
    },
    // {
    //   "collapse": true,
    //   "name": "Configuration",
    //   "icon": SettingsApplications,
    //   "state": "componentsCollapse",
    //   "collapse_path": "/config",
    //   "views": [
        {
            "path": "/mcc",
            "name": "MCC",
            "icon": SettingsApplications, //Remove when we add more configuration pages
            "component": Mcc,
            "layout": "/admin"
        },
    //   ]
    // },
    {
        "path": "/application/:id",
        "name": "Application Details",
        "icon": ThumbUpIcon,
        "component": ViewApplicationStatusDialog,
        "layout": "/admin",
        "invisible": true
    },
    {
        "path": "/notifications",
        "name": "Notifications",
        "component": Notifications,
        "layout": "/admin",
        "invisible": true
    },

    /*
     *{
     * path: "/tickets",
     * name: "Tickets",
     * icon: ConfirmationNumberIcon,
     * component: Tickets,
     * layout: "/admin"
     *},
     */
];
export default dashRoutes;
