import React from "react";
import PropTypes from "prop-types";
import {Redirect, Link} from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";

// Core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import AddAlert from "@material-ui/icons/AddAlert";
import Snackbar from "components/Snackbar/Snackbar.jsx";

import {connect} from "react-redux";
import AuthService from "customs/auth/AuthService";

import axiosHelper from "axios/axiosHelper";
import AccessControl from "customs/components/AccessControl";
import Table from "components/Table/Table.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import AccountBox from "@material-ui/icons/AccountBox";
import ListAlt from "@material-ui/icons/ListAlt";
import EventNote from "@material-ui/icons/EventNote";
import CardIcon from "components/Card/CardIcon.jsx";
import axios from "axios/axios";
import helpers from "customs/helpers/helpers";
import ClientDetailsDialog from "customs/components/ClientDetailsDialog";
import validations from "customs/helpers/validations";
import Button from "components/CustomButtons/Button.jsx";
import ConfirmDialog from "customs/components/ConfirmDialog";
import SelectScriptTemplates from "customs/components/SelectScriptTemplates";
import DuplicateClientScripts from "customs/components/DuplicateClientScripts";
import CardFooter from "components/Card/CardFooter.jsx";
import { withRouter } from "react-router-dom";
import EmojiFlags from "@material-ui/icons/EmojiFlags";
import Report from "@material-ui/icons/Report";
import PieChart from "@material-ui/icons/PieChart";
import Timeline from "@material-ui/icons/Timeline";
import { Chart } from "react-google-charts";

import moment from "moment";
import "moment-timezone";

class Dashboard extends React.Component {

    constructor (props) {

        super(props);
        
        let updateApplication = AccessControl.checkRoutePermissions(
            "/applications",
            this.props.auth,
            "update"
        );
        let canApprove = AccessControl.checkRoutePermissions(
            "/applications",
            this.props.auth,
            "can_approve"
        );

        this.state = {
            "value": 0,
            "events": [],
            "range": "Today",
            "tr": false,
            "tr_color": "success",
            "doneModalMsg": "",
            "clients": null,
            "change_events": null,
            "clientDetailsModal": false,
            "managers": [],
            "manager_teams": [],
            "merchant_types": [],
            "mccs": [],
            "business_types": {},
            "states": null,
            "countries": null,
            "id": "",
            "username": "",
            "password": "",
            "company": "",
            "user_roles": [],
            "username_valid": "",
            "password_valid": "",
            "primary_contact_id": "",
            "contacts": [],
            "company_valid": "",
            "managers_valid": "",
            "client_transaction_methods": [],
            "transaction_methods_valid": "",
            "client_managers": [],
            "client_manager_team": "",
            "client_manager_team_valid": "",
            "primary_contact_first_name_valid": "",
            "primary_contact_last_name_valid": "",
            "lead_operator_id": "",
            "merchant_type_id": "",
            "mcc_code": "",
            "switch_to_client_script": null,
            "selected_templates": [],
            "from_client_id": "",
            "duplicateClientScriptsModal": false,
            "clientsList": [],
            "clientScripts": [],
            "available_managers": [],
            "client_primary_contact": [],
            "contact_documents": [],
            "document_library": [],
            "selected_documents": [],
            "confirmModal": false,
            "confirmModalTitle": "",
            "confirmModalMsg": "Are you sure?",
            "action": "",
            "script_templates": [],
            "selectScriptTemplateModal": false,
            "client_count": null,
            "pending_applications": null,
            "applications_total": 0,
            "applications_chart": null,
            "applications_burndown": null,
            "user_tz_offset": moment.tz(props.auth.user.timezone).utcOffset(),
            "updateApplication": updateApplication,
            "canApprove": canApprove,
            "dont_focus_child": false,
            "burn_down_chart_value": "last_30_days"
        };
        this.processErrorAxios = this.processErrorAxios.bind(this);
        this.setUsersNotification = this.setUsersNotification.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleManualChange = this.handleManualChange.bind(this);
        this.handleChangeByName = this.handleChangeByName.bind(this);
        this.generateScriptDialog = this.generateScriptDialog.bind(this);
        this.handleGenerateScript = this.handleGenerateScript.bind(this);
        this.doSaveUserDetailsClick = this.doSaveUserDetailsClick.bind(this);
        this.processSaveUserDetails = this.processSaveUserDetails.bind(this);
        this.handleContactDetailsSaved = this.handleContactDetailsSaved.bind(this);
        this.doUploadDocuments = this.doUploadDocuments.bind(this);
        this.processGenerateScript = this.processGenerateScript.bind(this);
        this.showDuplicateScriptDialog = this.showDuplicateScriptDialog.bind(this);
        this.handleDuplicateScriptClientSelect = this.handleDuplicateScriptClientSelect.bind(this);
        this.handleDuplicateScripts = this.handleDuplicateScripts.bind(this);
        this.viewApplication = this.viewApplication.bind(this);
        this.handleFlagPage = this.handleFlagPage.bind(this);

        this.parentComponent = this.parentComponent.bind(this);
        window.parentComponent = this.parentComponent;
        this.runComponentMethod = this.runComponentMethod.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
    }

    parentComponent(action, params) {
        this.runComponentMethod(action, params);
    }

    runComponentMethod(action, params) {
        this.setState({'dont_focus_child': false});
        if (typeof this[action] === "function" && (action === 'handleStatusChange' || action === 'handleAddNote' || action === 'handleFlagPage')) {
            this[action](...params);
        } else if (action === 'viewApplication') {
            try {
                if (params[0] !== null && (params[0] === 'messages' || params[0] === 'escalate_application' || params[0] === 'deescalate_application' || params[0] === 'generate_match_report' || params[0] === 'request_asic_search' || params[0] === 'request_sign_form')) {
                    let id = this.viewApplicationWindow.location.pathname.replace('/admin/application/', '').replace('/' ,'');
                    if (/^-?\d+$/.test(id)) {
                        id = parseInt(id);
                        setTimeout(() => {
                            try {
                                this.state.pending_applications.forEach(approval => {
                                    if (approval.id === id) {
                                        this.setState({'dont_focus_child': true}, function() {
                                            this.viewApplication(...params);
                                        });
                                    }
                                });
                            } catch(e1) {}
                        }, 100);
                    } else {
                        let callback = params[4];
                        if (callback !== null && typeof callback === "function") this.viewApplicationWindow.callbackComponent(callback);
                    }
                    if (params[0] === 'escalate_application' || params[0] === 'deescalate_application') {
                        this.fetchDashboard();
                    }
                } else {
                    let callback = params[4];
                    if (callback !== null && typeof callback === "function") this.viewApplicationWindow.callbackComponent(callback);
                }
            } catch(e) {}
        } else if (action === 'refreshApprovalsTable') {
            try {
                let id = this.viewApplicationWindow.location.pathname.replace('/admin/application/', '').replace('/' ,'');
                if (/^-?\d+$/.test(id)) {
                    id = parseInt(id);
                    setTimeout(() => {
                        try {
                            this.state.pending_applications.forEach(approval => {
                                if (approval.id === id) {
                                    this.viewApplication(params[0], approval, approval.status, true, params[1]); 
                                }
                            });
                        } catch(e1) {}
                    }, 100);
                }
            } catch(e) {}
        }
    }

    processErrorAxios (error, prop) {
        axiosHelper.processError(
            this.isUnmounted,
            prop,
            error,
            (state) => {
                this.setState(state);
            },
            () => {
                this.showUsersNotification();
            },
            () => {
                this.props.doLogout({...this.props});
            }
        );
    }

  hideNotification;
  showUsersNotification () {
      if (!this.state.tr) {
          this.setState({"tr": true});
      } else {
          clearTimeout(this.hideNotification);
      }
      this.setHideNotificationTimeout();
  }

  setHideNotificationTimeout () {
      this.hideNotification = setTimeout(
          () => {
              this.handleCloseNotification();
          },
          this.state.tr_color === "success" ? 5000 : 10000
      );
  }

  setUsersNotification (msg, color) {
      this.setState({"doneModalMsg": msg,
          "tr_color": color});
      this.showUsersNotification();
  }

  handleCloseNotification () {
      clearTimeout(this.hideNotification);
      this.setState({"tr": false,
          "doneModalMsg": ""});
  }

  handleRedirect () {
      const authService = new AuthService(
          this.state,
          this.props
      );
        if (!authService.getToken()) {
            return <Redirect to="/auth/login" />;
        } else if (authService.isPasswordExpired()) {
            return <Redirect to="/admin/changepassword" />;
        } else if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "view") !== true) {
            let to = AccessControl.getFirstActiveRoute(this.props.auth);
            if (to.length > 0) {
                to = "/admin" + to;
                return <Redirect to={to} />;
            }
        }
        return '';
  }

    componentDidMount () {
        this._ismounted = true;
        if (AccessControl.checkRoutePermissions("/clients", this.props.auth, "view") || AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "pending_applications")) {
            this.fetchDashboard();
        }
        if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "incomplete_scripts")) {
            this.fetchIncompleteScripts();
        }
        if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "change_events")) {
            this.fetchChangeEvents();
        }

        window.onunload = function() {
            if (this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                this.viewApplicationWindow.close();
                this.viewApplicationWindow = null;
            }
        }.bind(this);
    }

    componentWillUnmount() {
        if (this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
            this.viewApplicationWindow.close();
            this.viewApplicationWindow = null;
        }
    }

    fetchDashboard() {
        // helpers.showLoading();
        axios(this.props)
            .post(
                "/dashboard",
                {}
            )
            .then((res) => {
                helpers.hideLoading();
                this.setState({
                    "roles": res.data.roles,
                    "managers": res.data.managers,
                    "manager_teams": res.data.manager_teams,
                    "leads": res.data.leads,
                    "transaction_methods": res.data.transaction_methods,
                    "script_templates": res.data.script_templates,
                    "merchant_types": res.data.merchant_types,
                    "mccs": res.data.mccs,
                    "document_library": res.data.document_library,
                    "countries": res.data.countries,
                    "client_count": res.data.clients,
                    "pending_applications": res.data.pending_applications,
                    "applications_chart": res.data.applications_chart,
                    "applications_burndown": res.data.burndown_details,
                    "applications_total": res.data.applications_total
                })
            })
            .catch((error) => {});
    }

    fetchBurndownChart(type) {
        // helpers.showLoading();
        axios(this.props)
            .get("/burndown/chart?type=" + type)
            .then((res) => {
                // helpers.hideLoading();
                this.setState({
                    "applications_burndown": res.data.burndown_details,
                })
            })
            .catch((error) => {});
    }

    fetchIncompleteScripts() {
        // helpers.showLoading();
        axios(this.props)
            .post(
                "/incomplete_scripts",
                {}
            )
            .then((res) => {
                helpers.hideLoading();
                this.setState({
                    "clients": res.data.scripts ? res.data.scripts : [],
                });

                if (this.state.switch_to_client_script !== null) {
                    let client = this.state.clients.find((row) => row.id === this.state.switch_to_client_script);
                    if (client !== undefined && client !== null) {
                        setTimeout(
                            () => {
                                let script = client.scripts[0];
                                this.props.history.push({ 
                                    "pathname": "/admin/clients",
                                    "state": {"from": "dashboard", script}
                                });
                            },
                            300
                        );
                    }
                }
            })
            .catch((error) => {});
    }

    fetchChangeEvents() {
        // helpers.showLoading();
        axios(this.props)
            .post(
                "/change_events",
                {}
            )
            .then((res) => {
                helpers.hideLoading();
                this.setState({
                    "change_events": res.data.change_events ? res.data.change_events : []
                })
            })
            .catch((error) => {});
    }

    accessControlManager = AccessControl.checkRoutePermissions(
        "/clients",
        this.props.auth,
        "add_manager"
    );

    handleChange (event) {
        this.setState({
            [event.target.id]: event.target.value,
            [`${event.target.id}_valid`]: ""
        });
    }
  
    handleManualChange (name, value) {
        this.setState({[name]: value, [`${name}_valid`]: ""});
    }
  
    handleChangeByName (event) {
        this.setState({
            [event.target.name]: event.target.value,
            [`${event.target.name}_valid`]: ""
        });
    }

    handleMultiple = (event) => {
        this.setState({"client_managers": event.target.value});
    };
  
    handleTransactionMethods = (values) => {
        this.setState({"client_transaction_methods": values});
    };

    handleSelectManagerTeam = (event) => {
        this.setState({"client_manager_team": event.target.value});
    };

    handleSelectLead = (event) => {
        this.setState({"lead_operator_id": event.target.value});
    };

    handleSelectPrimaryContact = (event) => {
        this.setState({"primary_contact_id": event.target.value});
    };

    handleSelectMerchantType = (event) => {
        this.setState({"merchant_type_id": event.target.value});
    };

    handleSelectMCCCode = (code) => {
        this.setState({"mcc_code": code});
    };

    handleAcceptClientDetails (modal, client_primary_contact, contact_documents) {
        const username_valid = true, //this.state.username.length > 0 ? validations.validateUsername(this.state.username) : true,
            password_valid = true, //validations.validatePassword(this.state.password),
            company_valid = this.state.company.length > 0 && this.state.company.length <= 255 && validations.validateBusinessName(this.state.company),
          //   abn_valid = validations.validateABN(this.state.abn) || validations.validateACN(this.state.abn),
            transaction_methods_valid = this.state.client_transaction_methods && this.state.client_transaction_methods.length > 0,
            client_manager_team_valid = (this.state.client_manager_team && this.state.client_manager_team.toString().length > 0)
                                      || (this.state.client_managers && this.state.client_managers.toString().length > 0),
            primary_contact_first_name_valid = client_primary_contact.first_name && client_primary_contact.first_name.length > 0,
            primary_contact_last_name_valid = client_primary_contact.last_name && client_primary_contact.last_name.length > 0,
            managers_valid = client_manager_team_valid;
        if (
          username_valid &&
          password_valid &&
          company_valid &&
          managers_valid &&
          // abn_valid &&
          transaction_methods_valid &&
          client_manager_team_valid &&
          primary_contact_first_name_valid &&
          primary_contact_last_name_valid
        ) {
            const x = [];
            x[modal] = true;
            this.setState({
                ...x,
                "confirmModalMsg": "Are you sure?",
                "username_valid": "",
                "password_valid": "",
                "company_valid": "",
                "managers_valid": "",
                "transaction_methods_valid": "",
                "client_manager_team_valid": "",
                "primary_contact_first_name_valid": "",
                "primary_contact_last_name_valid": "",
              //   "abn_valid": "",
                "client_primary_contact": client_primary_contact,
                "contact_documents": contact_documents
            });
        } else {
            this.setState({
                "username_valid": username_valid ? "success" : "error",
                "password_valid": password_valid ? "success" : "error",
                "company_valid": company_valid ? "success" : "error",
                "managers_valid": managers_valid ? "success" : "error",
              //   "abn_valid": abn_valid ? "success" : "error",
                "client_primary_contact": client_primary_contact,
                "transaction_methods_valid": transaction_methods_valid ? "success" : "error",
                "client_manager_team_valid": client_manager_team_valid ? "success" : "error",
                "primary_contact_first_name_valid": primary_contact_first_name_valid ? "success" : "error",
                "primary_contact_last_name_valid": primary_contact_last_name_valid ? "success" : "error",
                "contact_documents": contact_documents
            });
        }
    }

    getCountriesData () {
        if (this.state.countries === null) {
            axios(this.props)
              .get("/countries")
              .then((response) => this.processCountriesData(response))
              .catch((error) => this.processErrorAxios(
                    error,
                    null
                ));
        }
    }
  
    processCountriesData (res) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({"countries": res.data.countries});
    }

    handleClose (modal) {
        const x = [];
        x[modal] = false;
        this.setState(x);
    }

    handleConfirmClick () {
        if (this.state.action === "create_client") {
            this.doSaveUserDetailsClick();
        } else if (this.state.action === "generate_script") {
            this.doHandleGenerateScript();
        } else if (this.state.action === "duplicate_script") {
            this.doHandleDuplicateScripts();
        }
    }

    doSaveUserDetailsClick () {
        this.handleClose("confirmModal");
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        let formData = {
            "id": this.state.id,
            "username": this.state.username,
            "password": this.state.password,
            "company": this.state.company,
            "cm_user_ids": this.state.client_managers,
            "manager_team_id": this.state.client_manager_team,
            "lead_operator_id": this.state.lead_operator_id,
            "merchant_type_id": this.state.merchant_type_id,
            "mcc_code": this.state.mcc_code,
            "primary_contact": this.state.client_primary_contact,
            "transaction_methods": this.state.client_transaction_methods
        };
        let fd = new FormData();
        Object.keys(formData).forEach((key) => {
            fd = helpers.createFormData(
                fd,
                formData[key],
                key
            );
        });
        axios(this.props)
            .post(
                "/client",
                fd,
                {
                    "headers": {"Content-Type": "application/x-www-form-urlencoded"}
                }
            )
            .then((response) => this.processSaveUserDetails(response, -1))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
  
    processSaveUserDetails (results, idx) {
        if (this.isUnmounted) {
            return;
        }
  
        let client = results.hasOwnProperty('data') && results.data.hasOwnProperty('client')
            ? results.data.client : (results.hasOwnProperty('data') ? results.data : results);
        this.setState({client_selected: client, client_count: this.state.client_count + 1});
      if (this.state.contact_documents === undefined || this.state.contact_documents.length === 0 || idx >= this.state.contact_documents.length - 1) {
          this.handleContactDetailsSaved();
      } else {
          this.doUploadDocuments(
              client.id,
              client.primary_contact_id,
              ++idx,
              client
          );
      }
    }

    doUploadDocuments (client_id, contact_id, idx, prev_result) {
      let fd = new FormData(),
          url = `/client/${client_id}/contact/${contact_id}/document`;
      if (
          this.state.contact_documents[idx].id &&
          this.state.contact_documents[idx].id !== undefined &&
          this.state.contact_documents[idx].id !== null
      ) {
          fd.append(
              "_method",
              "PUT"
          );
          fd.append(
              "id",
              this.state.contact_documents[idx].id
          );
          url = `${url}/${this.state.contact_documents[idx].id}`;
      }
      if (this.state.contact_documents[idx].file !== "") {
          fd.append(
              "file",
              this.state.contact_documents[idx].file
          );
      }
      if (this.state.contact_documents[idx].tags !== "") {
          fd.append(
              "tags",
              JSON.stringify(this.state.contact_documents[idx].tags)
          );
      }
      axios(this.props)
        .post(
              url,
              fd,
              {
                  "headers": {"Content-Type": "application/x-www-form-urlencoded"}
              }
          )
          .then((response) => this.processSaveUserDetails(prev_result, idx))
          .catch((error) => this.processUploadErrorAxios(
              error,
              null,
              client_id,
              contact_id,
              idx,
              prev_result
          ));
    }
  
    processUploadErrorAxios (
      error,
      prop,
      client_id,
      contact_id,
      idx,
      prev_result
    ) {
      const default_err = helpers.getErrorMessage(error),
          saveDetailsErrors = default_err === false
            ? this.state.saveDetailsErrors
            : [
                ...this.state.saveDetailsErrors,
                default_err
            ];
      this.setState({saveDetailsErrors});
      if (this.state.contact_documents.length === 0 || idx >= this.state.contact_documents.length - 1) {
          this.handleContactDetailsSaved();
      } else {
          this.doUploadDocuments(
              client_id,
              contact_id,
              ++idx,
              prev_result
          );
      }
    }

    handleContactDetailsSaved() {
        this.setState({
            "btnDisabled": false,
            "confirmModal": false,
            "clientDetailsModal": false,
            "doneModalMsg": "Client details saved",
            "tr_color": "success",
            "client_primary_contact": [],
            "contact_documents": []
        });
        this.showUsersNotification();
        helpers.hideLoading();

        this.generateScriptDialog(this.state.client_selected.id, this.state.client_selected.username);
    }

    generateScriptDialog(id, username) {
        if (this.isUnmounted) {
            return;
        }

        helpers.showLoading();
        axios(this.props)
            .post(`/client/${id}/scripts`, {}, {})
            .then((response) => {
                this.setState({
                    "selectScriptTemplateModal": true,
                    "username": username,
                    "client_id_script": id,
                    "script_templates": response.data
                });
                helpers.hideLoading();
            })
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    handleGenerateScript(templates) {
        if (Object.keys(templates).length === 0 || templates.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one script template.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }

        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to generate scripts for this client?`,
            "confirmModalTitle": "Generate Script",
            "action": "generate_script",
            "selected_templates": templates
        });
    }

    doHandleGenerateScript() {
        this.setState({"confirmModal": false, "action": "generate_script", "selectScriptTemplateModal": false});
        helpers.showLoading();
        this.setState({"btnDisabled": true});

        axios(this.props)
            .post(
                `/client/${this.state.client_id_script}/generate/script`,
                {"templates": this.state.selected_templates},
                {}
            )
            .then((response) => this.processGenerateScript(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }

    processGenerateScript (results) {
        if (this.isUnmounted) {
            return;
        }
        let switch_to_client_script = this.state.selected_templates.length === 1 ? this.state.client_id_script : null;
        this.setState({
            "btnDisabled": false,
            "switch_to_client_script": switch_to_client_script,
            "client_id_script": "",
            "doneModalMsg": "Client script generated",
            "selected_templates": [],
            "tr_color": "success"
        });
        if (AccessControl.checkRoutePermissions("/clients", this.props.auth, "view")) {
            if (switch_to_client_script === null) {
                this.fetchDashboard();
            }
        }
        if (AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "incomplete_scripts")) {
            this.fetchIncompleteScripts();
        }
        if (switch_to_client_script === null) {
            this.showUsersNotification();
            if (this.state.client_selected === null) {helpers.hideLoading();}
        }
    }
    showDuplicateScriptDialog() {
        helpers.showLoading();
        axios(this.props)
        .get(`/client/all`)
            .then((response) => this.processGetAllClients(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
    processGetAllClients(response) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "duplicateClientScriptsModal": true,
            "clientsList": response.data,
            "selected_templates": [],
            "from_client_id": "",
            "clientScripts": []
        });
        helpers.hideLoading();
    }
    handleDuplicateScriptClientSelect(id) {
        helpers.showLoading();
        axios(this.props)
        .get('/client/' + id + '/scripts/list')
            .then((response) => this.processDuplicateScriptClientSelect(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
    processDuplicateScriptClientSelect(response) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "clientScripts": response.data.scripts
        });
        helpers.hideLoading();
    }
    handleDuplicateScripts(from_client_id, templates) {
        if (from_client_id === null || from_client_id.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select a client.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }
        if (Object.keys(templates).length === 0 || templates.length === 0) {
            this.setState({
                "btnDisabled": false,
                "doneModal": false,
                "doneModalMsg": "Please select at least one script to duplicate.",
                "doneModalBtn": "OK",
                "userDetailsModal": false,
                "tr_color": "danger"
            });
            this.showUsersNotification();
            return;
        }
        this.setState({
            "confirmModal": true,
            "confirmModalMsg": `Are you sure you want to duplicate this client's script(s)? Existing script values will be overwritten!`,
            "confirmModalTitle": "Duplicate Script",
            "action": "duplicate_script",
            "selected_templates": templates,
            "from_client_id": from_client_id
        });
    }
    doHandleDuplicateScripts() {
        this.setState({"confirmModal": false, "action": "duplicate_script", "duplicateClientScriptsModal": false});
        helpers.showLoading();
        this.setState({"btnDisabled": true});
        axios(this.props)
            .post(
                `client/${this.state.client_id_script}/duplicate/${this.state.from_client_id}/scripts`,
                {"script_template_id": this.state.selected_templates},
                {}
            )
            .then((response) => this.processDuplicateScripts(response))
            .catch((error) => this.processErrorAxios(
                error,
                null
            ));
    }
    processDuplicateScripts (results) {
        if (this.isUnmounted) {
            return;
        }
        this.setState({
            "btnDisabled": false,
            "client_id_script": "",
            "doneModalMsg": "Client script duplicated",
            "selected_templates": [],
            "from_client_id": "",
            "tr_color": "success"
        }, function() {
            this.refreshClientsTable();
            this.showUsersNotification();
        });
        helpers.hideLoading();
    }

    setValue(value, type) {
        if (type === "number") {
            return Number(value);
        } else if (Object.prototype.toString.call(value) === '[object Array]') {
            return helpers.addLineBreaks(value);
        } else if (typeof value === "object") {
            let val = [];
            for(let key in value) {
                val.push(key + ': ' + value[key]);
            }
            return helpers.addLineBreaks(val);
        } else if (type === 'string') {
            return helpers.addLineBreaks(value.split("\n").join('<br>'));
        } else if (type === 'file') {
            try {
                return helpers.addLineBreaks(value.split("\n").join('<br>'));
            } catch(e) {}
        }
        return value;
    }

    viewApplication (event, approval, status, showLoading = true, callback = null, direction = 'desc', sort = 'received') {
        if (event !== null && event !== 'messages' && event !== 'escalate_application' && event !== 'deescalate_application' && event !== 'generate_match_report' && event !== 'request_asic_search' && event !== 'request_sign_form') event.preventDefault();
        if (showLoading) helpers.showLoading();
        axios(this.props)
          .get('/applications/' + approval.id + '?sort=' + sort + '&direction=' + direction)
          .then((response) => {
            this.processViewApplication(response, approval, status, callback);
          })
          .catch((error) => {
            console.log(error);
            this.processErrorAxios(
                error,
                null
            )
        });
    }
  
    processViewApplication (results, approval, status, callback = null) {
        if (this.isUnmounted) {
            return;
        }
        this.loadViewApplication(results, approval, status, callback)
    }

    viewApplicationWindow = null;
    loadViewApplication(results, approval_details, status, callback = null) {
        let approval = JSON.parse(JSON.stringify(approval_details));
        status = results.data.application.status;
        approval.name = results.data.application.merchant_user.first_name + ' ' + results.data.application.merchant_user.last_name;
        approval.company = results.data.application.merchant_user.company;
        approval.email = results.data.application.merchant_user.email;
        approval.created_at = moment.utc(results.data.created_at).format(helpers.date_format() + ' HH:mm:ss')
        approval.status = <span style={{"color": helpers.application_status_colors()[status] }}>{helpers.fixStatusLabel(status)}</span>;
        approval.page_notes = results.data.application.page_notes;
        approval.details = results.data.application.details;
        let app_state = {
            "applicationView": true, //!this.state.appViewClosed,
            "appViewClosed": false,
            "client_files": results.data.client_files,
            "mccs": results.data.mccs,
            "business_types": results.data.business_types,
            "registry_entries": results.data.registry_entries,
            "application_history": results.data.status_history,
            "available_fields": results.data.available_fields,
            "selected_client_id": approval.merchant_user.client.id,
            "selected_client_script_id": results.data.selected_client_script_id,
            "selected_client_script_step_id": results.data.selected_client_script_step_id,
            "selected_approval": approval,
            "page_notes": approval.page_notes,
            "selected_application_id": approval.id,
            "high_risk_mccs": results.data.high_risk_mccs,
            "nexxtap_supported_android_versions": results.data.nexxtap_supported_android_versions,
            "nexxtap_supported_android_min_version": results.data.nexxtap_supported_android_min_version,
            "application_status": status,
            "approval_message": results.data.messages,
            "application_details": [approval.company, approval.abn.length > 0 ? '(ABN: ' + helpers.abnFormat(approval.abn) + ')' : ''].join(' '),
            "onboarding_name": results.data.onboarding_name,
            "updateApplication": this.state.updateApplication,
            "canApprove": this.state.canApprove
        };
        // this.setState(app_state);
        helpers.hideLoading();

        if (callback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
            try {this.viewApplicationWindow.callbackComponent(callback);} catch(e) {}
        }

        helpers.cleanLocalStorage('admin_app_');
        localStorage.setItem('admin_app_' + approval.id + '_state', JSON.stringify(app_state));
        let path = '/admin/application/' + approval.id;
        if (this.viewApplicationWindow === null || this.viewApplicationWindow.closed) {
            localStorage.setItem('admin_app_' + approval.id + '_props', JSON.stringify(this.props));
            try {
                this.viewApplicationWindow = window.open(path, '');
            } catch(e) {
                console.log(e);
                // this.setState({
                //     "doneModalMsg": "Cannot open application details, please try again. Error: " + e,
                //     "tr_color": "danger"
                // });
                // this.showUsersNotification();
            } finally {
                // if (!this.viewApplicationWindow) {
                //     this.setState({
                //         "doneModalMsg": "Cannot open application details, please try again.",
                //         "tr_color": "danger"
                //     });
                //     this.showUsersNotification();
                // }
            }
        } else {
            if (!this.viewApplicationWindow.location.href.endsWith(path)) {
                localStorage.setItem('admin_app_' + approval.id + '_props', JSON.stringify(this.props));
                this.viewApplicationWindow.location.href = path;
            } else {
                if (callback !== null) {
                    try {this.viewApplicationWindow.callbackComponent(callback);} catch(e) {}
                }
            }
        }
        if (!this.state.dont_focus_child && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
            this.viewApplicationWindow.focus();
        }
        this.setState({"dont_focus_child": false});

        if (approval_details.status === 'application_resubmitted') {
            setTimeout(() => {helpers.showLoading(); this.fetchDashboard();}, 100);
        }

        try {
            if (app_state.applicationView) this.viewApplicationWindow.updateComponent();
        } catch(e) {}
    }

    handleStatusChange(id, status, message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, callback = null, draftMessage = '') {
        this.setStatus(id, status, draftMessage ? draftMessage : message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, callback);
    }

    setStatus(id, status, message, additional_fields_values, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, callback) {
        let additional_fields = [];
        for (var key of Object.keys(additional_fields_values)) {
            if (additional_fields_values[key] === 1) additional_fields.push({'key': key});
        }
        this.doSetStatus(
            id, status, message,
            status === 'awaiting_customer_response' ? additional_fields : null,
            status === 'incomplete_-_please_provide_more_information' ? selected_request_fields : null,
            approved_signatory_type,
            approved_signatory_email,
            approved_signatory_name,
            callback
        );
    }

    doSetStatus (selected_application_id, application_status, application_message, additional_fields, selected_request_fields, approved_signatory_type, approved_signatory_email, approved_signatory_name, changeStatusCallback) {
        const url = "/applications/" + selected_application_id + "/status",
            postObject = {
                "status": application_status,
                "message": application_message,
                "additional_fields": additional_fields,
                "selected_request_fields": selected_request_fields,
                "signatory": {
                    "type": approved_signatory_type,
                    "email": approved_signatory_type === 'custom' ? approved_signatory_email : null,
                    "name": approved_signatory_type === 'custom' ? approved_signatory_name : null
                }
            };
        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                let notification = {
                    "doneModalMsg": "Status changed",
                    "tr_color": "success",
                };
                if (changeStatusCallback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                    this.viewApplicationWindow.callbackComponent(changeStatusCallback, notification);
                }
                this.fetchDashboard();
                this.setState(notification);
                this.showUsersNotification();

            })
            .catch((error) => {

                console.log(error);
                this.setState({
                    "doneModalMsg": error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.hasOwnProperty('error') ? error.response.data.error : "An error has occurred, please try again.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                helpers.hideLoading();

            });
    }

    handleAddNote(id, message, page, callback) {
        this.sendApplicationAddNoteRequest(id, message, page, callback);
    }

    sendApplicationAddNoteRequest (selected_application_id, application_message, application_page, addNoteCallback) {
        // this.setState({"confirmModal": false});
        const url = "/applications/" + selected_application_id + "/notes",
            postObject = {
                "notes": application_message,
                "page": application_page
            };

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                let notification = {
                    "doneModalMsg": "Note added",
                    "tr_color": "success",
                    "action": null
                };
                if (addNoteCallback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                    this.viewApplicationWindow.callbackComponent(null, notification);
                    setTimeout(() => {
                        try {
                            this.state.pending_applications.forEach(approval => {
                                if (approval.id === selected_application_id) {
                                    this.viewApplication(null, approval, approval.status, true, addNoteCallback);
                                }
                            });
                        } catch(e) {}
                    }, 100);
                }
                this.setState(notification);
                helpers.hideLoading();

            })
            .catch((error) => {

                console.log(error);
                this.setState({
                    "doneModalMsg": "An error has occurred, please try again.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                helpers.hideLoading();

            });
    }

    handleFlagPage(id, message, page, action, callback) {
        this.sendFlagApplicationPageRequest(id, message, page, action, callback);
    }

    sendFlagApplicationPageRequest (selected_application_id, application_message, application_page, action, flagPageCallback) {
        const url = "/applications/" + selected_application_id + (action !== 1 ? "/unflag" : "/flag"),
            postObject = {
                "notes": application_message,
                "page": application_page
            };

        helpers.showLoading();
        return axios(this.props)
            .post(
                url,
                postObject
            )
            .then(() => {
                let notification = {
                    "doneModalMsg": action !== 1 ? "Page unflagged" : "Page flagged",
                    "tr_color": "success",
                    "action": null
                };
                if (flagPageCallback !== null && this.viewApplicationWindow !== null && !this.viewApplicationWindow.closed) {
                    this.viewApplicationWindow.callbackComponent(null, notification);
                    setTimeout(() => {
                        try {
                            this.state.pending_applications.forEach(approval => {
                                if (approval.id === selected_application_id) {
                                    this.viewApplication(null, approval, approval.status, true, flagPageCallback);
                                }
                            });
                        } catch(e) {}
                    }, 100);
                }
                this.setState(notification);
                helpers.hideLoading();

            })
            .catch((error) => {

                console.log(error);
                this.setState({
                    "doneModalMsg": "An error has occurred, please try again.",
                    "tr_color": "danger"
                });
                this.showUsersNotification();
                helpers.hideLoading();

            });
    }

  render () {
    let pending_applications = this.state.pending_applications;
    let conditional_approved = 0;
    try {
        pending_applications.forEach(application => {if (application.status === 'conditional_approved') conditional_approved++;});
    } catch(e) {}
    let applications_chart = null, applications_total = 0;
    try {
        applications_chart = this.state.hasOwnProperty('applications_chart') ? this.state.applications_chart : [["Status", "Count"],["Applications", 0]];
        applications_total = this.state.hasOwnProperty('applications_total') ? parseInt(this.state.applications_total) : 0;
    } catch(e) {}
    let applications_burndown = null;
    try {
        applications_burndown = this.state.hasOwnProperty('applications_burndown') ? this.state.applications_burndown : [[{}, "All"],["", 0]];
    } catch(e) {}

      const {classes} = this.props;
      return (
          <div>
              {this.handleRedirect()}
              <GridContainer>
                {/* {
                    AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "agenda")
                    ?  <GridItem
                            md={6}
                            sm={12}
                            xs={12}
                        >
                            <DashboardCalendar
                                processErrorAxios={this.processErrorAxios}
                                setUsersNotification={this.setUsersNotification}
                                {...this.state}
                            />
                        </GridItem>
                    : ""
                } */}
                {
                    AccessControl.checkRoutePermissions("/clients", this.props.auth, "view")
                    ?  <GridItem  xs={12} sm={6} md={6} lg={3}>
                            <Card>
                                <CardHeader color="success" icon>
                                    <CardIcon color="success">
                                        <AccountBox />
                                    </CardIcon>
                                    <p className={classes.cardCategory}>Active Clients</p>
                                    <h1 style={{"color": "black", "marginTop": "1rem", "marginBottom": "0rem", "textAlign": "center"}}>
                                        {this.state.client_count === null ? '...' : this.state.client_count}
                                    </h1>
                                </CardHeader>
                                <CardFooter stats>
                                    <div className={classes.stats}>
                                        {
                                        AccessControl.checkRoutePermissions("/clients", this.props.auth, "add_client")
                                            ?  <Button
                                                variant="contained"
                                                color="info"
                                                className={classes.button}
                                                onClick={() => {
                                                    if (this.state.countries === null) return;
                                                    this.setState(
                                                        {
                                                            "id": "",
                                                            "username": "",
                                                            "password": "",
                                                            "company": "",
                                                            "referrer": "",
                                                            "client_managers": [],
                                                            "client_manager_team": "",
                                                            "lead_operator_id": "",
                                                            "merchant_type_id": "",
                                                            "mcc_code": "",
                                                            "username_valid": "",
                                                            "password_valid": "",
                                                            "company_valid": "",
                                                            "referrer_valid": "",
                                                            "managers_valid": "",
                                                            "client_transaction_methods": [],
                                                            "transaction_methods_valid": "",
                                                            "client_manager_team_valid": "",
                                                            "primary_contact_first_name_valid": "",
                                                            "primary_contact_last_name_valid": "",
                                                            "primary_contact_id": "",
                                                            "contacts": [],
                                                            "action": "create_client",
                                                            "confirmModalTitle": "Create Client",
                                                            clientDetailsModal: true,
                                                            client_selected: null
                                                        }
                                                    )
                                                }}
                                            >
                                                Create Client
                                            </Button>
                                            : ""
                                        }
                                    </div>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    : ""
                }
                {
                    AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "pending_applications")
                    ?  <GridItem  xs={12} sm={6} md={6} lg={3}>
                        {/* 11.6rem */}
                            <Card style={{height: "11.6rem"}}>
                                <CardHeader color="info" icon>
                                    <CardIcon color="info">
                                        <ListAlt />
                                    </CardIcon>
                                    <p className={classes.cardCategory}>Pending Applications</p>
                                    <h1 style={{"color": "black", "marginTop": "3rem", "marginBottom": "0rem", "textAlign": "center"}}>
                                        {pending_applications === null ? '...' : pending_applications.length}
                                    </h1>
                                </CardHeader>
                            </Card>
                        </GridItem>
                    : ""
                }
                {
                    AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "pending_applications")
                    ?  <GridItem  xs={12} sm={6} md={6} lg={3}>
                            <Card style={{height: "11.6rem"}}>
                                <CardHeader color="info" icon>
                                    <CardIcon color="warning">
                                        <ListAlt />
                                    </CardIcon>
                                    <p className={classes.cardCategory}>Conditional Approved</p>
                                    <h1 style={{"color": "rgb(51, 102, 102)", "marginTop": "3rem", "marginBottom": "0rem", "textAlign": "center"}}>
                                        {pending_applications === null ? '...' : conditional_approved}
                                    </h1>
                                </CardHeader>
                            </Card>
                        </GridItem>
                    : ""
                }
            </GridContainer>
            
            {
                this.props && this.props.auth && this.props.auth.user && this.props.auth.user.role && (this.props.auth.user.role === 'admin' || this.props.auth.user.role === 'tl' /*|| this.props.auth.user.role === 'vc'*/)
                ? <GridContainer>
                    <GridItem md={5}>
                        <Card>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <PieChart />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    Pending Applications ({applications_total})
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <div style={{height: "300px", width: "100%"}}>
                                {
                                    applications_chart === null || applications_total === 0
                                    ? <div style={{height: "300px", width: "100%", textAlign: "center", lineHeight: "300px"}}>{
                                        applications_chart === null ? "Loading" : (
                                            applications_total === 0
                                            ? "No Applications"
                                            : "Loading..."
                                        )
                                    }</div>
                                    : <Chart
                                        chartType="PieChart"
                                        data={applications_chart}
                                        options={{
                                            // title: "Applications",
                                            chartArea: {
                                                left: 5,
                                                top: 20,
                                                width: '100%',
                                                height: '250',
                                            },
                                            // legend: {'position': 'bottom'}
                                        }}
                                        width={"100%"}
                                        height={"300px"}
                                    />
                                }
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem md={7}>
                        <Card>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <Timeline />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    Application History ({applications_total})
                                    <select
                                        onChange={(e) => {
                                            if (!e.target.value || e.target.value.length === 0) return;
                                            this.setState({burn_down_chart_value: e.target.value, applications_burndown: null}, function() {
                                                this.fetchBurndownChart(this.state.burn_down_chart_value);
                                            });
                                        }}
                                        style={{
                                            "padding": "5px 10px 5px 10px",
                                            "marginLeft": "18px",
                                            "borderColor": "#ccc",
                                            "borderRadius": "3px"
                                        }}
                                        value={this.state.burn_down_chart_value}
                                    >
                                        <option key={0} value={""} disabled={true}>Select Range</option>
                                        <option key={1} value={"this_month"}>This Month</option>
                                        <option key={2} value={"last_month"}>Last Month</option>
                                        <option key={3} value={"last_30_days"}>Last 30 Days</option>
                                        <option key={4} value={"this_quarter"}>This Quarter</option>
                                        <option key={5} value={"this_year"}>This Year</option>
                                        {/* <option key={6} value={"last_year"}>Last Year</option> */}
                                        {/* <option key={7} value={"all_time"}>All Time</option> */}
                                    </select>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <div style={{height: "300px", width: "100%"}}>
                                {
                                    !applications_burndown || Object.keys(applications_burndown).length === 0
                                    ? <div style={{height: "300px", width: "100%", textAlign: "center", lineHeight: "300px"}}>{
                                        !applications_burndown ? "Loading" : (
                                            Object.keys(applications_burndown).length === 0
                                            ? "No Data"
                                            : "Loading..."
                                        )
                                    }</div>
                                    : <Chart
                                        chartType="Line"
                                        data={applications_burndown}
                                        options={{
                                            // title: "Applications",
                                            chartArea: {
                                                left: 5,
                                                top: 20,
                                                width: '100%',
                                                height: '250',
                                            },
                                            // legend: {'position': 'bottom'},
                                        }}
                                        width={"100%"}
                                        height={"300px"}
                                    />
                                }
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : ""
            }

            {AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "pending_applications")
                ? <GridContainer>
                    <GridItem md={12}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="info" icon>
                                <CardIcon color="info">
                                    <ListAlt />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    {"Pending Applications" + (
                                        pending_applications !== null && Object.keys(pending_applications).length > 0
                                            ? " (" + Object.keys(pending_applications).length + ")" : ""
                                    )}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    pending_applications === null || Object.keys(pending_applications).length === 0
                                        ? <GridItem
                                            md={12}
                                            style={{"textAlign": "center",
                                                "lineHeight": "100px"}}
                                        >
                                            {pending_applications === null ? "Loading..." : "No pending applications"}
                                        </GridItem>
                                        : <Table
                                            customCellClasses={[`no-wrap ${classes.right}`]}
                                            tableData={pending_applications.map((row) => {
                                                let created_at = '';
                                                try {
                                                    if (moment(row.created_at).isValid()) {
                                                        created_at = moment.utc(row.created_at).format(helpers.date_format() + ' HH:mm:ss');
                                                    } else {
                                                        created_at = row.created_at;
                                                    }
                                                } catch(e) {created_at = row.created_at;}
                                                let status = row.status;
                                                if (typeof status !== 'string') status = '';
                                                return [
                                                    <span>
                                                        <span title={row.risk === 'red' ? 'High Risk' : (row.risk === 'orange' ? 'Medium Risk' : (row.risk === 'green' ? 'Low Risk' : 'N/A'))}>
                                                            <EmojiFlags style={{fontSize: "18px", color: row.risk ? row.risk : 'green'}} />
                                                        </span>
                                                        {
                                                            this.props && this.props.auth && this.props.auth.user && this.props.auth.user.role && 
                                                            row.app_escalation !== null && row.app_escalation.escalated === 1 && (
                                                                (this.props.auth.user.role === 'admin' && row.app_escalation.to === 'admin') ||
                                                                (this.props.auth.user.role === 'tl' && (row.app_escalation.to === 'tl' || row.app_escalation.to === 'admin')) ||
                                                                (this.props.auth.user.role === 'vc')
                                                            )
                                                            ? <span title={'Escalated to ' + row.app_escalation.to.toUpperCase()}>
                                                                <Report style={{fontSize: "18px", color: 'red'}} />
                                                            </span>
                                                            : ""
                                                        }
                                                    </span>
                                                    
                                                    ,
                                                    row.id,
                                                    row.merchant_user.client.id,
                                                    created_at,
                                                    row.merchant_user.first_name + ' ' + row.merchant_user.last_name,
                                                    row.merchant_user.email,
                                                    row.merchant_user.company,
                                                    <div key={row.id}>
                                                        <span style={{"color": helpers.application_status_colors()[status], cursor: "pointer" }}
                                                            onClick={(event) => {
                                                                this.viewApplication(event, row, status);
                                                            }}
                                                        >
                                                            {helpers.fixStatusLabel(status)}
                                                        </span>
                                                    </div>
                                                ];
                                            })}
                                            tableHead={[
                                                "",
                                                "App ID",
                                                "Client ID",
                                                "Submitted",
                                                "Name",
                                                "Email",
                                                "Company",
                                                "Status"
                                            ]}
                                        />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : ""
            }
            {AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "incomplete_scripts")
                ? <GridContainer>
                    <GridItem md={12}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="warning" icon>
                                <CardIcon color="warning">
                                    <ListAlt />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    {"Clients with incomplete scripts" + (
                                        this.state.clients !== null && Object.keys(this.state.clients).length > 0
                                            ? " (" + Object.keys(this.state.clients).length + ")" : ""
                                    )}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    this.state.clients === null || Object.keys(this.state.clients).length === 0
                                        ? <GridItem
                                            md={12}
                                            style={{"textAlign": "center",
                                                "lineHeight": "100px"}}
                                        >
                                            {this.state.clients === null ? "Loading..." : "No incomplete scripts"}
                                        </GridItem>
                                        : <Table
                                            customCellClasses={[`no-wrap ${classes.right}`]}
                                            tableData={this.state.clients.map((row) => {
                                                return [
                                                    row.id,
                                                    row.username,
                                                    row.company,
                                                    row.scripts.map((script) => {
                                                        return <div key={row.id + '_' + script.id}>
                                                            <Link style={{"color": "black"}} to={{
                                                                "pathname": "/admin/clients",
                                                                "state": {"from": "dashboard", script}
                                                            }}>
                                                                {script.name + ' ' + script.percent + '% completed'}
                                                            </Link>
                                                        </div>;
                                                    })
                                                ];
                                            })}
                                            tableHead={[
                                                "Client ID",
                                                "Username",
                                                "Company",
                                                "Scripts"
                                            ]}
                                        />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : ""
              }
              {AccessControl.checkRoutePermissions("/dashboard", this.props.auth, "change_events")
                ? <GridContainer>
                    <GridItem md={12}>
                        <Card chart className={classes.cardHover}>
                            <CardHeader color="primary" icon>
                                <CardIcon color="primary">
                                    <EventNote />
                                </CardIcon>
                                <h4
                                    className={classes.cardIconTitle}
                                    style={{"color": "black"}}
                                >
                                    {"Last 10 Events"}
                                </h4>
                            </CardHeader>
                            <CardBody>
                                {
                                    this.state.change_events === null || Object.keys(this.state.change_events).length === 0
                                        ? <GridItem
                                            md={12}
                                            style={{"textAlign": "center",
                                                "lineHeight": "100px"}}
                                        >
                                            {this.state.change_events === null ? "Loading..." : "No events"}
                                        </GridItem>
                                        : <Table
                                            customCellClasses={[`no-wrap ${classes.right}`]}
                                            tableData={this.state.change_events.map((row) => {
                                                return [
                                                    row.client ? row.client.id : "N/A",
                                                    row.client ? (row.client.username ? row.client.username : "") : "N/A",
                                                    row.name ? row.name.replace(/^\*/, '') : "",
                                                    row.from === null ? "" : this.setValue(row.from, row.value_type),
                                                    row.to === null ? "" : this.setValue(row.to, row.value_type),
                                                    moment.utc(row.created_at).utcOffset(this.state.user_tz_offset).format(helpers.date_format() + ' HH:mm:ss'),
                                                    row.updater ? "(" + row.updater.type + ") " + row.updater.username : "N/A",
                                                    // row.from === null ? "Create" : (row.to === null ? "Delete" : "Update"),
                                                ];
                                            })}
                                            tableHead={[
                                                "Client ID",
                                                "Username",
                                                "Field",
                                                "From",
                                                "To",
                                                "Date",
                                                "Updated By",
                                                // "Action"
                                            ]}
                                        />
                                }
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
                : ""
              }
              {this.state.clientDetailsModal
                ? <ClientDetailsDialog
                    accessControlManager={this.accessControlManager}
                    handleChange={this.handleChangeByName}
                    handleManualChange={this.handleManualChange}
                    handleMultiple={this.handleMultiple}
                    handleTransactionMethods={this.handleTransactionMethods}
                    handleSelectManagerTeam={this.handleSelectManagerTeam}
                    handleSelectLead={this.handleSelectLead}
                    handleSelectPrimaryContact={this.handleSelectPrimaryContact}
                    handleSelectMerchantType={this.handleSelectMerchantType}
                    handleSelectMCCCode={this.handleSelectMCCCode}
                    onClose={() => this.handleClose("clientDetailsModal")}
                    onYesClick={(contact, contact_documents) => this.handleAcceptClientDetails("confirmModal", contact, contact_documents)}
                    selected_contact={[]}
                    countries={this.state.countries}
                    {...this.state}
                    auth={this.props.auth}
                    contact_details_title={"Create Client"}
                    action={"create_client"}
                />
                : ""
              }
              <SelectScriptTemplates
                  showDuplicateScriptDialog={this.showDuplicateScriptDialog}
                  handleGenerateScript={this.handleGenerateScript}
                  onChange={(templates) => this.setState({'selected_templates': templates})}
                  onClose={() => {
                      this.setState({'selected_templates': []});
                      this.handleClose("selectScriptTemplateModal");
                  }}
                  templates={this.state.selected_templates}
                  {...this.state}
                  showDuplicateButton={true}
              />
              <DuplicateClientScripts
                  handleDuplicateScripts={this.handleDuplicateScripts}
                  onChange={(client_id) => this.handleDuplicateScriptClientSelect(client_id)}
                  onMultipleChange={(templates) => this.setState({'selected_templates': templates})}
                  onClose={() => {
                      this.setState({'selected_templates': []});
                      this.handleClose("duplicateClientScriptsModal");
                  }}
                {...this.state}
              />
              {this.state.doneModalMsg
                  ? <Snackbar
                      close
                      closeNotification={() => {
                          this.handleCloseNotification();
                      }}
                      color={this.state.tr_color}
                      icon={AddAlert}
                      message={this.state.doneModalMsg}
                      open={this.state.tr}
                      place="tr"
                    />
                  : ""}
                <ConfirmDialog
                    confirmModal={this.state.confirmModal}
                    confirmModalMsg={this.state.confirmModalMsg}
                    confirmModalTitle={this.state.confirmModalTitle}
                    onClose={() => this.handleClose("confirmModal")}
                    onYesClick={() => this.handleConfirmClick()}
                />
          </div>
      );
  }
}

Dashboard.propTypes = {
    "classes": PropTypes.object.isRequired
};

const mapStateToProps = (state, props) => ({
    "auth": state.auth.data
});

export default connect(mapStateToProps)(withRouter(withStyles(dashboardStyle)(Dashboard)));
