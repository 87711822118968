import React from "react";
import {
    Redirect,
    Route,
    BrowserRouter as Router,
    Switch
} from "react-router-dom";

import AuthLayout from "layouts/Auth.jsx";
import AdminLayout from "layouts/Admin.jsx";
import ClientAuthLayout from "layouts/ClientAuth.jsx";
import ClientLayout from "layouts/Client.jsx";
import helpers from "customs/helpers/helpers";
import BlankLayout from "layouts/Blank.jsx";

import {connect} from "react-redux";
import {doTokenLogin, doUpdateAuth} from "../store/actions/authActions";
import store from "../store/store.js";
import login from "assets/img/login.jpeg";

class CheckAuth extends React.Component {

    constructor (props) {
        super(props);
        
        var d = null, url_string = '', url = '';
        if (window.location.pathname === '/admin/asic') {
            url_string = window.location.href; 
            url = new URL(url_string);
            d = url.searchParams.get("d");
        } else if (window.location.pathname.startsWith('/admin/application/')) {
            let id = window.location.pathname.replace('/admin/application/', '').replace('/' ,'');
            if (/^-?\d+$/.test(id)) d = id;
        }

        this.state = {
            showPages: false,
            path: window.location.pathname,
            query_params: d === null ? '' : d
        };
    }

    componentDidMount () {
        helpers.showLoading();
        if (!this.state.path.startsWith('/client')) {
            if (window.location.pathname.startsWith('/admin/application/')) {
                let id = window.location.pathname.replace('/admin/application/', '').replace('/' ,'');
                if (/^-?\d+$/.test(id)) {
                    var app_id = id;
                    let admin_app_id_props = localStorage.getItem('admin_app_' + app_id + '_props');
                    admin_app_id_props = admin_app_id_props ? JSON.parse(admin_app_id_props) : [];
                    if (admin_app_id_props.length === 0) {
                        window.close();
                    }
                    try {
                        store.dispatch(doUpdateAuth({
                            data: {
                                authMessage: '',
                                token: admin_app_id_props.auth.token,
                                user: admin_app_id_props.auth.user
                            },
                            showPages: false
                        }));
                    } catch(e) {}
                    this.props.doTokenLogin({...admin_app_id_props});
                    // localStorage.removeItem('admin_app_' + app_id + '_props');
                }
            } else {
                this.props.doTokenLogin({...this.props});
            }
        } else {
            this.setState({"showPages": true});
        }
    }
    
    render () {
        return (
            this.state.showPages || this.props.showPages
                ? <Router>
                    <Switch>
                        <Route
                            component={() => <AuthLayout prevPath={this.props.logout ? '' : this.state.path} prevParams={this.props.logout ? '' : this.state.query_params} />}
                            path="/auth"
                        />
                        {
                        !window.location.pathname.startsWith('/admin/application/')
                        ? <Route
                            component={AdminLayout}
                            path="/admin"
                        />
                        :
                        <Route
                            component={BlankLayout}
                            path="/admin"
                        />
                        }
                        <Route
                            component={ClientAuthLayout}
                            path="/client/auth"
                        />
                        <Route
                            component={ClientLayout}
                            path="/client"
                        />
                        <Redirect
                            from="/"
                            to="/auth/login"
                        />
                    </Switch>
                </Router>
                : <div>
                    <style>
                        {
                            "@media (max-width: 959.95px) {.fillPage {min-height: inherit !important;}"
                        }
                    </style>
                    <div>
                        <div
                            className={'fillPage'}
                            style={{
                                "backgroundImage": `url(${login})`,
                                "color": "#FFF", "border": "0", "height": "100%", "margin": "0", "display": "flex", "padding": "120px 0", "position": "relative",
                                "minHeight": "100vh", "alignItems": "center", "backgroundSize": "cover", "backgroundPosition": "center center",
                                "filter": "brightness(0.4)"
                            }}
                        >
                        </div>
                    </div>
                </div>
        )
    }
}

const mapStateToProps = (state, props) => {
    helpers.hideLoading();
    return {
        "auth": state.auth.data,
        "showPages": state.auth.showPages !== undefined,
        "logout": state.auth.logout !== undefined
    };

},

mapDispatchToProps = (dispatch) => ({
    "doTokenLogin": (props) => dispatch(doTokenLogin(props))
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckAuth);